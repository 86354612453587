(function () {
    var controllerId = 'revenue.views.settings.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        'abp.services.revenue.settings', 'Abp.Configuration.Setting',
        function (settingsSvc, constsAbpConfig) {
            var vm = this;
            vm.constsAbpConfig = constsAbpConfig;
            vm.loading = false;
            vm.saving = false;
            vm.settings = null;

            function loadSettings() {
                if (vm.loading) return;
                vm.loading = true;
                settingsSvc.getSettings().success(function (data) {
                    vm.settings = data;
                }).finally(function () {
                    vm.loading = false;
                });
            }

            vm.saveAll = function () {
                if (vm.saving) return;
                vm.saving = true;
                settingsSvc.updateSettings(vm.settings)
                    .success(function () {
                        abp.notify.info(App.localize("SuccessfullySaved"));
                        // Prevent disabling subscription management enhancement.
                        vm.preventDisableSubscriptionManagementEnhancement = vm.settings
                            && vm.settings.feature.subscriptionManagementEnhancement
                    }).finally(function () {
                        vm.saving = false;
                        loadSettings();
                    });
            };

            loadSettings();
        }
    ]);
})();
