(() => {
  angular.module('app').component('hostCorporatesCreateEditNotifications', {
    templateUrl: require('./widget.html'),
    controller: CorporateEditNotificationsController,
    controllerAs: 'vm',
    require: {
      container: '^hostCorporatesCreateEditContainer',
    },
    bindings: {
      data: '<',
    },
  });

  CorporateEditNotificationsController.$inject = [
    '$scope',
    'Abp.Configuration.Setting',
  ];

  function CorporateEditNotificationsController($scope, constsAbpConfig) {
    const vm = this;
    vm.constsAbpConfig = constsAbpConfig;
    vm.loading = 0;
    vm.saving = 0;
    vm.isEditing = false;
    vm.isCreate = false;

    vm.permissions = {
      edit: abp.auth.isGranted('Host.Corporates.Edit'),
      create: abp.auth.isGranted('Host.Corporates.Create'),
    };

    vm.isDirty = isDirty;
    vm.setPristine = setPristine;
    vm.validateForm = validateForm;
    vm.getPayload = getPayload;
    vm.save = save;
    vm.$onChanges = changes;
    vm.$onInit = init;

    function init() {
      vm.container.addWidget(this);
    }

    function isDirty() {
      return (
        $scope.corporateCreateEditNotificationsForm &&
        $scope.corporateCreateEditNotificationsForm.$dirty
      );
    }

    function setPristine() {
      $scope.corporateCreateEditNotificationsForm.$setPristine();
    }

    // Force validation on this form.
    // Return true if valid, otherwise, false.

    function validateForm() {
      const form = $scope.corporateCreateEditNotificationsForm;
      App.touchFormErrors(form);
      return form.$valid;
    }

    // Generate the payload from this form to send to CorporateAppService API.

    function getPayload() {
      return {
        notifications: vm.data,
      };
    }

    // Ask container to save this widget.

    function save() {
      vm.container.saveWidget(vm);
    }

    function changes() {
      vm.isCreate = vm.data ? !vm.data.id : false;
    }
  }
})();
