(() => {
  angular.module('app').component('commonInpatientTicketsDetailMiscellaneousTreatmentComponent', {
    templateUrl: require('./miscellaneousTreatment.component.html'),
    controller: MiscellaneousTreatmentController,
    controllerAs: 'vm',
    bindings: {
      ticket: '<',
      isEdit: '<',
      isSaved: '<',
      categories: '<',
      isCreate: '<',
      currencyCode: '<'
    },
  });

  MiscellaneousTreatmentController.$inject = [];

  function MiscellaneousTreatmentController() {
    const vm = this;

    vm.formattedCategories = [];
    vm.tempPlaceHolder = null;
    vm.loadedFlag = false;

    vm.$onChanges = onChanges;
    vm.addCategory = addCategory;
    vm.updateCategory = updateCategory;
    vm.updateSubCategory = updateSubCategory;
    vm.addTicketItem = addTicketItem;
    vm.removeTicketItem = removeTicketItem;
    vm.clearAllTicketItems = clearAllTicketItems;
    vm.manualEdit = manualEdit;
    vm.cancelManualEdit = cancelManualEdit;
    vm.saveManualEdit = saveManualEdit;
    vm.calculateAmount = calculateAmount;
    vm.calculateTotalAmount = calculateTotalAmount;
    vm.getAmount = getAmount;
    vm.getSubtotalAmount = getSubtotalAmount;
    vm.getTotalCoveredAmount = getTotalCoveredAmount;
    vm.getTotalUncoveredAmount = getTotalUncoveredAmount;

    const defaultTicketItem = {
      mainTreatment: '',
      isManualEdit: 0,
      ticketItems: [
        {
          amount: 0,
          uncoveredAmount: 0,
          totalCharges: 0,
          mainTreatment: '',
          subTreatment: '',
          procedure: '',
          section: '',
          uncoveredProcedure: '',
          remark: '',
          uncoveredRemark: '',
          subTreatments: [],
          procedures: [],
        },
      ],
    };

    init();
    function init() {
      // Initialise ticket items.

      if (!vm.ticket.miscellaneousTreatments) {
        vm.ticket.miscellaneousTreatments = [];
      }

      if (vm.isCreate) {
        addCategory();
      }
    }

    function onChanges(changes) {
      // Format categories.

      if (!vm.loadedFlag) {
        formatCategories();

        if (!vm.isCreate) {
          getTicketItems();
        }

        vm.loadedFlag = true;
      }
    }

    function addCategory() {
      vm.ticket.miscellaneousTreatments.push(JSON.parse(JSON.stringify(defaultTicketItem)));
    }

    function getTicketItems() {
      const ticketItems = _.filter(
        vm.ticket.ticketItems,
        (item) => item.category === 'Miscellaneous'
      );

      formatTicketItems(ticketItems);
    }

    function updateCategory(index, item) {
      if (item.mainTreatment) {
        // Clear sub categories & procedures.

        _.forEach(item.ticketItems, (x) => {
          x.subTreatment = '';
          x.procedure = '';
          x.uncoveredProcedure = '';
          x.subTreatments = [];
          x.procedures = [];
        });

        // Update each ticketItem in item.

        const mainTreatment = _.filter(
          vm.formattedCategories,
          (f) => f.mainTreatmentCategoryName === item.mainTreatment
        );

        _.forEach(item.ticketItems, (x) => {
          x.mainTreatment = item.mainTreatment;
          x.section = mainTreatment[0].sectionType;

          // Add sub treatments items.

          x.subTreatments = mainTreatment[0].subTreatmentCategories.map(
            (s) => s.subTreatmentItemName
          );
        });
      } else if (index > 0) {
        vm.ticket.miscellaneousTreatments.splice(index, 1);
      } else {
        clearAllTicketItems(item);
      }
    }

    function updateSubCategory(index, item, ticketItem) {
      // Clear procedures.

      ticketItem.procedure = '';
      ticketItem.uncoveredProcedure = '';
      ticketItem.procedures = [];

      // Add procedure items.

      const mainTreatment = _.filter(
        vm.formattedCategories,
        (f) => f.mainTreatmentCategoryName === item.mainTreatment
      );

      const subTreatment = _.filter(
        mainTreatment[0].subTreatmentCategories,
        (s) => s.subTreatmentItemName === ticketItem.subTreatment
      );

      ticketItem.procedures = subTreatment[0].procedures;
    }

    function addTicketItem(item) {
      const newTicketItem = JSON.parse(JSON.stringify(defaultTicketItem.ticketItems[0]));

      // Format new ticket item.

      newTicketItem.mainTreatment = item.mainTreatment;

      // Add sub treatments items.

      if (item.mainTreatment) {
        const mainTreatment = _.filter(
          vm.formattedCategories,
          (f) => f.mainTreatmentCategoryName === item.mainTreatment
        );

        newTicketItem.subTreatments = mainTreatment[0].subTreatmentCategories.map(
          (s) => s.subTreatmentItemName
        );
        newTicketItem.section = mainTreatment[0].sectionType;
      }

      // Add new ticket item.

      item.ticketItems.push(newTicketItem);
    }

    function removeTicketItem(index, item) {
      if (index) {
        item.ticketItems.splice(index, 1);
      } else {
        clearAllTicketItems(item);
      }
    }

    function clearAllTicketItems(item) {
      item.ticketItems = [];
      addTicketItem(item);
    }

    function manualEdit(index) {
      vm.tempPlaceHolder = angular.copy(vm.ticket.miscellaneousTreatments[index]);
      vm.ticket.miscellaneousTreatments[index].isManualEdit = 1;
    }

    function cancelManualEdit(index) {
      vm.ticket.miscellaneousTreatments[index] = angular.copy(vm.tempPlaceHolder);
      vm.ticket.miscellaneousTreatments[index].isManualEdit = 0;
      vm.tempPlaceHolder = null;
    }

    function saveManualEdit(index) {
      vm.ticket.miscellaneousTreatments[index].isManualEdit = 0;
      vm.tempPlaceHolder = null;

      _.each(vm.ticket.miscellaneousTreatments[index].ticketItems, (ticketItem) => {
        const p = ticketItem;
        p.isOverride = 1;
      });
    }

    function calculateAmount(index, item) {
      let amount = item.totalCharges;
      amount = amount < 0 ? 0 : amount;
      item.amount = amount || 0;

      let uncoveredAmount = item.totalCharges - item.amount;
      uncoveredAmount = uncoveredAmount < 0 ? 0 : uncoveredAmount;
      item.uncoveredAmount = uncoveredAmount || 0;
    }

    function calculateTotalAmount(index, item) {
      let totalCharges = item.amount + item.uncoveredAmount;
      totalCharges = totalCharges < 0 ? 0 : totalCharges;
      item.totalCharges = totalCharges || 0;
    }

    function getAmount() {
      let amount = 0;
      let uncoveredAmount = 0;

      _.each(vm.ticket.miscellaneousTreatments, (item) => {
        _.each(item.ticketItems, (ticketItem) => {
          amount += ticketItem.amount;
          uncoveredAmount += ticketItem.uncoveredAmount;
        });
      });

      return {
        subtotalAmount: App.roundAmount(amount + uncoveredAmount),
        totalCoveredAmount: App.roundAmount(amount),
        totalUncoveredAmount: App.roundAmount(uncoveredAmount),
      };
    }

    function formatCategories() {
      // Get main categories.

      _.forEach(vm.categories, (x) => {
        if (
          vm.formattedCategories
            .map((e) => e.mainTreatmentCategoryName)
            .indexOf(x.mainTreatmentCategoryName) < 0
        ) {
          vm.formattedCategories.push({
            mainTreatmentCategoryName: x.mainTreatmentCategoryName,
            sectionType: x.sectionType,
            subTreatmentCategories: [],
          });
        }
      });

      // Get sub categories.

      _.forEach(vm.formattedCategories, (x) => {
        const subTreatments = _.filter(
          vm.categories,
          (c) => c.mainTreatmentCategoryName === x.mainTreatmentCategoryName
        );

        _.forEach(subTreatments, (s) => {
          if (
            x.subTreatmentCategories
              .map((e) => e.subTreatmentItemName)
              .indexOf(s.subTreatmentItemName) < 0
          ) {
            x.subTreatmentCategories.push({
              subTreatmentItemName: !s.subTreatmentItemName
                ? x.mainTreatmentCategoryName
                : s.subTreatmentItemName,
              procedures: [],
            });
          }
        });
      });

      // Get procedures.

      _.forEach(vm.formattedCategories, (x) => {
        _.forEach(x.subTreatmentCategories, (s) => {
          const procedures = _.filter(
            vm.categories,
            (c) => c.subTreatmentItemName === s.subTreatmentItemName
          );

          _.forEach(procedures, (p) => {
            if (p.procedures) {
              s.procedures.push(p.procedures);
            }
          });
        });
      });
    }

    function formatTicketItems(items) {
      const mainTreatments = [];

      // Get main treatments.

      _.forEach(items, (item) => {
        if (_.indexOf(mainTreatments, item.categoryName) < 0) {
          mainTreatments.push(item.categoryName);
        }
      });

      // Get ticket items per main treatment.

      _.forEach(mainTreatments, (main) => {
        const ticketItems = _.filter(items, (x) => x.categoryName === main);
        const newCategory = JSON.parse(JSON.stringify(defaultTicketItem));

        newCategory.mainTreatment = main;
        newCategory.ticketItems = [];

        // Get sub categories and procedures.

        const mainTreatment = _.filter(
          vm.formattedCategories,
          (f) => f.mainTreatmentCategoryName === main
        );

        _.forEach(ticketItems, (x) => {
          const newTicketItem = JSON.parse(JSON.stringify(defaultTicketItem.ticketItems[0]));
          const subTreatment = _.filter(
            mainTreatment[0].subTreatmentCategories,
            (s) => s.subTreatmentItemName === x.subCategory
          );

          newTicketItem.subTreatments = mainTreatment[0].subTreatmentCategories.map(
            (s) => s.subTreatmentItemName
          );

          newTicketItem.procedures = subTreatment[0].procedures;

          newTicketItem.amount = x.amount;
          newTicketItem.uncoveredAmount = x.uncoveredAmount;
          newTicketItem.section = x.category;
          newTicketItem.mainTreatment = x.categoryName;
          newTicketItem.subTreatment = x.subCategory;
          newTicketItem.procedure = x.procedure;
          newTicketItem.uncoveredProcedure = x.uncoveredProcedure;
          newTicketItem.remark = x.remark;
          newTicketItem.uncoveredRemark = x.uncoveredRemark;
          newTicketItem.totalCharges = x.amount + x.uncoveredAmount;

          newCategory.ticketItems.push(newTicketItem);
        });

        vm.ticket.miscellaneousTreatments.push(newCategory);
      });
    }

    function getSubtotalAmount() {
      return getAmount().subtotalAmount;
    }

    function getTotalCoveredAmount() {
      return getAmount().totalCoveredAmount;
    }

    function getTotalUncoveredAmount() {
      return getAmount().totalUncoveredAmount;
    }
  }
})();
