(() => {
  angular
    .module('app')
    .controller(
      'common.views.postHospitalization.details.index',
      GuaranteeLetterDetailPageController
    );

  GuaranteeLetterDetailPageController.$inject = [
    '$state',
    '$stateParams',
    'abp.services.app.postHospitalizationGuaranteeLetter',
    'Hms.ServiceRequests.ServiceRequestType'
  ];

  function GuaranteeLetterDetailPageController(
    $state,
    $stateParams,
    postHospitalizationGuaranteeLetterSvc,
    enumServiceRequestType
  ) {
    const vm = this;

    vm.letterNumber = $stateParams.letterNumber;
    vm.serviceType = $stateParams.serviceType;
    vm.memberId = 0; // For host claim policy component use. Get value from letter detail component.
    vm.isHost = App.isHost();
    vm.isCorporate = App.isCorporate();
    vm.activeTab = 1;
    vm.isDetailPage = true;
    vm.showCreateQuery = false;

    vm.enums = {};
    vm.enums.serviceRequestType = enumServiceRequestType;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

    function init() {
      getLetterDetail();
    }

    function getLetterDetail() {
      vm.loading += 1;
      postHospitalizationGuaranteeLetterSvc
        .getPostHospitalizationGuaranteeLetterDetails({
          id: vm.letterNumber,
        })
        .success((data) => {
          vm.letter = data;

          if (vm.letter.latestRequestType === vm.enums.serviceRequestType.AddOn.id ||
            vm.letter.latestRequestType === vm.enums.serviceRequestType.Final.id ||
            vm.letter.latestRequestType === vm.enums.serviceRequestType.Adjustment.id ||
            vm.letter.latestRequestType === vm.enums.serviceRequestType.LateSubmission.id) {
            vm.showCreateQuery = true;
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    init();

  }
})();
