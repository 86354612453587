(() => {
  angular.module('app').controller('host.views.settings.index', SettingsController);

  SettingsController.$inject = [
    '$scope',
    'abp.services.app.hostSettings',
    'abp.services.app.sms',
    'abp.services.app.commonLookup',
    '$uibModal',
    'Abp.Configuration.Setting',
    'Hms.PaymentAccounts.PaymentAccount',
    'moment',
    '$window',
  ];

  function SettingsController(
    $scope,
    hostSettingsSvc,
    smsSvc,
    commonLookupSvc,
    $modal,
    constsAbpConfig,
    constsPaymentAccount,
    moment,
    $window
  ) {
    const vm = this;
    vm.constsAbpConfig = constsAbpConfig;
    vm.constsPaymentAccount = constsPaymentAccount;
    vm.loading = 0;
    vm.testing = 0;
    vm.saving = 0;
    vm.midnightDuration = 0;
    vm.zendeskTestResult = null;
    vm.settings = null;
    vm.clinicLanguages = [];
    vm.smsProviders = [];
    vm.pharmacySaleSettingsList = [{}];

    vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.hasSingaporeInvoiceGeneration = abp.setting.getBoolean(
      'Hms.Feature.SingaporeInvoiceGeneration'
    );
    vm.hasGpDashboardEnhancement = abp.setting.getBoolean('Hms.Feature.GPDashboardEnhancement');
    vm.hasInsurerModule = abp.setting.getBoolean('Hms.Feature.InsuranceModule');
    vm.hasSmsService = abp.setting.getBoolean('Hms.Feature.SmsService');
    vm.hasMailingModule = abp.auth.isGranted('HealthMetrics.Mailing');

    vm.saveAll = saveAll;
    vm.getLanguage = getLanguage;
    vm.calculateDuration = calculateDuration;
    vm.testZendeskConnection = testZendeskConnection;
    vm.openOneSignalNotificationCategoryModal = openOneSignalNotificationCategoryModal;
    vm.addNewPharmacySale = addNewPharmacySale;
    vm.deleteLatestPharmacySale = deleteLatestPharmacySale;
    vm.getEligibleCountries = getEligibleCountries;
    vm.removeSelectedCountry = removeSelectedCountry;
    vm.getTeleconsultServices = getTeleconsultServices;
    vm.getPharmacySalesSettings = getPharmacySalesSettings;

    init();

    function init() {
      getAllSettings();

      if (!vm.hasSmsService) {
        getSmsProviders();
      }

      getEligibleCountries();
      getTeleconsultServices();
      getPharmacySalesSettings();
      getBanks();
    }

    function getAllSettings() {
      vm.loading += 1;
      hostSettingsSvc
        .getAllSettings()
        .success((data) => {
          data.treatmentManagement.midnightStartTime = moment(
            data.treatmentManagement.midnightStartTime
          ).toDate();
          data.treatmentManagement.midnightEndTime = moment(
            data.treatmentManagement.midnightEndTime
          ).toDate();
          vm.settings = data;
          vm.calculateDuration();
        })
        .finally(() => {
          vm.preventDisableSobEnhancement = vm.settings && vm.settings.feature.sobEnhancement; // Prevent disabling SOB enhancement after enabled.
          vm.preventDisableTxtFileSupport = vm.settings && vm.settings.feature.txtFileSupport; // Prevent disabling txt file support after enabled.
          vm.preventDisableRegionalSystemSupport =
            vm.settings && vm.settings.feature.regionalSystemSupport; // Prevent disabling regional system support after enabled.
          vm.preventDisableHealthScreeningModule =
            vm.settings && vm.settings.feature.healthScreeningModule; // Prevent disabling health screening module after enabled.
          vm.preventDisableExcludedServiceTypesEnhancement =
            vm.settings && vm.settings.feature.excludedServiceTypesEnhancement; // Prevent disabling excluded service types enhancement.
          vm.preventDisableRegionalReimbursementSupport =
            vm.settings && vm.settings.feature.regionalReimbursementSupport; // Prevent disabling regional reimbursement support.
          vm.preventDisableNYearBenefitsEnhancement =
            vm.settings && vm.settings.feature.nYearBenefitsEnhancement; // Prevent disabling n-year benefits enhancement.
          vm.preventDisableHospitalisationWaitingPeriodEnhancement =
            vm.settings && vm.settings.feature.hospitalisationWaitingPeriodEnhancement; // Prevent disabling hospitalizaiton waiting period enhancement.
          vm.preventDisableIndonesiaAndThailandRegionalExpansion =
            vm.settings && vm.settings.feature.indonesiaAndThailandRegionalExpansion; // Prevent disabling Indonesia and Thailand regional expansion.
          vm.preventDisableRegionalExpansionForInpatient =
            vm.settings && vm.settings.feature.regionalExpansionForInpatient; // Prevent disabling regional expansion inpatient.
          vm.loading -= 1;
        });
    }

    function saveAll() {
      vm.settings.pharmacySales = vm.pharmacySaleSettingsList;

      if (validateSettings() && $scope.settings.$valid) {
        vm.saving += 1;
        hostSettingsSvc
          .updateAllSettings(vm.settings)
          .success(() => {
            abp.notify.info(App.localize('SuccessfullySaved'));
            vm.preventDisableSobEnhancement = vm.settings.feature.sobEnhancement; // Prevent disabling SOB enhancement after enabled.
            vm.preventDisableTxtFileSupport = vm.settings.feature.txtFileSupport; // Prevent disabling txt file support after enabled.
            vm.preventDisableRegionalReimbursementSupport =
              vm.settings.feature.regionalReimbursementSupport; // Prevent disabling regional reimbursement support after enabled.

            // Prevent disabling regional expansion for inpatient after enabled.

            vm.preventDisableRegionalExpansionForInpatient =
              vm.settings.feature.regionalExpansionForInpatient;

            getPharmacySalesSettings();
          })
          .finally(() => {
            vm.saving -= 1;
          });
      } else {
        abp.notify.error(App.localize('FailedToSave'));
      }
    }

    function getLanguage(code) {
      return App.localize(code);
    }

    function calculateDuration() {
      vm.midnightDuration = '-';
      if (vm.settings && vm.settings.treatmentManagement) {
        const endTime = moment(vm.settings.treatmentManagement.midnightEndTime);
        const startTime = moment(vm.settings.treatmentManagement.midnightStartTime);
        if (endTime.isValid() && startTime.isValid()) {
          let hrs = (endTime.valueOf() - startTime.valueOf()) / 1000 / 60 / 60;
          if (hrs <= 0) hrs += 24;
          vm.midnightDuration = hrs;
        }
      }
    }

    function testZendeskConnection() {
      vm.testing += 1;
      hostSettingsSvc
        .testZendeskApiConnection({
          username: vm.settings.integration.zendesk.adminUsername,
          apiKey: vm.settings.integration.zendesk.apiKey,
        })
        .success((result) => {
          vm.zendeskTestResult = result;
        })
        .finally(() => {
          vm.testing -= 1;
        });
    }

    function validateSettings() {
      if (Number.isNaN(vm.settings.general.minimumAppBuildNumberSupported)) {
        abp.notify.error('Minimum app build number support is not a number');
        return false;
      }

      if (Number.isNaN(vm.settings.general.minimumPwaBuildNumberSupported)) {
        abp.notify.error('Minimum PWA build number support is not a number');
        return false;
      }

      return true;
    }

    function getBanks() {
      vm.loading += 1;
      commonLookupSvc
        .getBanks()
        .success((data) => {
          vm.banks = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function openOneSignalNotificationCategoryModal() {
      const modal = $modal.open({
        templateUrl: require('./oneSignalNotificationCategory/oneSignalNotificationCategory.modal.html'),
        controller:
          'host.views.settings.oneSignalNotificationCategory.oneSignalNotificationCategory.modal as vm',
        backdrop: 'static',
        resolve: {
          categories: vm.categories,
        },
      });

      modal.result.then(() => {});
    }

    function getSmsProviders() {
      vm.loading += 1;
      smsSvc
        .getSmsProviders()
        .success((data) => {
          vm.smsProviders = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function addNewPharmacySale() {
      removeSelectedCountry();
      vm.pharmacySaleSettingsList.push({});
    }

    function deleteLatestPharmacySale() {
      vm.pharmacySaleSettingsList.splice(vm.pharmacySaleSettingsList.length-1, 1);
      removeSelectedCountry();
    }

    function getEligibleCountries() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
          vm.filteredCountries = vm.countries;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTeleconsultServices() {
      vm.loading += 1;
      commonLookupSvc
        .getTeleconsultServices()
        .success((data) => {
          vm.teleconsultServices = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getPharmacySalesSettings() {
      vm.loading += 1;
      commonLookupSvc
        .getPharmacySalesSettings()
        .success((data) => {
          vm.pharmacySaleSettingsList = data;
          vm.filteredCountries = vm.countries;

          setTimeout(() => {
            removeSelectedCountry();
          }, 500);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function removeSelectedCountry() {
      const selectedCountries = _.map(vm.pharmacySaleSettingsList, 'countryCode');
      vm.filteredCountries = _.filter(vm.countries, country =>
        !_.includes(selectedCountries, country.code));
    }
  }
})();
