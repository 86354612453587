import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller('common.views.patientTickets.detail.index', PatientTicketDetailPageController);

  PatientTicketDetailPageController.$inject = [
    '$state',
    '$stateParams',
    '$filter',
    '$uibModal',
    '$window',
    'abp.services.app.patientTicket',
    'abp.services.app.latePatientTicketSubmission',
    'Hms.Employees.EmployeeState',
    'Hms.PatientTickets.PatientTicketType',
    'moment',
  ];

  function PatientTicketDetailPageController(
    $state,
    $stateParams,
    $filter,
    $modal,
    $window,
    patientTicketSvc,
    latePatientTicketSubmissionSvc,
    enumEmployeeState,
    enumPatientTicketType,
    moment
  ) {
    const vm = this;

    const treatmentTreeElementId = 'uncoveredTreatmentTree';

    const unitValueFilter = $filter('unitValue');
    const frequencyUnitValueFilter = $filter('frequencyUnitValue');
    const nationalIdFilter = $filter('nationalIdFilter');

    vm.loading = 0;
    vm.ticketNumber = $stateParams.ticketNumber;
    vm.latePatientTicketSubmissionId = $stateParams.latePatientTicketSubmissionId;
    vm.ticket = null;
    vm.patient = null;
    vm.clinic = null;
    vm.report = null;
    vm.lateSubmission = null;
    vm.isClinic = App.isClinic();
    vm.isHost = App.isHost();
    vm.isCorporate = App.isCorporate();
    vm.isInpatient = App.isInpatient();
    vm.hasSpecialistModule = vm.isHost || abp.features.isEnabled('Specialist.Enabled');
    vm.isEditingSpecialistDetail = false;
    vm.limitPeriod = limitPeriod;
    vm.isSpecialistTicket = false;
    vm.isHealthScreeningTicket = false;
    vm.isPostHospitalizationTicket = false;
    vm.diagnoses = [];
    vm.diagnosesDisplay = '';
    vm.specialistNewVersion = 'V2';

    vm.hasMemberBenefitUtilizationEnabled = abp.setting.getBoolean(
      'Hms.Feature.MemberBenefitUtilization'
    );
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.isPossibleDuplicatedTicket = false;

    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.applicationHasHealthScreeningModule = abp.setting.getBoolean(
      'Hms.Feature.HealthScreeningModule'
    );
    vm.hasRegionalReimbursementSupport = abp.setting.getBoolean(
      'Hms.Feature.RegionalReimbursementSupport'
    );
    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );

    // Increse these values to trigger child component methods.

    vm.saveSpecialistDetailTrigger = 0;
    vm.finishSpecialistDetailWithoutSavingTrigger = 0;

    vm.enums = {
      employeeState: enumEmployeeState,
      patientTicketType: enumPatientTicketType,
    };

    vm.permissions = {
      edit: false,
      editSpecialistDetail: false,
      editDate: false,
      editManualPayment: false,
      delete: false,
      void: false,
    };
    vm.onTreatmentAmountDirtyEvent = {
      handler: null,
    };

    vm.isEditingTreatmentAmount = false;
    vm.saveTicketAmountTrigger = 0;
    vm.finishTicketAmountWithoutSavingTrigger = 0;

    vm.showActualDateRemark = showActualDateRemark;
    vm.getDependentDescription = getDependentDescription;
    vm.getMcDurationDescription = getMcDurationDescription;
    vm.getDoctorMmcRegistrationNumber = getDoctorMmcRegistrationNumber;
    vm.delete = deleteManualTicket;
    vm.void = voidTicket;
    vm.editDate = editDate;
    vm.editManualPayment = editManualPayment;
    vm.getExaminationRecord = getExaminationRecord;
    vm.getUnitValueStr = getUnitValueStr;
    vm.getDosageFrequencyStr = getDosageFrequencyStr;
    vm.editTicket = editTicket;
    vm.openApproveModal = openApproveModal;
    vm.openRejectModal = openRejectModal;
    vm.openUpdateIssueNumberModal = openUpdateIssueNumberModal;
    vm.delegateToHr = delegateToHr;
    vm.resendResolutionEmail = resendResolutionEmail;
    vm.isDirty = isDirty;
    vm.onSuccessSaveTicketAmount = onSuccessSaveTicketAmount;
    vm.onUpdateTicketAmount = onUpdateTicketAmount;
    vm.search = search;
    vm.triggerBack = triggerBack;
    vm.isSpecialistTicketV2 = isSpecialistTicketV2;

    init();

    function init() {
      if (vm.latePatientTicketSubmissionId) getLateSubmissionTicketDraft();
      else getTicket();
    }

    function getLateSubmissionTicketDraft() {
      vm.loading += 1;
      latePatientTicketSubmissionSvc
        .getPatientTicketDraft({
          id: $stateParams.latePatientTicketSubmissionId,
        })
        .success((data) => {
          vm.ticket = data.ticket;
          vm.patient = data.patient;
          vm.clinic = data.clinic;
          vm.currencyCode = data.currencyCode;
          vm.ticketNumber = data.ticket.ticketNumber;
          vm.lateSubmission = data.lateSubmission;
          vm.reportVersion = data.reportVersion;
          vm.report = vm.reportVersion === 'v0' ? data.reportV0 : data.reportV191018;
          vm.uncoveredTreatments = data.lateSubmission.patientUncoveredTreatments;

          // If is pre-employment ticket, get the treatment that represent package.

          if (vm.ticket.ticketType === 3) {
            vm.package = _.first(vm.ticket.treatments);
            vm.packageTreatments = _.tail(vm.ticket.treatments);
          }

          // Calculate tax amount
          if (vm.ticket.taxPercentage > 0) {
            if (vm.clinic.isTaxInclusive) {
              vm.coveredTaxAmount =
                (vm.ticket.originalCoveredAmount / (100 + vm.ticket.taxPercentage)) *
                vm.ticket.taxPercentage;
              vm.uncoveredTaxAmount =
                (vm.ticket.uncoveredAmount / (100 + vm.ticket.taxPercentage)) *
                vm.ticket.taxPercentage;
            } else {
              vm.coveredTaxAmount =
                (vm.ticket.originalCoveredAmount * vm.ticket.taxPercentage) / 100;
              vm.uncoveredTaxAmount = (vm.ticket.uncoveredAmount * vm.ticket.taxPercentage) / 100;
            }
          }

          if (vm.ticket.coPayAmount) {
            vm.eligibleClaimAmount = Math.max(
              vm.ticket.originalCoveredAmount - vm.ticket.coPayAmount,
              0
            );
          } else {
            vm.eligibleClaimAmount = vm.ticket.originalCoveredAmount;
          }

          if (vm.lateSubmission.coPayValue) {
            vm.coPayValueDisplay = vm.lateSubmission.coPayValue.isPercentage
              ? `${vm.lateSubmission.coPayValue.value}%`
              : $filter('currencyFormat')(vm.lateSubmission.coPayValue.value, vm.currencyCode);
          } else {
            vm.coPayValueDisplay = 'NotApplicable';
          }

          vm.finalCoveredAmount = vm.ticket.coveredAmount;

          buildTreatmentTree(treatmentTreeElementId);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function buildTreatmentTree(elementId) {
      const element = $(`#${elementId}`);
      const ref = $.jstree.reference(element);
      if (ref) {
        ref.destroy();
      }

      const coveredNodeIcon = '<i class="fas fa-check text-navy"></i> ';
      const uncoveredNodeIcon = '<i class="fas fa-times text-danger"></i> ';

      function getNodeText(data) {
        return (
          (!data.isCovered || data.hasUncoveredChildren ? uncoveredNodeIcon : coveredNodeIcon) +
          data.displayName
        );
      }

      const treatments = _.map(vm.uncoveredTreatments, (record) => ({
        id: record.id,
        parent: record.parentId ? record.parentId : '#',
        displayName: record.displayName,
        text: getNodeText(record),
        state: {
          opened: record.hasUncoveredChildren,
        },
      }));

      element
        .jstree({
          core: {
            data: treatments,
          },
          types: {
            default: {
              icon: 'fas fa-folder tree-item-icon-color icon-lg',
            },
            file: {
              icon: 'fas fa-file tree-item-icon-color icon-lg',
            },
          },
          search: {
            fuzzy: false,
            show_only_matches: true,
            show_only_matches_children: true,
          },
          sort(itemA, itemB) {
            const nodeA = this.get_node(itemA);
            const nodeB = this.get_node(itemB);
            if (nodeB.original.id === -1) {
              return 1;
            }
            if (nodeA.children_d.length > 0 && nodeB.children_d.length === 0) {
              return -1;
            }

            return nodeB.original.displayName < nodeA.original.displayName ? 1 : -1;
          },
          plugins: ['types', 'search', 'sort'],
        })
        .bind('select_node.jstree', (_e, data) => {
          const { href } = data.node.a_attr;
          if (href === '#') return '';

          window.open(href);
          return '';
        });
    }

    function getTicket() {
      vm.loading += 1;
      patientTicketSvc
        .getTicketDetail({
          id: $stateParams.ticketNumber,
        })
        .success((data) => {
          vm.ticket = data.ticket;
          vm.patient = data.patient;
          vm.clinic = data.clinic;
          vm.hasInsurerClaim = data.hasInsurerClaim;
          vm.ticketNumber = data.ticket.ticketNumber;
          vm.reportVersion = data.reportVersion;
          vm.report = vm.reportVersion === 'v0' ? data.reportV0 : data.reportV191018;
          vm.utilizationAdjustments = data.utilizationAdjustments;
          vm.currencyCode = data.currencyCode;
          vm.isPossibleDuplicatedTicket = data.isPossibleDuplicatedTicket;

          if (vm.ticket.claimCoPayValue) {
            vm.sessionCopayAmount = vm.ticket.claimIsCoPayPercentage
              ? `${vm.ticket.claimCoPayValue}%`
              : $filter('currencyFormat')(vm.ticket.claimCoPayValue, vm.currencyCode);
          }

          if (vm.ticket.shippingIsCovered) {
            vm.ticket.originalCoveredAmount += vm.ticket.shippingFee;
          }

          if (vm.ticket.coPayAmount) {
            vm.eligibleClaimAmount = Math.max(
              vm.ticket.originalCoveredAmount - vm.ticket.coPayAmount,
              0
            );
            vm.finalCoveredAmount = vm.ticket.amount - vm.ticket.manualPayment;
          } else {
            vm.eligibleClaimAmount = vm.ticket.originalCoveredAmount;
            vm.finalCoveredAmount = vm.ticket.coveredAmount;
          }

          if (vm.ticket.ticketType === 2) {
            if (vm.eligibleClaimAmount !== vm.ticket.coveredAmount) {
              vm.ticket.exceededAmount = vm.eligibleClaimAmount - vm.ticket.coveredAmount;
            }
          }

          if (vm.patient.coPayValue) {
            vm.coPayValueDisplay = vm.patient.isCoPayPercentage
              ? `${vm.patient.coPayValue}%`
              : `${$filter('currencyFormat')(vm.patient.coPayValue, vm.currencyCode)}`;
          } else {
            vm.coPayValueDisplay = 'NotApplicable';
          }

          // Set coPayamount to 0 if null
          vm.ticket.coPayAmount = vm.ticket.coPayAmount ?? 0;

          vm.isSpecialistTicket = vm.ticket.serviceType === 5;
          vm.isHealthScreeningTicket = vm.ticket.serviceType === 9;
          vm.isInpatientTicket = vm.ticket.serviceType === 7 || vm.ticket.serviceType === 8;
          vm.isPostHospitalizationTicket = vm.ticket.serviceType === 10;

          // If is pre-employment ticket, get the treatment that represent package.

          if (vm.ticket.ticketType === 3) {
            vm.package = _.first(vm.ticket.treatments);
            vm.packageTreatments = _.tail(vm.ticket.treatments);
          }

          // Format waive service fee reason.

          if (vm.ticket && vm.ticket.bankingDetails) {
            const { bankingDetails } = vm.ticket;

            vm.serviceFeePayorRemark = localizeServiceFeePayorRemark(
              bankingDetails.serviceFeePayor,
              bankingDetails.serviceFeePayorRemark
            );
          }

          // Show doctor name as N/A for specialist tickets without doctor.

          if (
            vm.isHost &&
            vm.ticket &&
            (vm.isSpecialistTicket || vm.isHealthScreeningTicket) &&
            !vm.ticket.doctorName
          )
            vm.ticket.doctorName = App.localize('NotAvailableShort');

          // If is specialist claim ticket, get specialist claim attachments.

          if (
            vm.ticket &&
            (vm.isSpecialistTicket 
              || vm.isHealthScreeningTicket 
              || vm.isPostHospitalizationTicket) &&
            vm.ticket.ticketType === 0
          ) {
            getClaimTicketAttachments(checkAttachments);
          }

          checkAttachments();

          if (isSpecialistTicketV2()) {
            vm.diagnoses = _.map(vm.ticket.diagnoses, (d) => ({
              code: d.code,
              description: d.description,
              displayName: d.code !== null ? `(${d.code}) ${d.description}` : `${d.description}`,
            }));
            vm.diagnosesDisplay = vm.diagnoses.map((_) => _.displayName).join(', ');
          }
        })
        .finally(() => {
          vm.loading -= 1;

          if (vm.ticket) {
            checkIsImage(vm.ticket.attachments);

            if (vm.ticket.ticketType === 0 || vm.ticket.ticketType === 3) {
              if (vm.isSpecialistTicket) {
                vm.permissions.edit =
                  vm.isSpecialistTicket &&
                  abp.auth.isGranted('PatientTickets.Host.Specialist.Edit');
              } else if (vm.isHealthScreeningTicket) {
                vm.permissions.edit =
                  vm.isHealthScreeningTicket &&
                  abp.auth.isGranted('PatientTickets.Host.HealthScreening.Edit');
              } // Post hospitalization permission here 
              else {
                vm.permissions.edit = !vm.isCorporate && abp.auth.isGranted('PatientTickets.Edit');
              }
              vm.permissions.editSpecialistDetail =
                (vm.isSpecialistTicket 
                  || vm.isHealthScreeningTicket 
                  || vm.isPostHospitalizationTicket) &&
                abp.auth.isGranted('PatientTickets.Host.Specialist.EditDetail');
              vm.permissions.editDate = abp.auth.isGranted('PatientTickets.EditTicketDate');
              vm.permissions.editManualPayment = abp.auth.isGranted(
                'PatientTickets.EditManualPayment'
              );
              vm.permissions.delete = false;
              vm.permissions.void = abp.auth.isGranted('PatientTickets.Void');
              vm.permissions.voidDuplicateTicket = abp.auth.isGranted(
                'PatientTickets.Void.DuplicateTicket'
              );
              vm.permissions.editAdvanced =
                (vm.isSpecialistTicket &&
                  abp.auth.isGranted('PatientTickets.Host.Specialist.Edit.Advanced')) ||
                (vm.isHealthScreeningTicket &&
                  abp.auth.isGranted('PatientTickets.Host.HealthScreening.Edit.Amount')) ||
                (vm.isPostHospitalizationTicket &&
                  abp.auth.isGranted('PatientTickets.Host.Specialist.Edit.Advanced')) ;
            } else if (vm.ticket.ticketType === 1) {
              vm.permissions.edit =
                abp.auth.isGranted('PatientTickets.Manual.Edit') ||
                abp.auth.isGranted('PatientTickets.Host.Manual.Edit');
              vm.permissions.editSpecialistDetail = false;
              vm.permissions.editDate = false;
              vm.permissions.delete =
                abp.auth.isGranted('PatientTickets.Manual.Delete') ||
                ((vm.isSpecialistTicket 
                  || vm.isHealthScreeningTicket 
                  || vm.isPostHospitalizationTicket) &&
                  abp.auth.isGranted('PatientTickets.Host.Manual.Specialist.Delete')) ||
                (vm.isInpatientTicket &&
                  abp.auth.isGranted('PatientTickets.Host.Manual.Inpatient.Delete'));
              vm.permissions.editManualPayment = false;
              vm.permissions.void = false;
            } else if (vm.ticket.ticketType === 2) {
              vm.permissions.edit = abp.auth.isGranted('PatientTickets.Reimbursement.Edit');
              vm.permissions.editSpecialistDetail = false;
              vm.permissions.editDate = false;
              vm.permissions.delete = false;
              vm.permissions.editManualPayment = abp.auth.isGranted(
                'PatientTickets.EditManualPayment'
              );
              vm.permissions.void = abp.auth.isGranted('PatientTickets.Void');
              vm.permissions.manageClaim =
                abp.auth.isGranted('Host.Claims.Manage') ||
                abp.auth.isGranted('Host.Claims.Inpatient.Manage');
            }
          }
        });
    }

    function getClaimTicketAttachments(callback) {
      vm.loading += 1;
      patientTicketSvc
        .getClaimTicketAttachments({
          id: $stateParams.ticketNumber,
        })
        .success((data) => {
          vm.ticket.specialistAttachments = data;
          _.forIn(vm.ticket.specialistAttachments, (d) => {
            checkIsImage(d);
          });
        })
        .finally(() => {
          if (callback) callback();
          vm.loading -= 1;
        });
    }

    function checkIsImage(attachments) {
      _.each(attachments, (attachment) => {
        const a = attachment;
        a.isImage = a.contentType.startsWith('image/');
      });
    }

    function checkAttachments() {
      vm.hasSpecialistAttachments =
        vm.ticket.specialistAttachments && _.some(vm.ticket.specialistAttachments, (d) => d.length);
      vm.hasAttachments =
        (vm.ticket.attachments && vm.ticket.attachments.length) || vm.hasSpecialistAttachments;
    }

    function localizeServiceFeePayorRemark(payor, remark) {
      let output = '';
      switch (payor) {
        case 0:
          output = App.localize('ServiceFeeWaivedBecauseX', remark);
          break;
        case 1:
          output = App.localize('ServiceFeePaidByCorporate');
          break;
        case 2:
          output = App.localize('ServiceFeePaidByClaimantBecauseX', remark);
          break;

        // no default
      }
      return output;
    }

    function showActualDateRemark() {
      return (
        vm.hasSpecialistModule &&
        vm.ticket &&
        vm.ticket.actualVisitDate &&
        moment(vm.ticket.ticketDate).format('LL') !== moment(vm.ticket.actualVisitDate).format('LL')
      );
    }

    function getDependentDescription() {
      return App.localize(
        'DependentDescription',
        vm.patient.sponsorName,
        nationalIdFilter(vm.patient.sponsorNationalId)
      );
    }

    function getMcDurationDescription() {
      if (vm.ticket.medicalCertificate) {
        if (vm.ticket.ticketType !== 0) {
          return App.localize('XDays', vm.ticket.medicalCertificate.effectiveMcDays);
        }
        const startDate = moment(vm.ticket.medicalCertificate.startDate);
        const endDate = moment(vm.ticket.medicalCertificate.endDate);
        const duration = endDate.diff(startDate, 'days') + 1;
        return App.localize('XDays', duration);
      }
      return '';
    }

    function getDoctorMmcRegistrationNumber() {
      if (vm.ticket.doctorMmcRegistrationNumber) {
        switch (vm.ticket.countryCode) {
          case 'MY':
            return `(${App.localize('MMCRegistrationNumberShort')}: ${
              vm.ticket.doctorMmcRegistrationNumber
            })`;
          default:
            return `(${App.localize('DoctorRegistrationNumberShort')}: ${
              vm.ticket.doctorMmcRegistrationNumber
            })`;
        }
      }
      return '';
    }

    function deleteManualTicket() {
      abp.message.confirm(
        App.localize('ManualTicketDeleteWarningMessage', vm.ticketNumber),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            patientTicketSvc
              .deleteManualTicket({
                id: vm.ticketNumber,
              })
              .success(() => {
                abp.notify.success(App.localize('SuccessfullyDeleted'));
                $window.history.back();
              });
          }
        }
      );
    }

    function voidTicket() {
      swal(
        {
          title: App.localize('VoidPatientTicket'),
          text: App.localize('WhyVoidPatientTicket'),
          type: 'input',
          showCancelButton: true,
          closeOnConfirm: false,
          confirmButtonColor: '#1ab394',
          inputPlaceholder: App.localize('PleaseExplain'),
          showLoaderOnConfirm: false,
        },
        (inputValue) => {
          if (inputValue === false) return false;
          if (_.trim(inputValue || '') === '') {
            swal.showInputError(App.localize('InvalidInput'));
            return false;
          }

          abp.message.confirm(
            App.localize('TicketVoidWarningMessage', vm.ticketNumber),
            App.localize('AreYouSure'),
            (d) => {
              if (d) {
                patientTicketSvc
                  .voidTicket({
                    id: vm.ticketNumber,
                    modificationRemarks: _.trim(inputValue),
                  })
                  .success(() => {
                    abp.notify.success(App.localize('SuccessfullyVoided'));
                    $window.history.back();
                    swal.close();
                  });
              }
            }
          );

          return true;
        }
      );
    }

    function editDate() {
      $modal
        .open({
          templateUrl: require('../editDateModal.html'),
          controller: 'common.views.patientTickets.editDateModal as vm',
          backdrop: 'static',
          resolve: {
            ticket() {
              return vm.ticket;
            },
          },
        })
        .result.then(() => {
          getTicket();
        });
    }

    function editManualPayment() {
      $modal
        .open({
          templateUrl: require('../editManualPaymentModal.html'),
          controller: 'common.views.patientTickets.editManualPaymentModal as vm',
          backdrop: 'static',
          resolve: {
            ticket() {
              return vm.ticket;
            },
            hasInsurerClaim: vm.hasInsurerClaim,
            currencyCode() {
              return vm.currencyCode;
            },
          },
        })
        .result.then(() => {
          getTicket();
        });
    }

    function getExaminationRecord() {
      const employmentMedicalString = 'Employment Medical';
      const drugAlcoholScreenString = 'Drug and Alcohol Screen';
      if (vm.report.examination.employmentMedical && vm.report.examination.drugAlcoholScreen) {
        return `${employmentMedicalString}, ${drugAlcoholScreenString}`;
      }
      if (vm.report.examination.employmentMedical && !vm.report.examination.drugAlcoholScreen) {
        return employmentMedicalString;
      }
      return drugAlcoholScreenString;
    }

    function getUnitValueStr(value, unit) {
      return unitValueFilter({
        value,
        unit,
      });
    }

    function getDosageFrequencyStr(value, unit) {
      return frequencyUnitValueFilter({
        value,
        unit,
      });
    }

    function editTicket() {
      let key = 'createEditPatientTicket';
      if (isSpecialistTicketV2())
        key = 'createEditSpecialistTicket';
      else if (vm.isPostHospitalizationTicket) key = 'createEditPostHospitalizationTicket'
      else if (vm.ticket.ticketFormat === 1) key = 'clinic.createEditPatientTicket';
      else if (vm.ticket.ticketType === 1) key = 'createEditManualTicket';

      $state.go(key, {
        ticketNumber: vm.ticketNumber,
        ticketType: vm.ticket.ticketType,
        serviceType: vm.ticket.serviceType,
        accountOwnerCountryCode: vm.ticket.countryCode,
      });
    }

    function openApproveModal() {
      $modal
        .open({
          templateUrl: require('./approveLateSubmission/approveLateSubmission.modal.html'),
          controller: 'common.views.patientTickets.detail.approveLateSubmissionModal as vm',
          backdrop: 'static',
        })
        .result.then((reason) => {
          approveLateSubmission(reason);
        });
    }

    function openRejectModal() {
      $modal
        .open({
          templateUrl: require('./rejectLateSubmission/rejectLateSubmission.modal.html'),
          controller: 'common.views.patientTickets.detail.rejectLateSubmissionModal as vm',
          backdrop: 'static',
          resolve: {
            isDeletedEmployee() {
              return vm.lateSubmission.isDeletedEmployee;
            },
          },
        })
        .result.then((reason) => {
          if (reason) {
            rejectLateSubmission(reason);
          }
        });
    }

    function openUpdateIssueNumberModal() {
      $modal
        .open({
          templateUrl: require('./updateIssueNumber/updateIssueNumber.modal.html'),
          controller: 'common.views.patientTickets.detail.updateIssueNumberModal as vm',
          backdrop: 'static',
        })
        .result.then((issueNumber) => {
          if (issueNumber) {
            updateIssue(issueNumber);
          }
        });
    }

    function delegateToHr(latePatientTicketSubmissionId) {
      latePatientTicketSubmissionSvc
        .getOrganizationEmails({
          Id: latePatientTicketSubmissionId,
        })
        .success((data) => {
          $modal
            .open({
              templateUrl: require('../../../../host/views/operationsDashboard/lateSubmissions/sendEmails.modal.html'),
              controller: 'host.views.operationsDashboard.lateSubmissions.sendEmailsModal as vm',
              backdrop: 'static',
              resolve: {
                title() {
                  return App.localize('DelegateToHR');
                },
                narrative() {
                  return App.localize('DelegateToHRNarrative');
                },
                emailAddresses() {
                  return data.emailAddresses;
                },
              },
            })
            .result.then((emailAddresses) => {
              latePatientTicketSubmissionSvc
                .sendPendingApprovalEmails({
                  id: latePatientTicketSubmissionId,
                  emailAddresses,
                })
                .success(() => {
                  abp.notify.info(App.localize('EmailSent'));
                  getTicket();
                });
            });
        });
    }

    function resendResolutionEmail(latePatientTicketSubmissionId, lastSentTime) {
      if (_.isNil(lastSentTime)) {
        return;
      }
      const formattedSentTime = moment(lastSentTime).format('Do MMMM YYYY');

      latePatientTicketSubmissionSvc
        .getOrganizationEmails({
          Id: latePatientTicketSubmissionId,
        })
        .success((data) => {
          $modal
            .open({
              templateUrl: require('../../../../host/views/operationsDashboard/lateSubmissions/sendEmails.modal.html'),
              controller: 'host.views.operationsDashboard.lateSubmissions.sendEmailsModal as vm',
              backdrop: 'static',
              resolve: {
                title() {
                  return App.localize('ResendResolutionEmail');
                },
                narrative() {
                  return App.localize('ResendResolutionEmailNarrative', formattedSentTime);
                },
                emailAddresses() {
                  return data.emailAddresses;
                },
              },
            })
            .result.then((emailAddresses) => {
              latePatientTicketSubmissionSvc
                .sendPendingApprovalEmails({
                  Id: latePatientTicketSubmissionId,
                  emailAddresses,
                })
                .success(() => {
                  abp.notify.info(App.localize('EmailSent'));
                  getTicket();
                });
            });
        });
    }

    function approveLateSubmission(reason) {
      vm.loading += 1;
      latePatientTicketSubmissionSvc
        .approveSubmission({
          id: vm.latePatientTicketSubmissionId,
          coveredAmount: vm.ticket.coveredAmount,
          manualPayment: vm.ticket.manualPayment,
          remarks: reason,
        })
        .success(() => {
          $state.reload();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function rejectLateSubmission(reason) {
      vm.loading += 1;
      latePatientTicketSubmissionSvc
        .rejectSubmission({
          id: vm.latePatientTicketSubmissionId,
          remarks: reason,
        })
        .success(() => {
          $state.reload();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function updateIssue(issueNumber) {
      vm.loading += 1;
      latePatientTicketSubmissionSvc
        .updateIssue({
          id: vm.latePatientTicketSubmissionId,
          issueNumber,
        })
        .success(() => {
          $state.reload();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Start of itemized treatment amount component section.

    function isDirty() {
      if (vm.onTreatmentAmountDirtyEvent.handler) {
        return vm.onTreatmentAmountDirtyEvent.handler();
      }
      return false;
    }

    function onSuccessSaveTicketAmount() {
      vm.isEditingTreatmentAmount = false;
      $state.reload();
    }

    function onUpdateTicketAmount() {
      let manualPayment = 0;
      let coveredAmount = 0;

      _.forEach(vm.ticket.treatments, (t) => {
        const uncoveredAmount = t.uncoveredAmount || 0;
        const amount = t.amount || 0;

        manualPayment += uncoveredAmount;
        coveredAmount += amount;
      });

      let coPay = vm.ticket.claimIsCoPayPercentage
        ? (coveredAmount * vm.ticket.claimCoPayValue) / 100
        : vm.ticket.claimCoPayValue || 0;

      if (coveredAmount < coPay) {
        coPay = coveredAmount;
      } else {
        coPay = _.min([coveredAmount, coPay]);
      }

      vm.newUncoveredAmount = manualPayment;
      vm.newManualPayment = manualPayment + coPay;
      vm.newCoveredAmount = coveredAmount - coPay;
      vm.newCoPayAmount = coPay;
    }

    // End of itemized treatment amount component section.

    function limitPeriod() {
      let cycle = '';
      if (vm.lateSubmission && vm.lateSubmission.allowancePeriod) {
        switch (vm.lateSubmission.allowancePeriod.cycle) {
          case 0:
            cycle = App.localize('EveryXYears', vm.lateSubmission.allowancePeriod.interval);
            break;
          case 1:
            cycle = App.localize('Yearly');
            break;
          case 2:
            cycle = App.localize('Monthly');
            break;
          case 3:
            cycle = App.localize('Daily');
            break;
          case 4:
            cycle = App.localize('PerVisit');
            break;
          case 5:
            cycle = App.localize('Every6Months');
            break;
          case 6:
            cycle = App.localize('Every4Months');
            break;
          case 7:
            cycle = App.localize('Every3Months');
            break;
          case 8:
            cycle = App.localize('Every2Months');
            break;

          // no default
        }
        cycle = cycle.toLowerCase();
      }
      return cycle;
    }

    function search() {
      const element = $(`#${treatmentTreeElementId}`);
      element.jstree('search', _.trim(vm.filter));
    }

    function triggerBack() {
      $state.go('host.operationsDashboard', {
        targetWidget: 'lateSubmissions',
      });
    }

    function isSpecialistTicketV2() {
      return (
        vm.isSpecialistTicket &&
        vm.hasInsuranceEnhancementModule &&
        vm.ticket.version === vm.specialistNewVersion
      );
    }
  }
})();
