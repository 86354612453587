(() => {
    angular.module('app').component('postHospitalizationCreateQuery', {
      templateUrl: require('./createQuery.page.html'),
      controller: PostHospitalizationCreateQueryController,
      controllerAs: 'vm',
    });
  
    PostHospitalizationCreateQueryController.$inject = [
      '$state', 
      '$stateParams',
      'abp.services.app.serviceRequestQuery'
    ];
  
    function PostHospitalizationCreateQueryController(
      $state,
      $stateParams,
      serviceRequestQuerySvc
    ){
      const vm = this;
      vm.loading = 0;
          vm.saving = 0;
          vm.submit = submit;
          vm.addQuestion = addQuestion;
          vm.addQueryItem = addQueryItem;
          vm.addItem = addItem;
          vm.deleteQuestion = deleteQuestion;
          vm.deleteQueryItem = deleteQueryItem;
          vm.deleteItem = deleteItem;
  
          vm.guaranteeLetterNumber = $stateParams.letterNumber;
          vm.claimNumber = $stateParams.claimNumber;
          vm.timeZoneId = $stateParams.timeZoneId;
          vm.serviceType = $stateParams.serviceType;
  
          vm.queryItems = [
          {
            isGeneralAttention: false,
            doctorId: "",
            type: 1,
            questions: [{}],
            items: [{}],
            newAttachments: []
          }];
  
          /* Attachments */
  
          var fileExtensions = '|' + app.consts.fileUpload.imageExtensions.concat(app.consts.fileUpload.pdfExtensions).join('|') + '|';
          vm.uploadConfig = {
              objectType: 'InpatientQueryItemAttachment',
              itemsLimit: 5,
              accept: 'image/*,application/pdf',
              filters: [{
                  name: 'fileFilter',
                  fn: function (n) {
                      var t = '|' + n.type.slice(n.type.lastIndexOf('/') + 1) + '|';
                      if (fileExtensions.indexOf(t) === -1) {
                          abp.message.warn(App.localize('File_Warn_FileType'));
                          return false;
                      }
                      return true;
                  }
              }, {
                  name: 'sizeFilter',
                  fn: function (n) {
                      var sizeLimit = 52428800;
                      if (n.size > sizeLimit) {
                          abp.message.warn(App.localize('File_Warn_XSizeLimit', sizeLimit / 1024 / 1024));
                          return false;
                      }
                      return true;
                  }
              }]
          };
  
          /* End Attachments */
  
          init();
  
          /* Functions */
  
          function submit() {
              if (!validateQuery()) {
                  return false;
              }
  
              vm.loading++;
  
              _.forEach(vm.queryItems, function (query) {
                  if (query.type === 2) {
                      query.questions = query.items;
                  }
              })
  
              var input = {
                  letterNumber: vm.guaranteeLetterNumber,
                  serviceRequestQueryItems: vm.queryItems
              };
  
              serviceRequestQuerySvc.createQuery(input)
                  .success(function () {
                      abp.notify.success(App.localize('SuccessfullySaved'));
                      $state.go('postHospitalizationGuaranteeLetterDetail', { letterNumber: vm.guaranteeLetterNumber, timeZoneId: vm.timeZoneId, serviceType: vm.serviceType });
                  }).finally(function () {
                      vm.loading--;
                  });
          }
  
          function validateQuery() {
              var isFormValid = true;
  
              // Validate query item
  
              if (_.isEmpty(vm.queryItems)) {
                  abp.message.warn(App.localize('EmptyQueryItemWarning'));
                  return false;
              }
  
              _.forEach(vm.queryItems, function (query) {
                  var isItemEmpty = _.some(query.items, function (item) {
                      return _.isEmpty(item.documentName) && _.isEmpty(item.content);
                  });
  
                  var isQuestionEmpty = _.some(query.questions, function (question) {
                      return _.isEmpty(question.content);
                  });
  
                  // Validate doctor
  
                  if (!query.isGeneralAttention && !query.doctorId) {
                      abp.message.warn(App.localize('EmptyDoctorWarning'));
                      isFormValid = false;
                      return false;
                  }
  
                  // Validate questions
  
                  if (query.type === 1 && (_.isEmpty(query.questions) || isQuestionEmpty)) {
                      abp.message.warn(App.localize('EmptyQuestionWarning'));
                      isFormValid = false;
                      return false;
                  }
  
                  // Validate items
  
                  else if (query.type === 2 && (_.isEmpty(query.items) || isItemEmpty)) {
                      abp.message.warn(App.localize('EmptyItemWarning'));
                      isFormValid = false;
                      return false;
                  }
              });
  
              return isFormValid;
          }
  
          /* Query items */
  
          function addQueryItem() {
              vm.queryItems.push({
                  isGeneralAttention: false,
                  doctorId: "",
                  type: 1,
                  newAttachments: [],
                  questions: [{}],
                  items: [{}]
              });
          }
  
          function deleteQueryItem(index) {
              vm.queryItems.splice(index, 1);
          }
  
          /* End Query items */
  
          /* Query question */
  
          function addQuestion(questionObject) {
              questionObject.push({});
          }
  
          function deleteQuestion(questionObject, index) {
              questionObject.splice(index, 1);
          }
  
          /* End Query question */
  
          /* Query document item */
  
          function addItem(itemObject) {
              itemObject.push({});
          }
  
          function deleteItem(itemObject, index) {
              itemObject.splice(index, 1);
          }
  
          /* End Query document item */
  
          function init() {
              vm.loading++;
  
              serviceRequestQuerySvc.getQueryForCreate({ id: vm.guaranteeLetterNumber })
                  .success(function (data) {
                      vm.doctors = data;
                  }).finally(function () {
                      vm.loading--;
                  });
          }
  
          /* End Functions */
    }
  })();