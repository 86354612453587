(function () {
    angular.module('app').component('clinicPatientTicketConsultation', {
        templateUrl: require('/App/clinic/views/patient/ticket/consultation/widget.html'),
        controller: ['$filter', controller],
        controllerAs: 'vm',
        bindings: {
            isDisabled: '<',
            isExpanded: '<',
            isCompleted: '<',
            onDirtyEvent: '<',
            onSetPristineEvent: '<',
            isCreate: '<',
            isCorporate: '<',
            patient: '<',
            consultationAveragePrice: '<',
            doctors: '<',
            ticket: '<',
            icpcs: '<',
            onCreateDoctor: '&',
            onSyncExpansion: '&',
            onComplete: '&',
            currencyCode: '<',
            countryCode:'<'
        }
    });

    function controller($filter) {
        var vm = this;

        vm.onUpdate = onUpdate;

        vm.$onChanges = function (changes) {
            if (changes.ticket && changes.ticket.currentValue && vm.ticket.doctorId) {
                vm.updateDoctor();
            }

            if (changes.isExpanded) {
                vm.isOpen = vm.isExpanded;
                updatePostHeader();
            }

            if (changes.onDirtyEvent && changes.onDirtyEvent.currentValue) {
                vm.onDirtyEvent.handler = vm.onDirtyEventHandler;
            }

            if (changes.onSetPristineEvent && changes.onSetPristineEvent.currentValue) {
                vm.onSetPristineEvent.handler = vm.onSetPristineEventHandler;
            }

            if (changes.consultationAveragePrice && changes.consultationAveragePrice.currentValue) {
                calculateConsultationAveragePriceRange();
                vm.onUpdate(vm.ticket.consultation);
            }
        };

        vm.$doCheck = function () {
            if (vm.isOpen !== vm.isOpenPreviousValue) {
                // Component expansion MUST be in sync with parent component's isExpanded state to work as intended.
                // uib-accordion toggles isOpen by itself when user clicks on it.

                vm.onSyncExpansion({ isExpanded: vm.isOpen });
                vm.isOpenPreviousValue = vm.isOpen;
            }
        };

        vm.doctor = {};
        vm.isOpen = false;
        vm.isOpenPreviousValue = false;
        vm.isPermanent = 0;
        vm.limitSize = 100;
        vm.isCreate = !vm.ticket.id;
        vm.ticket.hasConsultation = vm.isCreate
            ? !_.isNil(vm.ticket.hasConsultation) ? vm.ticket.hasConsultation : null
            : !!vm.ticket.consultation;

        vm.toggleConsultation = toggleConsultation;

        vm.onDirtyEventHandler = function () {
            return vm.consultationForm.$dirty;
        };

        vm.onSetPristineEventHandler = function () {
            vm.consultationForm.$setPristine();
        };

        vm.tagTransform = function (newTag) {
            return {
                inclusion: newTag
            };
        };

        vm.next = function () {
            if (App.isFormValid(vm.consultationForm) && areFieldsValid()) {
                vm.onComplete();
            }
        };

        vm.updateDoctor = function () {
            vm.doctor = _.find(vm.doctors, function (d) { return d.id === vm.ticket.doctorId });
        };

        vm.lookupDiagnosis = function (filter) {
            var keyword = _.trim(filter || '');
            if (keyword) {
                var filteredDiagnosis = [];

                var regexes = _.chain(keyword.split(' '))
                    .map(function (k) {
                        return _.trim(k.toUpperCase());
                    })
                    .filter(function (k) {
                        return k;
                    })
                    .uniq()
                    .map(function (k) {
                        _.escapeRegExp(k);
                        return {
                            token: k,
                            len: k.length,
                            pattern: new RegExp('\\b' + k, 'i')
                        };
                    })
                    .value();

                _.forEach(vm.icpcs, function (symptom) {
                    var score = 0;
                    _.forEach(regexes, function (r) {
                        if (r.pattern.test(symptom.inclusion)) {
                            score -= r.len;
                        }

                        if (r.pattern.test(symptom.title)) {
                            score -= r.len;
                        }
                    });
                    if (score) {
                        filteredDiagnosis.push({
                            score: score,
                            item: symptom
                        });
                    }
                });

                vm.filteredDiagnosis = _.chain(filteredDiagnosis)
                    .sortBy(['score'])
                    .take(30)
                    .map(function (k) {
                        return k.item;
                    })
                    .uniqBy(function (s) {
                        return s.inclusion;
                    })
                    .value();
            } else {
                vm.filteredDiagnosis = _.take(vm.icpcs, vm.limitSize);
            }
        };

        /* Functions */

        function clearConsultationDetails() {
            vm.ticket.consultation = null;
            vm.ticket.referralLetter = null;
        }

        function toggleConsultation() {
            if (!vm.ticket.hasConsultation) clearConsultationDetails();
            areFieldsValid();
        }

        function areFieldsValid() {
            if ((vm.showSelectOptionMessage = _.isNil(vm.ticket.hasConsultation))) {
                return false;
            }
            return true;
        }

        var priceRangeMargin = parseFloat(abp.setting.get('Hms.General.GpAveragePriceRangeMargin'));
        var maxConsultationAverageAmount = 0;
        var consultationWarningMessage = '';
        function calculateConsultationAveragePriceRange() {
            if (!vm.consultationAveragePrice || !vm.consultationAveragePrice.amount) return;

            var consultationAverageAmount = vm.ticket.hasMidnightSurcharge && vm.ticket.midnightSurchargeRate
                ? vm.consultationAveragePrice.amount * vm.ticket.midnightSurchargeRate
                : vm.consultationAveragePrice.amount;

            // Calculate price range based on ±margin of consultation average amount.

            var minAmount = Math.round(consultationAverageAmount * (1 - priceRangeMargin));
            maxConsultationAverageAmount = Math.round(consultationAverageAmount * (1 + priceRangeMargin));

            consultationWarningMessage = App.localize('OverchargeConsultation',
                $filter('currencyFormat')(minAmount, vm.currencyCode),
                $filter('currencyFormat')(maxConsultationAverageAmount, vm.currencyCode));
        }

        function onUpdate(record) {
            vm.warningMessage = '';
            if (record && record.amount > maxConsultationAverageAmount && consultationWarningMessage) {
                vm.warningMessage = consultationWarningMessage;
            }
        }

        function updatePostHeader() {
            var message = [];

            var doctorInformation = vm.doctor.mmcRegistrationNumber ?
                vm.doctor.name + ' ' + $filter('doctorMmcRegistrationNumber')(vm.doctor, vm.countryCode) :
                vm.doctor.name;

            if (vm.ticket.consultation && vm.ticket.consultation.symptoms) {
                message.push(App.localize('ConsultationNarrative', doctorInformation, vm.ticket.consultation.symptoms.join(', ')));
                message.push(vm.currencyCode + vm.ticket.consultation.amount);
            } else {
                message.push(App.localize('NoConsultationNarrative', doctorInformation));
            }

            if (vm.ticket && vm.ticket.referralLetter) {
                message.push(App.localize('ReferralLetterNarrative'));
                message.push(vm.currencyCode + vm.ticket.referralLetter.amount);
            }

            vm.summaryMessage = message.join('. ');
        }

        /* End of Functions */
    }
})();
