(() => {
  angular.module('app').component('commonPostHospitalizationGuaranteeLettersCommonAdmissionRecordComponent', {
    templateUrl: require('./admissionRecord.component.html'),
    controller: AdmissionRecordController,
    controllerAs: 'vm',
    bindings: {
      admissionRecord: '<',
    }
  });

  AdmissionRecordController.$inject = [
    '$stateParams'
  ];

  function AdmissionRecordController(
    $stateParams
  ) {
    
    const vm = this;
    vm.diagnoses = "";
    vm.doctors = "";

    vm.timeZoneId = $stateParams.timeZoneId;

    init();

    function init() {
      vm.diagnoses = vm.admissionRecord.diagnoses.join(", ");
      vm.doctors = vm.admissionRecord.doctors.join(", ");
    }
  }
})();
