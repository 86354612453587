(() => {
  angular
    .module('app')
    .controller('host.view.specialistTreatmentCategories', SpecialistTreatmentCategoriesController);

  SpecialistTreatmentCategoriesController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$uibModal',
    '$timeout',
    'appConstants',
    'abp.services.app.specialistTreatmentCategory',
    'abp.services.app.commonLookup',
  ];

  function SpecialistTreatmentCategoriesController(
    $scope,
    $state,
    $stateParams,
    $modal,
    $timeout,
    appConstants,
    specialistTreatmentCategorySvc,
    commonLookupSvc
  ) {
    const vm = this;
    vm.loading = 0;
    vm.filterText = $stateParams.filterText || '';
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.status = 'false';
    vm.countryCode = null;

    // Assign permissions

    vm.permissions = {
      create: abp.auth.isGranted('Host.SpecialistTreatmentCategories.Create'),
      edit: abp.auth.isGranted('Host.SpecialistTreatmentCategories.Edit'),
      archive: abp.auth.isGranted('Host.SpecialistTreatmentCategories.Archive'),
    };

    const viewOptions = {
      skipCount: $stateParams.skipCount,
      maxResultCount: $stateParams.maxResultCount,
      sorting: $stateParams.sorting,
    };

    vm.treatmentGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSize,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      columnDefs: [
        {
          enableSorting: false,
          name: App.localize('Actions'),
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
          visible: vm.status === "false",
        },
        {
          displayName: App.localize('SpecialistTreatmentCategoryName'),
          field: 'name',
          minWidth: 120,
        },
        {
          displayName: App.localize('SpecialistTreatmentCategoryCode'),
          field: 'code',
          minWidth: 100,
        },
        {
          displayName: App.localize('Country'),
          field: 'countryCode',
          minWidth: 50,
          visible: vm.hasRegionalSystemSupport,
          cellTemplate: 'countryCodeTemplate',
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          viewOptions.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          vm.getSpecialistTreatmentCategories();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(viewOptions, vm.getSpecialistTreatmentCategories)
        );
      },
      data: [],
    };

    vm.getSpecialistTreatmentCategories = getSpecialistTreatmentCategories;
    vm.createSpecialistTreatmentCategory = createSpecialistTreatmentCategory;
    vm.bulkCreateSpecialistTreatmentCategory = bulkCreateSpecialistTreatmentCategory;
    vm.editSpecialistTreatmentCategory = editSpecialistTreatmentCategory;
    vm.archiveSpecialistTreatmentCategory = archiveSpecialistTreatmentCategory;
    vm.showCreateEditModal = showCreateEditModal;
    vm.showImportModal = showImportModal;

    init();

    function init() {
      $timeout(() => {
        vm.treatmentGridOptions.paginationCurrentPage = Math.floor(
          viewOptions.skipCount / viewOptions.maxResultCount + 1
        );
      });

      if (vm.hasRegionalSystemSupport) {
        vm.loading += 1;
        commonLookupSvc
          .getEligibleCountries()
          .success((data) => {
            vm.countries = data;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function registerStateParams() {
      $state.transitionTo(
        $state.current,
        {
          skipCount: viewOptions.skipCount,
          maxResultCount: viewOptions.maxResultCount,
          sorting: viewOptions.sorting,
          filterText: vm.filterText,
          countryCode: vm.countryCode,
        },
        {
          notify: false,
        }
      );
    }

    function getSpecialistTreatmentCategories() {
      registerStateParams();
      updateGridColumnDefs();
      vm.loading += 1;
      specialistTreatmentCategorySvc
        .getSpecialistTreatmentCategories({
          status: vm.status,
          skipCount: viewOptions.skipCount,
          maxResultCount: viewOptions.maxResultCount,
          sorting: viewOptions.sorting,
          filter: vm.filterText,
          countryCode: vm.countryCode,
        })
        .success((data) => {
          vm.treatmentGridOptions.totalItems = data.totalCount;
          vm.treatmentGridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function createSpecialistTreatmentCategory() {
      showCreateEditModal();
    }

    function bulkCreateSpecialistTreatmentCategory() {
      showImportModal();
    }

    function editSpecialistTreatmentCategory(specialistTreatmentCategory) {
      showCreateEditModal(specialistTreatmentCategory.id);
    }

    function archiveSpecialistTreatmentCategory(specialistTreatmentCategory) {
      abp.message.confirm(
        App.localize(
          'SpecialistTreatmentCategoryArchiveWarningMessage',
          specialistTreatmentCategory.name
        ),
        App.localize('AreYouSure'),
        (result) => {
          if (result) {
            specialistTreatmentCategorySvc
              .archiveSpecialistTreatmentCategory({
                id: specialistTreatmentCategory.id,
              })
              .success(() => {
                vm.getSpecialistTreatmentCategories();
                abp.notify.success(App.localize('SuccessfullyArchived'));
              });
          }
        }
      );
    }

    function showCreateEditModal(id) {
      $modal
        .open({
          templateUrl: require('./createOrEdit.modal.html'),
          controller: 'host.views.specialistTreatmentCategories.createOrEditModal as vm',
          backdrop: 'static',
          resolve: {
            specialistTreatmentCategoryId: id,
          },
        })
        .result.then(() => {
          vm.getSpecialistTreatmentCategories();
        });
    }

    function showImportModal() {
      $modal
        .open({
          templateUrl: require('./import.modal.html'),
          controller: 'host.views.specialistTreatmentCategories.importModal as vm',
          backdrop: 'static',
        })
        .result.then(() => {
          vm.getSpecialistTreatmentCategories();
        });
    }

    function updateGridColumnDefs() {
      vm.treatmentGridOptions.columnDefs = [
        {
          enableSorting: false,
          name: App.localize('Actions'),
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
          visible: vm.status === "false",
        },
        {
          displayName: App.localize('SpecialistTreatmentCategoryName'),
          field: 'name',
          minWidth: 120,
        },
        {
          displayName: App.localize('SpecialistTreatmentCategoryCode'),
          field: 'code',
          minWidth: 100,
        },
        {
          displayName: App.localize('Country'),
          field: 'countryCode',
          minWidth: 50,
          visible: vm.hasRegionalSystemSupport,
          cellTemplate: 'countryCodeTemplate',
        },
      ];
    }
  }
})();
