import swal from 'sweetalert';
import PromptQueue from '../../../../../../lib/prompt-queue';

(() => {
  angular.module('app').component('commonClaimsProcessClaimDetailWidget', {
    templateUrl: require('./widget.html'),
    controller: ControllerController,
    controllerAs: 'vm',
    bindings: {
      receiptDate: '=',
    },
  });

  ControllerController.$inject = [
    '$state',
    '$stateParams',
    '$uibModal',
    '$timeout',
    '$filter',
    'abp.services.app.patientTicket',
    'Hms.MultiTenancy.TenantClinicType',
    'Hms.PatientTickets.PatientTicketItem',
    'moment',
  ];

  function ControllerController(
    $state,
    $stateParams,
    $uibModal,
    $timeout,
    $filter,
    patientTicketSvc,
    enumTenantClinicType,
    constsPatientTicketItem,
    moment
  ) {
    const vm = this;

    vm.constsPatientTicketItem = constsPatientTicketItem;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.defaultMaxAllowance = parseFloat(abp.setting.get('Hms.General.MaxAllowanceAmountLimit'));
    vm.claimNumber = $stateParams.claimNumber;
    vm.isHost = App.isHost();
    vm.isCorporate = App.isCorporate();
    vm.loading = 0;
    vm.saving = 0;
    vm.claimReceiptAmount = 0;
    vm.isSpecialistTicket = false;
    vm.isHealthScreeningTicket = false;

    vm.ticketDate = moment();
    vm.maxTicketDate = moment().endOf('day');

    vm.treatments = [];
    vm.patient = null;
    vm.clinic = {};
    vm.report = null;
    vm.claim = null;
    vm.ticket = { treatments: [] };
    vm.processRemarks = '';

    vm.hasSelfManageNpr = App.isCorporate() && abp.features.isEnabled('PreferSelfManageNPR');
    vm.hasRegionalReimbursementSupport = abp.setting.getBoolean(
      'Hms.Feature.RegionalReimbursementSupport'
    );
    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );

    vm.isCtTicket = false;
    vm.isInpatientAso = false;
    vm.isTaxInclusive = null;

    vm.enums = {};
    vm.enums.tenantClinicType = enumTenantClinicType;

    vm.permissions = {
      manage: abp.auth.isGranted('Host.Claims.Manage'),
      process:
        abp.auth.isGranted('Host.Claims.Manage') &&
        abp.auth.isGranted('PatientTickets.Reimbursement.Create'),
      viewTicket: abp.auth.isGranted('PatientTickets'),
    };

    vm.uploadConfig = {
      objectType: 'TicketAttachment',
    };

    vm.diagnoses = {
      selected: [],
      data: [],
    };
    vm.specialistTreatmentCategories = [];
    vm.sections = [
      {
        treatments: [{}],
      },
    ];

    vm.getTreatmentTotalAmount = getTreatmentTotalAmount;
    vm.getSubtotalAmount = getSubtotalAmount;
    vm.getTaxAmount = getTaxAmount;
    vm.updateTaxOption = updateTaxOption;
    vm.setTicketDate = setTicketDate;
    vm.treatmentList = [];
    vm.resetClaimType = resetClaimType;
    vm.treatmentHasValue = treatmentHasValue;
    vm.treatmentMissed = treatmentMissed;
    vm.checkRemarkRequired = checkRemarkRequired;
    vm.getRemarkPlaceholder = getRemarkPlaceholder;
    vm.addTreatments = addTreatments;
    vm.clearTreatments = clearTreatments;
    vm.removeTreatment = removeTreatment;
    vm.checkDuplicateTreatment = checkDuplicateTreatment;
    vm.updateTreatment = updateTreatment;
    vm.refreshTreatmentAndMcRemarks = refreshTreatmentAndMcRemarks;
    vm.hasUncoveredTreatments = hasUncoveredTreatments;
    vm.getAllowanceDisplay = getAllowanceDisplay;
    vm.calculateAmountCoverage = calculateAmountCoverage;
    vm.getCopayAmount = getCopayAmount;
    vm.getManualPaymentAmount = getManualPaymentAmount;
    vm.getCoveredAmount = getCoveredAmount;
    vm.getTotalAmount = getTotalAmount;
    vm.getTicketTotalAmount = getTicketTotalAmount;
    vm.isTotalBillAmountValid = isTotalBillAmountValid;
    vm.needMc = needMc;
    vm.issueMc = issueMc;
    vm.removeMc = removeMc;
    vm.setMcStartDate = setMcStartDate;
    vm.getMcDurationDescription = getMcDurationDescription;
    vm.getMcStartDateChoice = getMcStartDateChoice;
    vm.save = save;
    vm.resetCopay = resetCopay;
    vm.isSpecialistTicketV2 = isSpecialistTicketV2;
    vm.getTotalCoveredAmount = getTotalCoveredAmount;
    vm.getTotalUncoveredAmount = getTotalUncoveredAmount;
    vm.getEligibleClaimAmount = getEligibleClaimAmount;
    vm.getEntitledAmount = getEntitledAmount;
    vm.getExceededAmount = getExceededAmount;
    vm.getTotalPayableAmount = getTotalPayableAmount;
    vm.getTotalCollectCashAmount = getTotalCollectCashAmount;
    vm.getCoveredTaxAmount = getCoveredTaxAmount;
    vm.getUncoveredTaxAmount = getUncoveredTaxAmount;

    init();

    function init() {
      vm.loading += 1;

      patientTicketSvc
        .processClaim({
          id: $stateParams.claimNumber,
        })
        .success((data) => {
          vm.doctors = data.doctors || [];
          vm.banks = data.banks || [];
          vm.patient = data.patient;
          vm.clinic = data.clinic || {};
          vm.treatments = data.treatments || [];
          vm.claim = data.claim;
          vm.ticket = data.ticket;
          vm.tenantBalance = data.corporateBalance;

          vm.employeeId = data.patient.id;
          vm.isCreate = data.patient.id;
          vm.claimId = data.claim.id;

          if (!vm.isCreate) {
            vm.ticketNumber = data.ticket.ticketNumber;
            vm.employeeId = data.patient.id;
          }

          vm.currencyCode =
            (vm.isCreate ? data.currencyCode : data.ticket.currencyCode) ||
            abp.setting.get('Hms.General.CurrencyCode');

          vm.claim.claimDate = moment.utc(data.claim.claimDate);

          vm.ticketDate = moment.utc(data.ticket.ticketDate);
          vm.receiptDate = vm.ticketDate;

          vm.ticket = data.ticket;
          vm.report = data.ticket.report;

          vm.attachments = vm.ticket.attachments;
          vm.newAttachments = [];

          if (vm.claim) {
            vm.claimReceiptAmount = vm.claim.receiptAmount;
            vm.currencyCode = vm.claim.currencyCode;

            // To apply if tax exclusive
            vm.taxDetails = data.taxDetails;

            _.forEach(vm.claim.attachments, (a) => {
              const attachment = a;
              attachment.isImage = attachment.contentType.startsWith('image/');
            });
          }

          if (vm.ticket.medicalCertificate) {
            vm.ticket.medicalCertificate.startDate = 
              moment.utc(vm.ticket.medicalCertificate.startDate);
            vm.ticket.medicalCertificate.endDate = moment.utc(vm.ticket.medicalCertificate.endDate);
            vm.ticket.checkedInDate = moment.utc(vm.ticket.checkedInDate);
            vm.isMcStartDateAlsoCheckedInDate =
              vm.ticket.medicalCertificate.startDate.diff(
                vm.ticket.checkedInDate.startOf('days'),
                'days'
              ) === 0;
            if (vm.isCreate && vm.claim) {
              vm.ticket.medicalCertificate.remark = vm.claim.treatmentRemarks || 'n/a';
            }
          }
          const strAmt = $filter('currencyFormat')(vm.ticket.coveredAmount, vm.currencyCode);
          vm.reviseCoveredAmountUpwardErrorMessage = App.localize(
            'ReviseCoveredAmountUpwardErrorMessage',
            strAmt
          );

          vm.resetClaimType();

          vm.isSpecialistTicket = vm.ticket.serviceType === 5;
          vm.isHealthScreeningTicket = vm.ticket.serviceType === 9;

          if (data.patient.coPay) {
            vm.ticketCopayValue = data.patient.coPay.isPercentage
              ? App.roundAmount(vm.claimReceiptAmount * (data.patient.coPay.value / 100))
              : data.patient.coPay.value;
          }

          // Tax details
          vm.taxCode = null;
          vm.taxPercentage = null;

          if (vm.hasRegionalReimbursementSupport && vm.claim.countryCode === 'SG') {
            if (vm.ticket.taxCode && vm.ticket.taxPercentage) {
              vm.taxCode = vm.ticket.taxCode;
              vm.taxPercentage = vm.ticket.taxPercentage;
              vm.taxDescription = vm.ticket.taxDescription;
            } else {
              vm.taxCode = vm.taxDetails.taxCode;
              vm.taxPercentage = vm.taxDetails.taxPercentage;
              vm.taxDescription = vm.taxDetails.taxDescription;
            }
          }

          if (vm.hasInsuranceEnhancementModule) {
            vm.diagnoses.data = _.map(data.diseaseClassifications, (d) => ({
              code: d.code,
              description: d.description,
              displayName: `(${d.code}) ${d.description}`,
            }));

            vm.specialistTreatmentCategories = data.specialistTreatmentCategories;
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function setTicketDate() {
      if (vm.isCreate) {
        refreshAllowance();
        vm.receiptDate = vm.ticketDate;
      }

      if (vm.isCreate && vm.ticket.medicalCertificate) {
        vm.ticket.medicalCertificate.startDate = moment(vm.ticketDate).startOf('day');
        vm.ticket.medicalCertificate.endDate = moment(vm.ticketDate).startOf('day');
      }
    }

    /* Treatment Management */

    function resetClaimType() {
      vm.isSpecialistTicket = vm.ticket.serviceType === 5;
      vm.isHealthScreeningTicket = vm.ticket.serviceType === 9;
      vm.isCtTicket = _.some(
        vm.claim.claimServiceTypes,
        (st) => st.id === vm.ticket.serviceType && st.isCustom
      );
      vm.isInpatientAso = vm.ticket.serviceType === 251;
      vm.treatmentList =
        !vm.isCtTicket && !vm.isInpatientAso
          ? _.filter(vm.treatments, (d) => d.serviceType === vm.ticket.serviceType)
          : null;
      vm.clearTreatments();

      if (vm.treatmentList && vm.isSpecialistTicket && vm.claim.specialistDisciplineId) {
        const defaultTreatment = _.find(
          vm.treatmentList,
          (d) => d.specialistDisciplineId === vm.claim.specialistDisciplineId
        );

        if (vm.hasInsuranceEnhancementModule) {
          vm.sections[0].disciplineId = defaultTreatment.specialistDisciplineId;
        } else {
          vm.ticket.treatments[0].treatmentId = defaultTreatment.id;
          vm.ticket.treatments[0].treatment = defaultTreatment;
        }
      }

      refreshAllowance();
    }

    function treatmentHasValue(treatment) {
      if (isSpecialistTicketV2())
        return treatment.code || hasRemark(treatment) || treatment.coveredAmount;

      return treatment.treatment || hasRemark(treatment) || treatment.amount;
    }

    function treatmentMissed(treatment) {
      if (isSpecialistTicketV2())
        return !treatment.code && (hasRemark(treatment) || treatment.coveredAmount);

      return !treatment.treatment && (hasRemark(treatment) || treatment.amount);
    }

    function checkRemarkRequired(treatment) {
      return (
        vm.treatmentHasValue(treatment) &&
        ((treatment.treatment &&
          treatment.treatment.requiresRemark &&
          !treatment.treatment.notCovered) ||
          vm.isCtTicket ||
          vm.isInpatientAso)
      );
    }

    function getRemarkPlaceholder(treatment) {
      if (!treatment.treatment) return '';
      if (vm.ticket.serviceType === 1) {
        return _.toUpper(_.trim(treatment.treatment.displayName || '')) ===
          _.toUpper('Consultation')
          ? App.localize('ConsultationRemarksNarrative')
          : App.localize('TreatmentRemarksNarrative');
      }
      if (vm.checkRemarkRequired(treatment)) return App.localize('PleaseExplain');
      return '';
    }

    function hasTreatments() {
      const treatments = isSpecialistTicketV2()
        ? _.flatten(_.map(vm.sections, (s) => s.treatments))
        : vm.ticket.treatments;

      return _.some(treatments, (t) => vm.treatmentHasValue(t));
    }

    function refreshTicketTreatments() {
      if (isSpecialistTicketV2()) return;

      // For CT ticket, auto-assign treatment if other fields are filled.
      // And unassign if other fields are empty.
      // This adjustment is for amount calculation to be able to handle custom treatment normally.

      if (vm.isCtTicket || vm.isInpatientAso) {
        const defaultTreatment = _.find(
          vm.treatments,
          (t) => t.serviceType === vm.ticket.serviceType
        );
        _.each(vm.ticket.treatments, (treatment) => {
          const t = treatment;
          if (hasRemark(t) || t.amount) {
            t.treatment = defaultTreatment;
            t.treatmentId = t.treatment.id;
          } else {
            t.treatment = null;
            t.treatmentId = null;
          }
        });
      }

      let hasBlank = _.some(vm.ticket.treatments, (t) => !vm.treatmentHasValue(t));

      while (!hasBlank || vm.ticket.treatments.length < 2) {
        vm.ticket.treatments.push({});
        hasBlank = true;
      }
    }

    function addTreatments() {
      for (let i = 0; i < 4; i += 1) {
        vm.ticket.treatments.push({});
      }
    }

    function clearTreatments() {
      if (isSpecialistTicketV2()) {
        vm.sections = [
          {
            treatments: [{}],
          },
        ];

        const refTreatment = vm.sections[0].treatments[0];
        refTreatment.coveredDescription = vm.claim.treatmentRemarks;
        refTreatment.coveredAmount = vm.claim.receiptAmount;
      } else {
        vm.ticket.treatments = [{}];

        // Push the default claim message and remarks.

        const refTreatment = vm.ticket.treatments[0];
        refTreatment.remark = vm.claim.treatmentRemarks;

        if (refTreatment.remark && !vm.isSpecialistTicket) {
          refTreatment.remarkTags = _.chain(_.split(vm.claim.treatmentRemarks, ','))
            .map((data) => _.trim(data || ''))
            .filter((data) => data.length > 0)
            .uniqBy((data) => data.toUpperCase())
            .value();
        }

        refTreatment.treatmentId = null;
        refTreatment.amount = vm.claim.receiptAmount;
      }

      vm.refreshTreatmentAndMcRemarks();
    }

    function removeTreatment(index) {
      if (index < vm.ticket.treatments.length) {
        vm.ticket.treatments.splice(index, 1);
        vm.refreshTreatmentAndMcRemarks();
      }
    }

    function checkDuplicateTreatment(treatment) {
      if (treatment.isDuplicable) return false;

      return _.some(vm.ticket.treatments, (t) => t.treatmentId === treatment.id);
    }

    function updateTreatment(t) {
      const treatment = t;

      if (treatment.treatment) {
        const treatments = _.filter(
          vm.ticket.treatments,
          (t2) => t2 && t2.treatment && t2.treatment.id !== treatment.treatment.id
        );

        if (vm.checkDuplicateTreatment(treatment.treatment)) {
          abp.message.error(App.localize('DuplicateTreatmentValidationErrorMessage'));
          treatment.treatmentId = null;
          treatment.treatment = null;
          treatment.amount = null;
          return;
        }

        treatment.treatmentId = treatment.treatment.id;
        if (treatments.length === 0) {
          $timeout(() => {
            treatment.amount = vm.claim.receiptAmount;
          }, 50);
        }

        if (treatment.treatment.notCovered) {
          treatment.amount = 0;
          treatment.remark = '';
        }
      } else {
        treatment.treatmentId = null;
      }
      vm.refreshTreatmentAndMcRemarks();
    }

    function refreshTreatmentAndMcRemarks() {
      refreshTicketTreatments();
      updateMcRemarks();
    }

    function hasUncoveredTreatments() {
      return _.some(
        vm.ticket.treatments,
        (treatment) => treatment.treatment && treatment.treatment.notCovered
      );
    }

    /* Treatment Remark functions */

    function getRemark(t) {
      return t.remarkTags
        ? _.join(
            _.map(t.remarkTags, (d) => d.text),
            ', '
          )
        : '';
    }

    function hasRemark(treatment) {
      if (vm.isSpecialistTicket && !vm.hasInsuranceEnhancementModule) {
        return treatment.remark && treatment.remark.length;
      }

      if (isSpecialistTicketV2()) {
        return treatment.coveredDescription && treatment.coveredDescription.length;
      }

      return treatment.remarkTags && treatment.remarkTags.length;
    }

    /* End of Treatment Remark functions */

    /* End of Treatment Management */

    /* Amounts Calculation */

    function getAllowanceDisplay() {
      return Math.min(vm.patient.allowance, vm.defaultMaxAllowance);
    }

    function refreshAllowance() {
      // Refresh patient's allowance. Only for NPR & Manual tickets.
      vm.loading += 1;
      patientTicketSvc
        .getAllowance({
          employeeId: vm.employeeId,
          panelLocationId: vm.clinic ? vm.clinic.clinicLocationId : null,
          clinicType: vm.ticket.clinicType,
          ticketType: vm.ticket.ticketType,
          ticketDate: vm.ticketDate,
          serviceType: vm.ticket.serviceType,
        })
        .success((data) => {
          vm.patient.allowance = data.employeeAllowance;
          vm.tenantBalance = data.tenantBalance;
          if (data.coPay) vm.patient.coPay = data.coPay;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTreatmentTotalAmount() {
      let amount = 0;

      if (isSpecialistTicketV2()) {
        const treatmentCategories = _.flatten(_.map(vm.sections, (s) => s.treatments));

        _.each(treatmentCategories, (t) => {
          if (t.coveredAmount) amount = App.evenRound(amount + t.coveredAmount, 2);

          if (t.uncoveredAmount) amount = App.evenRound(amount + t.uncoveredAmount, 2);
        });
      } else {
        _.each(vm.ticket.treatments, (t) => {
          if (t.treatment) {
            if (t.amount) amount = App.evenRound(amount + t.amount, 2);

            if (t.uncoveredAmount) amount = App.evenRound(amount + t.uncoveredAmount, 2);
          }
        });
      }
      return amount;
    }

    function getSubtotalAmount() {
      const treatmentAmount = getTreatmentTotalAmount();
      return vm.isTaxInclusive ? treatmentAmount / (1 + vm.taxPercentage / 100) : treatmentAmount;
    }

    function getTaxAmount() {
      let taxAmount = 0;
      if (vm.isTaxInclusive !== null && !vm.isTaxInclusive)
        taxAmount = (getSubtotalAmount() * vm.taxPercentage) / 100;
      else if (vm.isTaxInclusive) taxAmount = getTreatmentTotalAmount() - getSubtotalAmount();
      return taxAmount;
    }

    function calculateAmountCoverage() {
      let claimable = 0;
      let coPay = 0;
      let totalUncoveredAmount = 0;
      let totalCoveredAmount = 0;

      if (isSpecialistTicketV2()) {
        const treatmentCategories = _.flatten(_.map(vm.sections, (s) => s.treatments));

        _.each(treatmentCategories, (t) => {
          claimable += t.coveredAmount || 0;
          totalUncoveredAmount += t.uncoveredAmount || 0;
          totalCoveredAmount += t.coveredAmount || 0;
        });
      } else {
        _.each(vm.ticket.treatments, (t) => {
          if (t.treatment) {
            if (!t.treatment.notCovered) {
              claimable += t.amount || 0;
            }
          }
        });
      }

      if (vm.isTaxInclusive !== null && !vm.isTaxInclusive) claimable *= 1 + vm.taxPercentage / 100;

      if (vm.isCreate) {
        if (vm.patient && vm.ticket && claimable) {
          // Calculate copay based on covered amount of items
          if (vm.patient.coPay) {
            if (vm.hasSelfManageNpr && !isSpecialistTicketV2()) {
              coPay = vm.ticketCopayValue;
            } else {
              coPay = vm.patient.coPay.isPercentage
                ? App.roundAmount(claimable * (vm.patient.coPay.value / 100))
                : vm.patient.coPay.value;
            }

            if (coPay < 0) {
              coPay = 0;
            } else if (coPay > claimable) {
              coPay = claimable;
            }

            // Deduct copay from claimable

            claimable -= coPay;
          }

          // Assign claimable to allowance limit if overlimit

          if (claimable > vm.patient.allowance) claimable = vm.patient.allowance;

          // If copay, deduct from claimable
        }
      } else {
        claimable -= vm.ticket ? vm.ticket.manualPayment : 0;
      }

      const total = vm.getTotalAmount();
      const manualPayment = Math.max(total - claimable, 0);

      return {
        claimable: App.roundAmount(claimable),
        manualPayment: App.roundAmount(manualPayment),
        copay: App.roundAmount(coPay),
        totalCoveredAmount: App.roundAmount(totalCoveredAmount),
        totalUncoveredAmount: App.roundAmount(totalUncoveredAmount),
      };
    }

    function getCopayAmount() {
      return vm.calculateAmountCoverage().copay;
    }

    function getManualPaymentAmount() {
      return vm.calculateAmountCoverage().manualPayment;
    }

    function getCoveredAmount() {
      return vm.calculateAmountCoverage().claimable;
    }

    function getTotalUncoveredAmount() {
      return vm.calculateAmountCoverage().totalUncoveredAmount;
    }

    function getTotalCoveredAmount() {
      return vm.calculateAmountCoverage().totalCoveredAmount;
    }

    function getEligibleClaimAmount() {
      return getTotalCoveredAmount() - getCopayAmount();
    }

    function getEntitledAmount() {
      const allowance = getAllowanceDisplay();
      const eligibleClaimAmount = getEligibleClaimAmount();

      return eligibleClaimAmount > allowance ? allowance : eligibleClaimAmount;
    }

    function getExceededAmount() {
      return getEligibleClaimAmount() - getEntitledAmount();
    }

    function getTotalPayableAmount() {
      return getEligibleClaimAmount() - getExceededAmount();
    }

    function getTotalCollectCashAmount() {
      return getTotalUncoveredAmount() + getCopayAmount() + getExceededAmount();
    }

    function getCoveredTaxAmount() {
      if (vm.taxPercentage <= 0) return 0;

      if (vm.isTaxInclusive)
        return (getTotalCoveredAmount() / (100 + vm.taxPercentage)) * vm.taxPercentage;

      return (getTotalCoveredAmount() * vm.taxPercentage) / 100;
    }

    function getUncoveredTaxAmount() {
      if (vm.taxPercentage <= 0) return 0;

      if (vm.isTaxInclusive)
        return (getTotalUncoveredAmount() / (100 + vm.taxPercentage)) * vm.taxPercentage;

      return (getTotalUncoveredAmount() * vm.taxPercentage) / 100;
    }

    function getTotalAmount() {
      return vm.getSubtotalAmount() + vm.getTaxAmount();
    }

    function getTicketTotalAmount() {
      if (!vm.isSpecialistTicket) {
        return App.roundAmount(vm.getCoveredAmount() + vm.getManualPaymentAmount());
      }
      return vm.getTreatmentTotalAmount();
    }

    function isTotalBillAmountValid() {
      if (vm.hasRegionalReimbursementSupport && vm.getTaxAmount() !== 0)
        return (
          vm.claim &&
          ((!vm.isSpecialistTicket && vm.getTreatmentTotalAmount() <= vm.claimReceiptAmount) ||
            (vm.isSpecialistTicket && vm.getTreatmentTotalAmount() === vm.claimReceiptAmount))
        );
      return (
        vm.claim &&
        vm.getTicketTotalAmount() === vm.claimReceiptAmount &&
        ((!vm.isSpecialistTicket && vm.getTreatmentTotalAmount() <= vm.claimReceiptAmount) ||
          (vm.isSpecialistTicket && vm.getTreatmentTotalAmount() === vm.claimReceiptAmount))
      );
    }

    function resetCopay() {
      if (vm.patient.coPay) {
        vm.ticketCopayValue = vm.patient.coPay.isPercentage
          ? App.roundAmount(vm.claimReceiptAmount * (vm.patient.coPay.value / 100))
          : vm.patient.coPay.value;
      }
    }

    /* End of Amounts Calculation */

    /* Medical Certificate Management */

    function needMc() {
      return (
        vm.ticket &&
        (vm.ticket.serviceType === 1 ||
          vm.ticket.serviceType === 2 ||
          vm.isSpecialistTicket ||
          vm.isCtTicket ||
          vm.isInpatientAso)
      );
    }

    function updateMcRemarks() {
      if (
        vm.ticket.medicalCertificate &&
        !vm.isCtTicket &&
        !vm.claimId &&
        !isSpecialistTicketV2()
      ) {
        const remark = _.chain(vm.ticket.treatments)
          .filter((t) => t.treatment && t.treatment.displayName)
          .map((t) => t.treatment.displayName)
          .join(', ')
          .value();
        vm.ticket.medicalCertificate.remark = remark;
      }
    }

    function issueMc() {
      const firstDate = moment(vm.ticketDate).startOf('day');
      vm.ticket.medicalCertificate = {
        startDate: firstDate,
        endDate: firstDate,
      };
      vm.isMcStartDateAlsoCheckedInDate = 1;
      updateMcRemarks();
    }

    function removeMc() {
      vm.ticket.medicalCertificate = null;
    }

    function setMcStartDate(offset) {
      const checkInDate = moment(vm.ticket.checkedInDate).startOf('day');
      vm.ticket.medicalCertificate.startDate = checkInDate.add(offset, 'days');

      const mcEndDate = vm.ticket.medicalCertificate.endDate.startOf('day');
      if (mcEndDate.diff(vm.ticket.medicalCertificate.startDate, 'days') < 0) {
        vm.ticket.medicalCertificate.endDate = moment(vm.ticket.medicalCertificate.startDate);
      }
      vm.isMcStartDateAlsoCheckedInDate = offset === 0;
    }

    function getMcDurationDescription() {
      if (vm.ticket.medicalCertificate) {
        const startDate = moment(vm.ticket.medicalCertificate.startDate);
        const endDate = moment(vm.ticket.medicalCertificate.endDate);
        const duration = endDate.diff(startDate, 'days') + 1;
        return App.localize('XDays', duration);
      }
      return '';
    }

    function getMcStartDateChoice(offset) {
      return moment(vm.ticket.checkedInDate).add(offset, 'days').startOf('day');
    }

    /* End of Medical Certificate Management */

    function createOrEditTicket() {
      let canSubmit = true;
      const prompts = new PromptQueue();
      let invalidTreatment = {};

      vm.clinic = {};
      vm.ticket.doctorId = null;

      if (!hasTreatments()) {
        abp.message.error(App.localize('EmptyTreatmentValidationErrorMessage'));
        return;
      }

      if (isSpecialistTicketV2) {
        const treatments = _.flatten(_.map(vm.sections, (s) => s.treatments));
        invalidTreatment = _.find(treatments, (t) => vm.treatmentHasValue(t) && !t.code);
      } else {
        invalidTreatment = _.find(
          vm.ticket.treatments,
          (t) => vm.treatmentHasValue(t) && !t.treatmentId
        );
      }

      if (vm.isCreate && !vm.isCtTicket && !vm.isInpatientAso && invalidTreatment) {
        abp.message.error(App.localize('OneOrMoreInputValueIsInvalid'));
        return;
      }

      let cleanTreatments = true;

      _.each(vm.ticket.treatments, (t) => {
        const treatment = t;

        if (vm.treatmentHasValue(t)) {
          if (!vm.isSpecialistTicket && !vm.isHealthScreeningTicket)
            treatment.remark = getRemark(t);
          if (t.amount < 0) {
            abp.message.error(App.localize('InvalidTreatmentRateAmount'));
            cleanTreatments = false;
            return false;
          }
          if (vm.checkRemarkRequired(t) && !t.remark) {
            abp.message.error(App.localize('MissingTreatmentRemark'));
            cleanTreatments = false;
            return false;
          }
        }
        return true;
      });

      if (!cleanTreatments) return;

      if (vm.ticketDate > moment.utc()) {
        abp.message.error(App.localize('TicketDateIsInFuture'));
        return;
      }

      if (vm.getTicketTotalAmount() > vm.claimReceiptAmount) {
        abp.message.error(App.localize('TicketAmountMoreThanClaimReceiptAmount'));
        return;
      }

      if (vm.ticket.medicalCertificate) {
        const startDate = moment(vm.ticket.medicalCertificate.startDate);

        const mcDays = Math.ceil(vm.ticket.medicalCertificate.effectiveMcDays);
        if (Number.isNaN(Number(mcDays)) || mcDays < 0) {
          abp.message.error(App.localize('InvalidEffectiveMcDays'));
          return;
        }
        vm.ticket.medicalCertificate.endDate = moment(startDate).add(mcDays - 1, 'd');

        const mcStartDayOffset = startDate.diff(moment(vm.ticketDate).startOf('day'), 'days');
        if (mcStartDayOffset < 0) {
          abp.message.error(App.localize('McStartDateEarlierThanTicketDate'));
          return;
        }
        if (mcStartDayOffset > 1) {
          prompts.add(
            swal,
            {
              title: App.localize('AreYouSure'),
              text: App.localize('McStartDateTooFarFromTicketDateWarning'),
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#1ab394',
              confirmButtonText: App.localize('Yes'),
              cancelButtonText: App.localize('No'),
              closeOnConfirm: true,
              closeOnCancel: true,
            },
            (isConfirm) => {
              canSubmit = isConfirm;
            }
          );
        }
      }

      if (vm.claim && vm.getTicketTotalAmount() !== vm.claimReceiptAmount) {
        prompts.add(
          swal,
          {
            title: App.localize('AreYouSure'),
            text: App.localize('ClaimReceiptAmountMismatchWithTicketAmountWarning'),
            type: 'input',
            showCancelButton: true,
            confirmButtonColor: '#1ab394',
            confirmButtonText: App.localize('Submit'),
            cancelButtonText: App.localize('Cancel'),
            closeOnConfirm: true,
            closeOnCancel: true,
            inputPlaceholder: App.localize('PleaseExplain'),
          },
          (inputValue) => {
            if (inputValue === false) {
              refreshTicketTreatments();
              canSubmit = false;
            }

            if ($.trim(inputValue || '') === '') {
              swal.showInputError(App.localize('InvalidInput'));
              canSubmit = false;
            } else if (inputValue) {
              vm.processRemarks = inputValue;
            }
          }
        );
      }

      // Filter away attachments marked for deletion.

      const attachments = [];
      _.each(vm.attachments, (attachment) => {
        if (!attachment.isDelete) attachments.push(attachment.id);
      });

      // Construct input request.

      refreshTicketTreatments();

      const input = {
        doctorId: vm.ticket.doctorId,
        treatments: _.filter(
          vm.ticket.treatments,
          (treatment) =>
            // Discard treatment without id or without remark if it is CT ticket.

            treatment.treatmentId > 0
        ),
        manualPayment: vm.getManualPaymentAmount(),
        clinicLocationId: vm.clinic.clinicLocationId,
        medicalCertificate: vm.ticket.medicalCertificate,
        bankingDetails: vm.ticket.bankingDetails,
        manualClinicName: vm.ticket.manualClinicName,
        manualClinicLocation: vm.ticket.manualClinicLocation,
        manualDoctorName: vm.ticket.manualDoctorName,
        clinicType: vm.ticket.clinicType,
        ticketDate: vm.ticketDate,
        ticketType: vm.ticket.ticketType,
        attachments,
        newAttachments: vm.newAttachments,
        report: vm.report,
        serviceType: vm.ticket.serviceType,
        isTaxInclusive: vm.isTaxInclusive == null ? null : vm.isTaxInclusive,
      };
      if (!vm.needMc()) {
        input.medicalCertificate = null;
      }

      function createTicket() {
        if (vm.saving !== 0) return;

        vm.saving += 1;
        patientTicketSvc
          .createTicket(
            $.extend(input, {
              employeeId: vm.employeeId,
              claimId: vm.claimId,
              claimReceiptAmount: vm.claimReceiptAmount,
              claimRemarks: vm.processRemarks,
              ticketCopay: vm.ticketCopayValue,
              diagnoses: vm.diagnoses.selected,
              sections: vm.sections,
            })
          )
          .success((data) => {
            vm.isUpdated = true;
            abp.notify.info(App.localize('TicketXIssued', data.ticketNumber));

            showSuccessModal(
              data.ticketNumber,
              data.ticketType,
              vm.ticket.medicalCertificate !== null,
              'claimListDetail',
              {
                claimNumber: data.nextClaimNumber,
                employeeId: vm.employeeId,
              }
            );
          })
          .finally(() => {
            vm.saving -= 1;
          });
      }

      function editTicket(remarks) {
        if (vm.saving !== 0) return;

        vm.saving += 1;
        patientTicketSvc
          .editTicket(
            $.extend(input, {
              ticketNumber: vm.ticketNumber,
              modificationRemarks: remarks,
            })
          )
          .success(() => {
            vm.isUpdated = true;
            abp.notify.info(App.localize('SuccessfullySaved'));
            showSuccessModal(
              vm.ticketNumber,
              vm.ticket.ticketType,
              vm.ticket.medicalCertificate !== null,
              'patientTickets',
              null
            );
            swal.close();
          })
          .finally(() => {
            vm.saving -= 1;
          });
      }

      if (vm.isCreate) {
        vm.saving += 1;
        prompts.run(() => {
          vm.saving -= 1;
          if (canSubmit) createTicket();
        });
      } else {
        prompts.add(
          swal,
          {
            title: App.localize('EditPatientTicket'),
            text: App.localize('WhyEditPatientTicket'),
            type: 'input',
            showCancelButton: true,
            closeOnConfirm: false,
            confirmButtonColor: '#1ab394',
            inputPlaceholder: App.localize('PleaseExplain'),
          },
          (inputValue) => {
            vm.saving -= 1;
            vm.saving += 1;
            if (inputValue === false) {
              vm.saving -= 1;
              return false;
            }
            if (String.trim(inputValue || '') === '') {
              swal.showInputError(App.localize('PleaseExplain'));
              return false;
            }

            vm.saving -= 1;
            editTicket(String.trim(inputValue));
            return true;
          }
        );

        vm.saving += 1;
        prompts.run();
      }
    }

    function save() {
      createOrEditTicket();
    }

    function showSuccessModal(ticketNumber, ticketType, hasMc, nextState, nextStateData) {
      let callbackData = null;
      const obj = {
        ticketNumber,
        ticketType,
        hasMc,
        callback(data) {
          callbackData = data;
        },
      };

      $uibModal
        .open({
          templateUrl: require('../../../patientTickets/createEditSuccessModal.html'),
          controller: 'common.views.patientTickets.createEditSuccessModal as vm',
          backdrop: 'static',
          size: 'sm',
          resolve: {
            ticket() {
              return obj;
            },
          },
        })
        .result.then(() => {
          if (callbackData) {
            $state.go(callbackData.stateName, callbackData.stateData);
          } else {
            $state.go(nextState, nextStateData);
          }
        });
    }

    function updateTaxOption(taxOption) {
      if (taxOption === null) vm.isTaxInclusive = null;
      else vm.isTaxInclusive = taxOption === 1;
    }

    function isSpecialistTicketV2() {
      return vm.hasInsuranceEnhancementModule && vm.isSpecialistTicket;
    }
  }
})();
