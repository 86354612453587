(() => {
  angular
    .module('app')
    .controller('corporate.views.employees.createOrEditModal', EmployeeEditModalController);

  EmployeeEditModalController.$inject = [
    'Hms.Employees.RelationshipNames',
    '$scope',
    '$uibModalInstance',
    'countries',
    'identificationTypes',
    'abp.services.app.employee',
    'employeeId',
    'selectedTab',
    'isRehire',
    'Hms.Employees.EmployeeState',
    'Hms.Gender',
    'Hms.Employees.Employee',
    'moment',
    'abp.services.app.commonLookup',
  ];

  function EmployeeEditModalController(
    constsEmployeeRelationship,
    $scope,
    $uibModalInstance,
    countries,
    identificationTypes,
    employeeSvc,
    employeeId,
    selectedTab,
    isRehire,
    enumEmployeeState,
    enumGender,
    constsEmployee,
    moment,
    commonLookupSvc
  ) {
    const vm = this;

    const defaultDeactivationDate = moment().add(30, 'd');
    vm.isPreEmployee = false;
    vm.benefitGroupId = null;
    vm.loading = 0;
    vm.saving = 0;
    vm.tomorrow = moment().startOf('day').add(1, 'd');
    vm.employee = null;
    vm.countries = countries;
    vm.benefitGroups = [];
    vm.hospitalizationBenefitGroups = [];
    vm.maternityBenefitGroups = [];
    vm.subsidiaries = [];
    vm.subsidiaryDepartments = [];
    vm.scheduledSubsidiaryDepartments = [];
    vm.dependents = [];
    vm.maximumDependents = 0;
    vm.hasAutoDeactivation = false;
    vm.hasScheduledMovement = false;
    vm.hasScheduledBenefitGroupMovement = false;
    vm.hasAutoFullEntitlement = false;
    vm.benefitsProrateMode = abp.setting.get('Hms.EmployeeManagement.BenefitsProrateMode');
    vm.canScheduleEmployeeMovement = abp.features.isEnabled('CanScheduleEmployeeMovement');
    vm.canScheduleBenefitGroupMovement = abp.features.isEnabled('CanScheduleBenefitGroupMovement');
    vm.hasExplicitExclusion = abp.features.isEnabled('CanExplicitExclusion');
    vm.canAutoDeactivateDependent = abp.features.isEnabled('CanAutoDeactivateDependent');
    vm.organizationHasInpatientModule = false;
    vm.isRehire = false;
    vm.oldEmployeeId = 0;
    vm.countryIdentificationTypes = [];
    vm.applicationHasExcludedServiceTypeEnhancement = abp.setting.getBoolean(
      'Hms.Feature.ExcludedServiceTypeEnhancement'
    );
    vm.enums = {
      employeeState: enumEmployeeState,
      gender: enumGender,
    };

    class ExclusionItem {
      constructor(icon, name, value, excluded) {
        this.icon = icon;
        this.name = name;
        this.value = value;
        this.excluded = excluded;
      }
    }

    vm.constsEmployee = constsEmployee;
    vm.constsEmployeeRelationship = constsEmployeeRelationship;
    vm.toggleScheduledTermination = toggleScheduledTermination;
    vm.showTerminationAlert = showTerminationAlert;
    vm.getExcessUtilization = getExcessUtilization;
    vm.resetIdentificationTypes = resetIdentificationTypes;
    vm.toggleAutoDeactivation = toggleAutoDeactivation;
    vm.toggleScheduledMovement = toggleScheduledMovement;
    vm.toggleScheduledBenefitGroupMovement = toggleScheduledBenefitGroupMovement;
    vm.toggleAutoFullEntitlement = toggleAutoFullEntitlement;
    vm.getIntValue = getIntValue;
    vm.save = save;
    vm.cancel = cancel;
    vm.toggleActivateDependents = toggleActivateDependents;
    vm.getActiveDependentCountNarrative = getActiveDependentCountNarrative;
    vm.activateAllDependents = activateAllDependents;
    vm.dependentHasValue = dependentHasValue;
    vm.refreshDependents = refreshDependents;
    vm.refreshSubsidiaryDepartments = refreshSubsidiaryDepartments;
    vm.refreshScheduledSubsidiaryDepartments = refreshScheduledSubsidiaryDepartments;
    vm.removeDependent = removeDependent;
    vm.getExclusionItem = getExclusionItem;
    vm.getBenefitGroup = getBenefitGroup;
    vm.getEmployeeTotalError = getEmployeeTotalError;
    vm.getBenefitGroupTotalError = getBenefitGroupTotalError;
    vm.getLeaveTotalError = getLeaveTotalError;
    vm.getDependentTotalError = getDependentTotalError;

    init();

    function init() {
      vm.loading += 1;
      vm.selectedTab = selectedTab;
      employeeSvc
        .getEmployeeForEdit({
          id: employeeId,
        })
        .success((data) => {
          vm.organizationHasInpatientModule = data.organizationHasInpatientModule;
          vm.organizationHasHospitalizationService = data.organizationHasHospitalizationService;
          vm.organizationCountryCode = data.organizationCountryCode;

          vm.employee = data.employee;

          if (!employeeId) {
            // If create, set relevant fields value to be null for form validation.

            vm.employee.identificationType = null;
            vm.employee.subsidiaryId = null;
          }

          vm.dependents = data.dependents || [];
          vm.benefitGroups = data.benefitGroups;
          if (vm.organizationHasInpatientModule) {
            vm.hospitalizationBenefitGroups = data.hospitalizationBenefitGroups;
            vm.maternityBenefitGroups = data.maternityBenefitGroups;
          }
          vm.subsidiaries = data.subsidiaries;
          vm.subsidiaryDepartments = data.subsidiaryDepartments;
          vm.scheduledSubsidiaryDepartments = data.scheduledSubsidiaryDepartments;
          vm.maximumDependents = data.maximumDependents;
          vm.extendedFields = data.extendedFields;
          vm.serviceTypes = data.serviceTypes;
          vm.pictureUrl = data.pictureUrl;
          vm.modificationLog = data.modificationLog;
          vm.hasActiveCheckIn = data.hasActiveCheckIn;
          vm.hasInsurancePlan = data.hasInsurancePlan;
          vm.hasPendingInsuranceClaim = data.hasPendingInsuranceClaim;

          vm.employee.identificationTypes = [];
          vm.resetIdentificationTypes(vm.employee);

          if (isRehire) {
            vm.isRehire = true;
            renewEmployeeDetails(vm.employee);
          }

          if (vm.employee.scheduledDeactivationDate) {
            vm.employee.scheduledDeactivationDate = moment(vm.employee.scheduledDeactivationDate);
            vm.hasAutoDeactivation = true;
          }
          if (vm.employee.scheduledTerminationDate && !isRehire) {
            vm.employee.scheduledTerminationDate = moment(vm.employee.scheduledTerminationDate);
            vm.hasScheduledTermination = true;
            getExcessUtilization(vm.employee.scheduledTerminationDate);
          }
          if (vm.employee.scheduledMovementDate) {
            vm.employee.scheduledMovementDate = moment(vm.employee.scheduledMovementDate);
            vm.hasScheduledMovement = true;
          }
          if (vm.employee.scheduledBenefitGroupMovementDate) {
            vm.employee.scheduledBenefitGroupMovementDate = moment(
              vm.employee.scheduledBenefitGroupMovementDate
            );
            vm.hasScheduledBenefitGroupMovement = true;
          }
          if (vm.employee.scheduledDateToFullEntitlement) {
            vm.employee.scheduledDateToFullEntitlement = moment(
              vm.employee.scheduledDateToFullEntitlement
            );
            vm.hasAutoFullEntitlement = true;
          }
          if (vm.extendedFields && vm.employee.extendedFields) {
            _.each(vm.extendedFields, (f) => {
              const input = _.find(vm.employee.extendedFields, { value: f.value });
              if (input) {
                f.input = input.name;
              }
            });
          }
          if (vm.employee.birthDate) {
            vm.employee.birthDate = App.getLocalDate(vm.employee.birthDate);
          }

          if (vm.employee.joinDate) {
            vm.employee.joinDate = moment(vm.employee.joinDate);
          }

          if (vm.employee.id === 0) {
            vm.employee.gender = null;
          }

          // Process dependent's values
          _.each(vm.dependents, (dependent) => {
            dependent.identificationTypes = [];
            resetIdentificationTypes(dependent);
            dependent.birthDate = App.getLocalDate(dependent.birthDate);

            let showAutoDeactivation = '0';

            if (
              typeof dependent.dependentAutoDeactivationAge === 'undefined' ||
              dependent.dependentAutoDeactivationAge === null
            ) {
              showAutoDeactivation = '2';
            } else if (dependent.dependentAutoDeactivationAge > 0) {
              showAutoDeactivation = '1';
            }

            _.assign(dependent, {
              showAutoDeactivation,
            });
          });

          vm.refreshDependents();
          vm.toggleActivateDependents();
          vm.getBenefitGroup();
        })
        .finally(() => {
          vm.loading -= 1;

          if (vm.employee && !vm.employee.id && vm.subsidiaries && vm.subsidiaries.length === 1) {
            vm.employee.subsidiaryId = parseInt(vm.subsidiaries[0].value, 10);
            vm.refreshSubsidiaryDepartments();
            vm.refreshScheduledSubsidiaryDepartments();
          }
        });

      getCurrencyCode();
    }

    function toggleAutoDeactivation() {
      if (vm.employee) {
        if (vm.hasAutoDeactivation) {
          vm.employee.scheduledDeactivationDate = null;
        } else {
          vm.employee.scheduledDeactivationDate = vm.tomorrow;
        }
      }
      vm.hasAutoDeactivation = !vm.hasAutoDeactivation;
    }

    function toggleScheduledTermination() {
      if (vm.employee) vm.employee.scheduledTerminationDate = null;
      vm.hasScheduledTermination = !vm.hasScheduledTermination;
    }

    // To show alert only when scheduled termination date is backdated
    function showTerminationAlert() {
      const dtNow = moment.utc().startOf('day');
      if (vm.employee.scheduledTerminationDate) {
        const scheduledTerminationDate = vm.employee.scheduledTerminationDate.startOf('day');
        if (scheduledTerminationDate != null)
          return App.getDaysDifference(dtNow, scheduledTerminationDate) < 0;
      }

      return false;
    }

    function toggleScheduledMovement() {
      if (vm.employee) {
        if (vm.hasScheduledMovement) {
          vm.employee.scheduledMovementDate = null;
        } else {
          vm.employee.scheduledMovementDate = vm.tomorrow;
        }
      }
      vm.hasScheduledMovement = !vm.hasScheduledMovement;
    }

    function toggleScheduledBenefitGroupMovement() {
      if (vm.employee) {
        if (vm.hasScheduledBenefitGroupMovement) {
          vm.employee.scheduledBenefitGroupMovementDate = null;
          vm.employee.scheduledBenefitGroupId = null;
        } else {
          vm.employee.scheduledBenefitGroupMovementDate = vm.tomorrow;
        }
      }
      vm.hasScheduledBenefitGroupMovement = !vm.hasScheduledBenefitGroupMovement;
    }

    function toggleAutoFullEntitlement() {
      if (vm.employee) {
        if (vm.hasAutoFullEntitlement) {
          vm.employee.scheduledDateToFullEntitlement = null;
        } else {
          vm.employee.scheduledDateToFullEntitlement = vm.tomorrow;
        }
      }
      vm.hasAutoFullEntitlement = !vm.hasAutoFullEntitlement;
    }

    function getIntValue(n) {
      return parseInt(n.value, 10);
    }

    function save() {
      vm.saving += 1;

      const { employeeForm } = $scope.vm;
      const { benefitGroupForm } = $scope.vm;
      const { leaveForm } = $scope.vm;
      const { dependentForm } = $scope.vm;

      employeeForm.$setDirty();
      benefitGroupForm.$setDirty();
      leaveForm.$setDirty();

      App.touchFormErrors(employeeForm);
      App.touchFormErrors(benefitGroupForm);
      App.touchFormErrors(leaveForm);

      const hasDependentForm = typeof dependentForm !== 'undefined' && dependentForm !== null;
      if (hasDependentForm) {
        dependentForm.$setDirty();
        App.touchFormErrors(dependentForm);
      }

      if (
        employeeForm.$invalid ||
        benefitGroupForm.$invalid ||
        leaveForm.$invalid ||
        (hasDependentForm && dependentForm.$invalid)
      ) {
        getEmployeeTotalError(true);
        getBenefitGroupTotalError(true);
        getLeaveTotalError(true);

        if (hasDependentForm) {
          getDependentTotalError(true);
        }

        vm.saving -= 1;
        return;
      }

      const empExcludedServiceTypes = _.filter(
        vm.employee.explicitExclusions,
        (e) => e.value !== 251 && e.excluded
      );

      if (vm.applicationHasExcludedServiceTypeEnhancement) {
        vm.employee.excludedServiceTypes = _.map(empExcludedServiceTypes, 'value');
      } else {
        vm.employee.excludedTreatmentTypes = _.map(empExcludedServiceTypes, 'value');
      }

      const empHasExcludedSupportPlan = _.some(
        vm.employee.explicitExclusions,
        (e) => e.value === 251 && e.excluded
      );

      vm.employee.excludedSupportPlan = empHasExcludedSupportPlan;

      const dependents = _.filter(vm.dependents, (d) => vm.dependentHasValue(d));
      _.each(dependents, (d) => {
        const depExcludedServiceTypes = _.filter(
          d.explicitExclusions,
          (e) => e.value !== 251 && e.excluded
        );

        if (vm.applicationHasExcludedServiceTypeEnhancement) {
          d.excludedServiceTypes = _.map(depExcludedServiceTypes, 'value');
        } else {
          d.excludedTreatmentTypes = _.map(depExcludedServiceTypes, 'value');
        }

        const depHasExcludedSupportPlan = _.some(
          d.explicitExclusions,
          (e) => e.value === 251 && e.excluded
        );

        d.excludedSupportPlan = depHasExcludedSupportPlan;

        if (d.birthDate) {
          d.birthDate = App.getUtcDate(d.birthDate.format());
        }
      });

      vm.employee.extendedFields = _.map(vm.extendedFields, (f) => ({
        name: f.input || '',
        value: f.value,
      }));

      // Reformat employee birth date
      if (vm.employee.birthDate) {
        vm.employee.birthDate = App.getUtcDate(vm.employee.birthDate.format());
      }

      _.each(dependents, (dependent) => {
        if (dependent.showAutoDeactivation === '0') dependent.dependentAutoDeactivationAge = 0;
        if (dependent.showAutoDeactivation === '2') dependent.dependentAutoDeactivationAge = null;
      });

      if (vm.isRehire) {
        if (vm.employee.isSuspended && dependents.length) {
          _.each(dependents, (dependent) => {
            dependent.isSuspended = true;
          });
        }

        employeeSvc
          .rehireEmployee({
            employee: vm.employee,
            dependents,
            isRehire: vm.isRehire,
          })
          .success(() => {
            abp.notify.info(App.localize('SuccessfullySaved'));
            $uibModalInstance.close();
          })
          .finally(() => {
            vm.saving -= 1;
          });
      } else {
        employeeSvc
          .createOrUpdateEmployee({
            employee: vm.employee,
            dependents,
          })
          .success(() => {
            abp.notify.info(App.localize('SuccessfullySaved'));
            $uibModalInstance.close();
          })
          .finally(() => {
            vm.saving -= 1;
          });
      }
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function toggleActivateDependents() {
      vm.showActivateDependents =
        vm.employee.isActive && _.some(vm.dependents, (d) => vm.dependentHasValue(d));
    }

    function getActiveDependentCountNarrative() {
      let activeDependentCount = 0;
      let totalDependentCount = 0;
      _.each(vm.dependents, (d) => {
        if (vm.dependentHasValue(d)) {
          if (d.isActive) activeDependentCount += 1;
          totalDependentCount += 1;
        }
      });
      return App.localize(
        'ActivateDependentsNarrative1',
        activeDependentCount,
        totalDependentCount
      );
    }

    function activateAllDependents() {
      if (vm.employee.isActive) {
        _.forEach(vm.dependents, (d) => {
          if (vm.dependentHasValue(d)) {
            d.isActive = true;
          }
        });
      }
    }

    function dependentHasValue(d) {
      return d.name || d.nationalId || d.relationship || d.emailAddress || d.mobileNumber;
    }

    function refreshDependents() {
      let hasBlankItem = false;
      const explicitExclusion = _.map(vm.serviceTypes, (d) =>
        _.assignIn({}, d, {
          excluded: false,
        })
      );

      if (vm.hasSupportPlanEnabled && vm.hasExplicitExclusion) {
        explicitExclusion.push({
          icon: 'fa fa-suitcase',
          name: App.localize('SupportPlan'),
          value: 251,
          excluded: false,
        });
      }

      hasBlankItem = _.some(vm.dependents, (dependent) => !vm.dependentHasValue(dependent));

      if (!hasBlankItem && vm.dependents.length < vm.maximumDependents) {
        const defaultDependent = {
          id: 0,
          isActive: true,
          nationalityCode: vm.employee ? vm.employee.nationalityCode : 'MY',
          explicitExclusions: explicitExclusion,
          identificationTypes: [],
        };

        resetIdentificationTypes(defaultDependent);
        vm.dependents.push(defaultDependent);
      }
    }

    function refreshSubsidiaryDepartments() {
      if (vm.employee.subsidiaryId) {
        vm.loading += 1;
        employeeSvc
          .getSubsidiaryDepartments({
            subsidiaryId: vm.employee.subsidiaryId,
          })
          .success((data) => {
            vm.subsidiaryDepartments = data.items || [];
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function refreshScheduledSubsidiaryDepartments() {
      if (vm.employee.scheduledSubsidiaryId) {
        vm.loading += 1;
        employeeSvc
          .getSubsidiaryDepartments({
            subsidiaryId: vm.employee.scheduledSubsidiaryId,
          })
          .success((data) => {
            vm.scheduledSubsidiaryDepartments = data.items || [];
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function removeDependent(index) {
      if (index < vm.dependents.length) {
        vm.dependents.splice(index, 1);
        vm.refreshDependents();
      }
    }

    function getExclusionItem() {
      vm.employee.explicitExclusions = [];
      _.each(vm.serviceTypes, (d) => {
        let excluded = false;
        if (vm.applicationHasExcludedServiceTypeEnhancement) {
          excluded =
            vm.employee.excludedServiceTypes &&
            vm.employee.excludedServiceTypes.indexOf(d.value) !== -1;
        } else {
          excluded =
            vm.employee.excludedTreatmentTypes &&
            vm.employee.excludedTreatmentTypes.indexOf(d.value) !== -1;
        }
        vm.employee.explicitExclusions.push(new ExclusionItem(d.icon, d.name, d.value, excluded));
      });

      if (vm.hasSupportPlanEnabled && vm.hasExplicitExclusion) {
        const excluded = vm.employee.excludedSupportPlan;
        vm.employee.explicitExclusions.push(
          new ExclusionItem('fas fa-bed', App.localize('Hospitalization'), 251, excluded)
        );
      }

      _.forEach(vm.dependents, (dep) => {
        dep.explicitExclusions = [];
        _.each(vm.serviceTypes, (d) => {
          let excluded = false;
          if (vm.applicationHasExcludedServiceTypeEnhancement) {
            excluded = dep.excludedServiceTypes && dep.excludedServiceTypes.indexOf(d.value) !== -1;
          } else {
            excluded =
              dep.excludedTreatmentTypes && dep.excludedTreatmentTypes.indexOf(d.value) !== -1;
          }
          dep.explicitExclusions.push(new ExclusionItem(d.icon, d.name, d.value, excluded));
        });
        if (vm.hasSupportPlanEnabled && vm.hasExplicitExclusion) {
          const excluded = dep.excludedSupportPlan;
          dep.explicitExclusions.push(
            new ExclusionItem('fas fa-bed', App.localize('Hospitalization'), 251, excluded)
          );
        }
      });
    }

    function getBenefitGroup() {
      const benefitGroup = _.find(
        vm.benefitGroups,
        (o) => parseInt(o.value, 10) === vm.employee.benefitGroupId
      );

      if (benefitGroup) {
        vm.hasSupportPlanEnabled = benefitGroup.hasSupportPlanEnabled;
        vm.scheduledBenefitGroups = _.filter(
          vm.benefitGroups,
          (d) => d.value !== benefitGroup.value && !d.isPreEmployeeBenefitGroup
        );
      }

      vm.isPreEmployee = benefitGroup && benefitGroup.isPreEmployeeBenefitGroup;

      // No default deactivation date for pre-employee.
      // And unset if it's the default date and not pre-employee.
      if (vm.isPreEmployee) {
        vm.hasAutoDeactivation = !!vm.employee.scheduledDeactivationDate;
      } else if (vm.employee.scheduledDeactivationDate === defaultDeactivationDate) {
        vm.hasAutoDeactivation = false;
        vm.employee.scheduledDeactivationDate = null;
      }
      vm.getExclusionItem();
    }

    function renewEmployeeDetails(employee) {
      employee.isTerminated = false;
      employee.isSuspended = false;
      employee.isActive = true;
      employee.state = 1;
      employee.subsidiaryId = null;
      employee.subsidiaryDepartmentId = null;
      employee.employeeNumber = null;
      employee.emailAddress = null;
      employee.mobileNumber = null;
      employee.joinDate = null;
      employee.benefitGroupId = null;
      employee.hospitalizationBenefitGroupId = null;
      employee.maternityBenefitGroupId = null;
      employee.mcLimit = null;
      employee.hospitalizationMcLimit = null;
      employee.scheduledDeactivationDate = null;
      employee.scheduledTerminationDate = null;
      employee.scheduledMovementDate = null;
      employee.scheduledBenefitGroupMovementDate = null;
      employee.scheduledDateToFullEntitlement = null;
    }

    function getExcessUtilization(scheduledTerminationDate) {
      // This is to assign the date back to the variable
      // as it will be nullify after this function call.
      vm.employee.scheduledTerminationDate = scheduledTerminationDate;

      // Cut off date is inclusive.
      const date = moment().utc();
      angular.copy(scheduledTerminationDate, date);

      if (vm.employee.id) {
        vm.loading += 1;
        employeeSvc
          .getExcessUtilization({
            employeeId: vm.employee.id,
            scheduledTerminationDate: date,
          })
          .success((data) => {
            vm.excessUtilization = data || [];
            data.outpatient = [];
            data.inpatient = [];

            const { hasCustomBenefits, customBenefitNames } = vm.excessUtilization;

            // Handle outpatient benefit
            _.each(vm.excessUtilization.outpatientUtilization, (pool) => {
              let title = _.join(
                _.map(pool.benefitTypes, (bt) =>
                  getServiceTypeLocalization(bt, hasCustomBenefits, customBenefitNames)
                ),
                '/'
              );

              if (pool.modifierCycle === 0) {
                title += ` (every ${pool.interval} years)`;
              } else {
                title += ' (annual budget)';
              }

              data.outpatient.push({
                benefitTypes: title,
                proratedAmount: pool.limit,
                actualUtilization: pool.utilization,
                excessUtilization: pool.excessUtilization,
              });
            });

            // Handle inpatient benefit
            _.each(vm.excessUtilization.inpatientUtilization, (pool) => {
              let title = _.join(
                _.map(pool.benefitTypes, (bt) => getServiceTypeLocalization(bt, false, [])),
                '/'
              );

              if (pool.modifierCycle === 0) {
                title += ` (every ${pool.interval} years)`;
              } else {
                title += ' (annual budget)';
              }

              data.inpatient.push({
                benefitTypes: title,
                proratedAmount: pool.limit,
                actualUtilization: pool.utilization,
                excessUtilization: pool.excessUtilization,
              });
            });

            data.outpatient.sort((a, b) => (a.benefitTypes > b.benefitTypes ? 1 : -1));
            data.inpatient.sort((a, b) => (a.benefitTypes > b.benefitTypes ? 1 : -1));

            if (!vm.isRehire) {
              vm.showOutpatientUtilization = data.outpatient.length > 0;
              vm.showInpatientUtilization = data.inpatient.length > 0;
            }
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function getServiceTypeLocalization(bt, hasCustomServiceTypes, customBenefitNames) {
      if (bt === 1) return App.localize('GP');
      if (bt === 2) return App.localize('Dental');
      if (bt === 3) return App.localize('Optical');
      if (bt === 4) return App.localize('PreEmployment');
      if (bt === 5) return App.localize('Specialist');
      if (bt === 6) return App.localize('Pharmacy');
      if (bt === 7) return App.localize('Hospitalization');
      if (bt === 8) return App.localize('Maternity');
      if (bt === 9) return App.localize('HealthScreening');
      if (hasCustomServiceTypes) {
        let strCt = `CT${bt}`;

        const idx = _.findIndex(customBenefitNames, (ctn) => ctn.value === bt);
        if (idx !== -1) strCt = customBenefitNames[idx].name;
        return strCt;
      }

      return '';
    }

    function resetIdentificationTypes(employee) {
      if (employee.nationalityCode === '') return;
      /*
       * Show the identification type when any of the following conditions is fulfilled:
       *
       * - If the identification type is not tied to any specific country
       *   and the current employee is a foreigner
       *   (e.g. a Japanese in a Malaysian-owned company
       *   or a Malaysian in a Singaporean-owned company)
       *
       * - If the identification type is tied to a specific country,
       *      - If the identification type is for citizen only
       *                and the current employee is a citizen of the specific country
       *        (e.g. a Malaysian in a Malaysian-owned company
       *          or a Singaporean in a Singaporean-owned company)
       *
       *      - If the identification type is not for citizen and the current employee
       *        is a foreigner
       *        (e.g. a Korean who is issued a long-term pass holders and not a permanent resident
       *        by the Singapore government and in a Singaporean-owned company)
       *
       * - If the identification type is tied to a specific country and is for permanent resident
       *    and the current employee is a foreigner
       *   (e.g. an Malaysian who is a permanent resident of Singapore and in a
       *    Singaporean-owned company)
       */
      employee.identificationTypes = _.filter(
        identificationTypes,
        (k) =>
          (k.countryCode === '' && employee.nationalityCode !== vm.organizationCountryCode) ||
          (k.countryCode === vm.organizationCountryCode &&
            ((k.isCitizen && k.countryCode === employee.nationalityCode) ||
              (!k.isCitizen && k.countryCode !== employee.nationalityCode))) ||
          (k.isPermanentResident &&
            k.countryCode === vm.organizationCountryCode &&
            k.countryCode !== employee.nationalityCode)
      );
    }

    function getCurrencyCode() {
      vm.loading += 1;
      commonLookupSvc
        .getTenantCurrencyCode()
        .success((data) => {
          vm.corpAccOwnerCurrencyCode = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getEmployeeTotalError(isSave) {
      const { employeeForm } = $scope.vm;
      if (employeeForm.$dirty || isSave) {
        if (employeeForm.$invalid) return getFormTotalErrors(employeeForm);
      }
      return 0;
    }

    function getBenefitGroupTotalError(isSave) {
      const { benefitGroupForm } = $scope.vm;
      if (benefitGroupForm.$dirty || isSave) {
        if (benefitGroupForm.$invalid) return getFormTotalErrors(benefitGroupForm);
      }
      return 0;
    }

    function getLeaveTotalError(isSave) {
      const { leaveForm } = $scope.vm;
      if (leaveForm.$dirty || isSave) {
        if (leaveForm.$invalid) return getFormTotalErrors(leaveForm);
      }
      return 0;
    }

    function getDependentTotalError(isSave) {
      const { dependentForm } = $scope.vm;
      if (dependentForm.$dirty || isSave) {
        if (dependentForm.$invalid) return getFormTotalErrors(dependentForm);
      }
      return 0;
    }

    function getFormTotalErrors(form) {
      let noOfErrors = 0;
      const fieldNames = [];
      _.forIn(form.$error, (e) => {
        _.forEach(e, (t) => {
          if (!_.includes(fieldNames, t.$name)) {
            fieldNames.push(t.$name);
            noOfErrors += 1;
          }
        });
      });
      return noOfErrors;
    }
  }
})();
