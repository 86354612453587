(() => {
  angular.module('app').component('hostCorporatesCreateEditFiles', {
    templateUrl: require('./widget.html'),
    controller: CorporateEditFilesController,
    controllerAs: 'vm',
    require: {
      container: '^hostCorporatesCreateEditContainer',
    },
    bindings: {},
  });

  CorporateEditFilesController.$inject = [
    '$stateParams',
    '$uibModal',
    'abp.services.app.corporate',
  ];

  function CorporateEditFilesController($stateParams, $modal, corporateSvc) {
    const vm = this;
    vm.loading = 0;
    vm.isEditing = false;
    vm.isCreate = false;
    const corporateId = $stateParams.corporateId > 0 ? $stateParams.corporateId : 0;

    vm.permissions = {
      edit: abp.auth.isGranted('Host.Corporates.Edit'),
      upload: abp.auth.isGranted('Document.Host.Upload'),
      create: abp.auth.isGranted('Host.Corporates.Create'),
      delete: abp.auth.isGranted('Document.Host.Delete'),
    };

    vm.isDirty = isDirty;
    vm.setPristine = setPristine;
    vm.uploadDocument = uploadDocument;
    vm.deleteFile = deleteFile;
    vm.validateForm = validateForm;
    vm.getPayload = getPayload;
    vm.save = save;
    vm.$onChanges = changes;
    vm.$onInit = init;

    function init() {
      vm.container.addWidget(this);
      lookupFiles();
    }

    function isDirty() {
      return false;
    }

    function setPristine() {}

    function lookupFiles() {
      if (!vm.isCreate && !vm.isAgreement) {
        vm.loading += 1;
        corporateSvc
          .getCorporateFiles({
            id: corporateId,
          })
          .success((data) => {
            vm.files = data.items;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function uploadDocument() {
      $modal
        .open({
          templateUrl: require('../../documentModal.html'),
          controller: 'host.views.corporates.documentModal as vm',
          backdrop: 'static',
          resolve: {
            corporateId: () => $stateParams.corporateId,
          },
        })
        .result.then(() => {
          lookupFiles();
        });
    }

    function deleteFile(file) {
      abp.message.confirm(
        App.localize('DocumentDeleteWarningMessage', file.fileName),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            corporateSvc
              .deleteDocument({
                id: file.id,
              })
              .success(() => {
                lookupFiles();
                abp.notify.success(App.localize('SuccessfullyDeleted'));
              });
          }
        }
      );
    }

    // Force validation on this form.
    // Return true if valid, otherwise, false.

    function validateForm() {
      return true;
    }

    // Generate the payload from this form to send to CorporateAppService API.

    function getPayload() {
      return {};
    }

    // Ask container to save this widget.

    function save() {
      vm.container.saveWidget(vm);
    }

    function changes() {
      vm.isCreate = !($stateParams.corporateId > 0);
    }
  }
})();
