/* global __API_ENDPOINT__, __REVENUE_ENDPOINT__, __FINANCE_ENDPOINT__, __SMS_ENDPOINT__, GoogleKey, __GA_BROWSER_CREDENTIAL_KEY__, PRODUCTION */

(() => {
  angular
    .module('app', [
      'ngAnimate',
      'ngSanitize',
      'ngMessages',
      'ngMask',

      'ui.router',
      'ui.bootstrap',
      'ui.select',
      'ui.sortable',
      'ui.validate',
      'ui.grid',
      'ui.grid.resizeColumns',
      'ui.grid.pagination',
      'ui.grid.selection',
      'ui.grid.autoResize',
      'ngTagsInput',
      'ngTouch',

      'nemLogging',
      'uiGmapgoogle-maps',

      'summernote',
      'angularMoment',
      'angularFileUpload',
      'frapontillo.bootstrap-switch',
      'daterangepicker',
      'datePicker',
      'colorpicker.module',
      'abp',

      'wiz.markdown',

      'finance',
      'revenue',
      'sms',
      'hms-ng-auth',

      'ngHandsontable',
      'angular-flot',
      'angular-peity',
      'signature',
      'ui.codemirror',
    ])
    .config(configureApp)
    .run(runApp);

  configureApp.$inject = [
    '$stateProvider',
    '$urlRouterProvider',
    'uiGmapGoogleMapApiProvider',
    '$urlMatcherFactoryProvider',
    '$sceDelegateProvider',
    '$httpProvider',
    'tagsInputConfigProvider',
    'appConstants',
    'moment',
    '$compileProvider',
  ];
  runApp.$inject = ['$rootScope', '$state', 'amMoment'];

  function configureApp(
    $stateProvider,
    $urlRouterProvider,
    uiGmapGoogleMapApiProvider,
    $urlMatcherFactoryProvider,
    $sceDelegateProvider,
    $httpProvider,
    tagsInputConfigProvider,
    appConstants,
    moment,
    $compileProvider
  ) {
    if (PRODUCTION) {
      // Disable comment and css class directives
      // Improve performance by not having to parse comment and CSS classes for directives.
      // https://docs.angularjs.org/guide/production#disable-comment-and-css-class-directives
      $compileProvider.commentDirectivesEnabled(false);
      $compileProvider.cssClassDirectivesEnabled(false);

      // Disable debug data to improve performance.
      // Anything rely on debug data would fail.
      // https://docs.angularjs.org/guide/production#disabling-debug-data
      // Use `angular.reloadWithDebugInfo();` in Dev console to reenable if needed.
      $compileProvider.debugInfoEnabled(false);
    }

    if (__GA_BROWSER_CREDENTIAL_KEY__) {
      uiGmapGoogleMapApiProvider.configure({
        libraries: 'places',
        key: GoogleKey.browserKey,
      });
    }

    moment.fn.toJSON = momentToJson;
    function momentToJson() {
      return this.format();
    }

    tagsInputConfigProvider
      .setActiveInterpolation('tagsInput', {
        placeholder: true,
      })
      .setDefaults('tagsInput', {
        placeholder: '',
      });

    // Add bearer token to authorization header.
    // for async http call. (i.e. the svc that calling success)
    $httpProvider.interceptors.push([
      '$q',
      'getUser',
      ($q, getUser) => ({
        async request(config) {
          const user = await getUser();
          config.headers.Authorization = `Bearer ${user.access_token}`;
          config.headers['Abp.Localization.CultureName'] = abp.utils.getCookieValue(
            'Abp.Localization.CultureName'
          );
          return config;
        },
        responseError(responseError) {
          const defer = $q.defer();

          // Handle AbpException from revenue service to appear similar to portal error message.
          if (
            (_.includes(responseError.config.url, __REVENUE_ENDPOINT__) ||
              _.includes(responseError.config.url, __FINANCE_ENDPOINT__)) &&
            responseError.headers('_AbpErrorFormat')
          ) {
            const errorMessage = {
              message: `${responseError.status} ${responseError.statusText}`,
              details: `${responseError.data.error.message}`,
            };

            abp.log.error(responseError.data);
            abp.message.error(errorMessage.details);
          }
          // Handle SMS service error messages
          else if (_.includes(responseError.config.url, __SMS_ENDPOINT__)) {
            responseError.config.abpHandleError = false;

            abp.log.error(responseError.data);
            abp.message.error(responseError.data);
            defer.reject(responseError);
          } else {
            // Rethrow the error by returning a rejection constructed via reject.
            // Refer to https://docs.angularjs.org/api/ng/service/$q#reject.
            defer.reject(responseError);
          }

          return defer.promise;
        },
      }),
    ]);

    // Add bearer token to authorization header.
    // for sync http call. (i.e. the svc that calling then)
    $.ajaxSetup({
      headers: {
        Authorization: `Bearer ${App.getAccessToken()}`,
        'Abp.Localization.CultureName': abp.utils.getCookieValue('Abp.Localization.CultureName'),
      },
    });

    $sceDelegateProvider.resourceUrlWhitelist([
      'self',
      'http://healthmetrics.com/**',
      'https://healthmetrics.com/**',
    ]);

    $urlRouterProvider.otherwise('/welcome');

    // Override ui-view scroll with smooth scrolling.
    // TODO: Kai: Temporary disabled this. It cause jerky movement.

    // $provide.decorator('$uiViewScroll', ['$delegate',
    //   function ($delegate) {
    //     return function (ui) {
    //       $('html, body').animate({ scrollTop: ui.offset().top }, 800);
    //     };
    //   }
    // ]);

    // Configure ui-router url matching options.

    // $urlMatcherFactoryProvider.caseInsensitive(true);
    $urlMatcherFactoryProvider.strictMode(false);
    $urlMatcherFactoryProvider.defaultSquashPolicy(true);

    $stateProvider
      .state('welcome', {
        url: '/welcome',
        templateUrl: require('./common/views/welcome/index.html'),
      })
      .state('host', {
        abstract: true,
        template: '<div ui-view class="ui-view-wrapper"></div>',
      })
      .state('tenant', {
        abstract: true,
        template: '<div ui-view class="ui-view-wrapper"></div>',
      })
      .state('corporate', {
        abstract: true,
        template: '<div ui-view class="ui-view-wrapper"></div>',
      })
      .state('clinic', {
        abstract: true,
        template: '<div ui-view class="ui-view-wrapper"></div>',
      });

    $stateProvider.state('notifications', {
      url: '/notifications',
      templateUrl: require('./common/views/notifications/index.html'),
    });

    if (abp.auth.isGranted('Administration.AccountOwners')) {
      const accountOwnerState = $stateProvider.state('accountOwners', {
        url: '/accountOwners',
        templateUrl: require('./host/views/accountOwners/accountOwners.page.html'),
      });
      accountOwnerState.state('accountOwnersCreateEdit', {
        url: '/accountOwners/createEdit/:id',
        templateUrl: require('./host/views/accountOwners/createEdit/createEdit.html'),
        params: {
          id: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('Administration.Roles')) {
      $stateProvider.state('roles', {
        url: '/roles',
        templateUrl: require('./common/views/roles/index.html'),
      });
    }

    if (abp.auth.isGranted('Administration.Users')) {
      $stateProvider.state('users', {
        url: '/users?filterText&sorting&{skipCount:int}&{maxResultCount:int}',
        templateUrl: require('./common/views/users/index.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });
    }

    if (abp.auth.isGranted('Administration.AuditLogs')) {
      $stateProvider.state('auditLogs', {
        url:
          '/auditLogs?userName&serviceName&minExecutionDuration&maxExecutionDuration&methodName' +
          '&{skipCount:int}&{maxResultCount:int}&hasException&browserInfo&startDate&endDate',
        templateUrl: require('./common/views/auditLogs/index.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });
    }

    if (abp.auth.isGranted('Administration.ImpersonationAuditLogs')) {
      $stateProvider.state('impersonationAuditLogs', {
        url:
          '/impersonationAuditLogs?userName' +
          '&{skipCount:int}&{maxResultCount:int}&startDate&endDate&targetTenancyName&targetUserName' +
          '&impersonatorTenancyName&impersonatorUserName',
        templateUrl: require('./host/views/impersonationAuditLogs/index.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });
    }

    if (abp.auth.isGranted('Administration.Host.Settings')) {
      $stateProvider.state('host.settings', {
        url: '/host/settings',
        templateUrl: require('./host/views/settings/index.html'),
      });
    }

    if (abp.auth.isGranted('Host.Corporates')) {
      const corporateState = $stateProvider.state('host.corporates', {
        url: '/host/corporates?filterText&sorting&{skipCount:int}&{maxResultCount:int}',
        templateUrl: require('./host/views/corporates/index.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });

      corporateState.state('host.corporateViewHistory', {
        url: '/host/corporates/viewHistory/:id',
        templateUrl: require('./host/views/corporates/viewHistory.html'),
        params: {
          id: '',
        },
      });

      $stateProvider.state('host.corporateFinanceManagement', {
        url: '/host/corporates/financeManagement/{organizationId:int}',
        templateUrl: require('./host/views/corporates/financeManagement/financeManagement.page.html'),
        params: {
          organizationId: {
            value: null,
          },
        },
      });

      if (abp.auth.isGranted('Host.Corporates.Subsidiaries')) {
        corporateState.state('host.corporates.subsidiaries', {
          url: '/:id/subsidiaries',
          templateUrl: require('./host/views/corporates/subsidiaries.html'),
          dynamic: true,
          params: {
            id: {
              value: null,
            },
            tenancyName: {
              value: null,
            },
            virtualAccountGenerationMode: {
              value: null,
            },
          },
        });
        $stateProvider.state('host.corporateSubsidiaries', {
          url: '/host/corporates/subsidiaries/:id',
          templateUrl: require('./host/views/corporates/subsidiaries.html'),
        });
      }
      if (abp.auth.isGranted('Host.Corporates.ManageSupportPlans')) {
        if (abp.setting.getBoolean('Hms.Feature.InpatientModule')) {
          $stateProvider.state('host.corporateInpatientSupportPlan', {
            url: '/host/corporates/supportPlans/:{id:int}',
            templateUrl: require('./host/views/corporates/supportPlans/supportPlan.page.html'),
          });
          $stateProvider.state('host.createEditInpatientSupportPlan', {
            url: '/host/corporates/supportPlans/createEdit/:supportPlanId?{organizationId:int}&{serviceType:int}',
            templateUrl: require('./host/views/corporates/supportPlans/createEdit/createEditSupportPlan.page.html'),
            dynamic: true,
            params: {
              supportPlanId: '',
              organizationId: {
                value: null,
              },
              serviceType: {
                value: null,
              },
            },
          });
        }

        $stateProvider.state('host.corporateSupportPlans', {
          url: '/host/corporates/supportPlan/:{id:int}',
          templateUrl: require('./host/views/corporates/supportPlans.html'),
        });
        $stateProvider.state('host.createEditSupportPlan', {
          url: '/host/corporates/supportPlan/:supportPlanId?{organizationId:int}',
          templateUrl: require('./host/views/corporates/supportPlan/index.html'),
          params: {
            organizationId: {
              value: null,
            },
            supportPlanId: {
              value: null,
            },
          },
        });
      }

      corporateState.state('host.createEditCorporate', {
        url: '/host/corporates/createEdit/:corporateId',
        templateUrl: require('./host/views/corporates/createEdit/index.html'),
        params: {
          corporateId: '',
        },
      });

      const corporateMasterPoliciesState = $stateProvider.state('host.corporateMasterPolicies', {
        url: '/host/corporates/{organizationId:int}/masterPolicies',
        templateUrl: require('./host/views/corporates/masterPolicies/masterPolicies.page.html'),
        dynamic: true,
        params: {
          organizationId: {
            value: null,
          },
        },
      });

      $stateProvider.state('host.createEditMasterPolicy', {
        url: '/host/corporates/{organizationId:int}/masterPolicies/createEditMasterPolicy/:masterPolicyId',
        templateUrl: require('./host/views/corporates/masterPolicies/masterPolicy/createEditMasterPolicy.page.html'),
        dynamic: true,
        params: {
          organizationId: {
            value: null,
          },
          masterPolicyId: {
            value: null,
          },
          isRenewal: false,
        },
      });

      corporateMasterPoliciesState.state('host.corporateMasterPolicies.insurancePlans', {
        url: '/:masterPolicyId/insurancePlans',
        templateUrl: require('./host/views/corporates/masterPolicies/insurancePlansListing/insurancePlansListing.component.html'),
        dynamic: true,
        params: {
          organizationId: {
            value: null,
          },
          masterPolicyId: {
            value: null,
          },
          masterPolicyNumber: {
            value: null,
          },
          masterPolicyInsurer: {
            value: null,
          },
        },
      });

      $stateProvider.state('host.createEditInsurancePlan', {
        url: '/host/corporates/{organizationId}/masterPolicies/{masterPolicyId}/insurancePlans/createEdit/:insurancePlanId',
        templateUrl: require('./host/views/corporates/masterPolicies/insurancePlans/createEditInsurancePlan.page.html'),
        params: {
          organizationId: {
            value: null,
          },
          masterPolicyId: {
            value: null,
          },
          insurancePlanId: {
            value: null,
          },
        },
      });

      $urlRouterProvider.otherwise('/host/corporates');
    }

    if (abp.auth.isGranted('Host.Clinics')) {
      const clinicState = $stateProvider.state('host.clinics', {
        url: '/host/clinics?filterText&clinicType&sorting&{skipCount:int}&{maxResultCount:int}',
        templateUrl: require('./host/views/clinics/index.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });
      if (abp.auth.isGranted('Host.Clinics.Locations')) {
        clinicState.state('host.clinics.locations', {
          url: '/:id/locations',
          templateUrl: require('./host/views/clinics/locations.html'),
        });

        $stateProvider.state('host.clinicLocations', {
          url: '/host/clinics/locations/:id',
          templateUrl: require('./host/views/clinics/locations.html'),
        });
      }

      clinicState.state('host.createEditPanel', {
        url: '/host/clinics/createEdit/:panelId?requestNumber',
        templateUrl: require('./host/views/clinics/panelEditor/panelEditor.page.html'),
        params: {
          panelId: '',
        },
      });
    }

    if (abp.auth.isGranted('Host.Banks')) {
      $stateProvider.state('host.banks', {
        url: '/host/banks?filterText&sorting&{skipCount:int}&{maxResultCount:int}',
        templateUrl: require('./host/views/banks/index.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });
    }

    if (abp.auth.isGranted('Host.NewInsurers.View')) {
      const insurerState = $stateProvider.state('host.insurers', {
        url: '/host/insurers',
        templateUrl: require('./host/views/insurers/index.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });

      insurerState.state('host.insurers.insurerTeams', {
        url: '/:insurerId/insurerTeams',
        templateUrl: require('./host/views/insurers/insurerTeams/insurerTeams.page.html'),
        params: {
          insurerId: {
            value: null,
          },
        },
      });

      $stateProvider.state('host.createEditInsurer', {
        url: '/host/insurers/createEdit/:insurerId',
        templateUrl: require('./host/views/insurers/createEdit/createEditInsurer.page.html'),
        params: {
          insurerId: {
            value: null,
          },
        },
      });

      $stateProvider.state('host.createEditInsurerTeam', {
        url: '/host/insurers/:insurerId/createEditInsurerTeam/:insurerTeamId',
        templateUrl: require('./host/views/insurers/insurerTeams/createEdit/createEditInsurerTeam.page.html'),
        params: {
          insurerId: {
            value: null,
          },
          insurerTeamId: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('Host.ClaimSubmissionTemplate')) {
      $stateProvider.state('host.claimSubmissionTemplates', {
        url: '/host/claimSubmissionTemplate',
        templateUrl: require('./host/views/claimSubmissionTemplate/index.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });

      if (abp.auth.isGranted('Host.ClaimSubmissionTemplate.Create')) {
        $stateProvider.state('host.createClaimSubmissionTemplate', {
          url: '/host/claimSubmissionTemplate/createTemplate',
          templateUrl: require('./host/views/claimSubmissionTemplate/createEdit/createEditTemplate.page.html'),
        });
      }

      if (abp.auth.isGranted('Host.ClaimSubmissionTemplate.Edit')) {
        $stateProvider.state('host.editClaimSubmissionTemplate', {
          url: '/host/claimSubmissionTemplate/editTemplate/:templateId',
          templateUrl: require('./host/views/claimSubmissionTemplate/createEdit/createEditTemplate.page.html'),
        });
      }
    }

    if (abp.auth.isGranted('Host.Industries')) {
      $stateProvider.state('host.industries', {
        url: '/host/industries?filterText&sorting&{skipCount:int}&{maxResultCount:int}',
        templateUrl: require('./host/views/industries/index.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });
    }

    if (abp.auth.isGranted('Host.SpecialistDisciplines')) {
      $stateProvider.state('host.specialistDisciplines', {
        url: '/host/specialistDisciplines',
        templateUrl: require('./host/views/specialistDisciplines/index.html'),
      });
    }

    if (
      abp.setting.getBoolean('Hms.Feature.InsuranceEnhancementModule') &&
      abp.auth.isGranted('Host.SpecialistTreatmentCategories')
    ) {
      $stateProvider.state('host.specialistTreatmentCategories', {
        url: '/host/specialistTreatmentCategories',
        templateUrl: require('./host/views/specialistTreatmentCategories/specialistTreatmentCategories.page.html'),
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });
    }

    if (abp.auth.isGranted('Host.Treatments')) {
      if (abp.auth.isGranted('Host.Treatments.GeneralTreatments')) {
        $stateProvider.state('host.generalTreatments', {
          url: '/host/treatments',
          templateUrl: require('./host/views/treatments/index.html'),
        });
      }
      if (abp.auth.isGranted('Host.Treatments.PreEmploymentTreatments')) {
        $stateProvider.state('host.preEmploymentTreatments', {
          url: '/host/preEmploymentTreatments',
          templateUrl: require('./host/views/preEmploymentTreatments/index.html'),
        });
      }
      if (abp.auth.isGranted('Host.Treatments.ExclusionList')) {
        $stateProvider.state('host.exclusionList', {
          url: '/host/exclusionList',
          templateUrl: require('./host/views/hospitalizationExclusions/hospitalizationExclusions.page.html'),
        });

        $stateProvider.state('host.createEditExclusions', {
          url: '/host/hospitalizationExclusions/exclusionEditor/:serviceType',
          templateUrl: require('./host/views/hospitalizationExclusions/exclusionEditor/exclusionEditor.page.html'),
          params: {
            serviceType: {
              value: null,
            },
          },
        });
      }
      if (abp.auth.isGranted('Host.Treatments.MedicalFeeSchedules')) {
        $stateProvider.state('host.medicalFeeSchedules', {
          url:
            '/host/medicalFeeSchedules?' +
            'sorting&{skipCount:int}&{maxResultCount:int}&part&section&chapter*item' +
            '&category&subCategory&{specialistDisciplineId:int}&{bodyPartId:int}&filter',
          templateUrl: require('./host/views/treatments/medicalFeeSchedules/medicalFeeSchedule.page.html'),
          dynamic: true,
          params: {
            skipCount: {
              value: 0,
            },
            maxResultCount: {
              value: appConstants.grid.defaultPageSize,
            },
            part: {
              value: null,
            },
            section: {
              value: null,
            },
            chapter: {
              value: null,
            },
            item: {
              value: null,
            },
            category: {
              value: null,
            },
            subCategory: {
              value: null,
            },
            specialistDisciplineId: {
              value: null,
            },
            bodyPartId: {
              value: null,
            },
            filter: {
              value: null,
            },
          },
        });
      }
    }

    if (abp.auth.isGranted('PatientTickets')) {
      $stateProvider.state('patientTickets', {
        url:
          '/common/patientTickets?' +
          'sorting&{skipCount:int}&{maxResultCount:int}&{ticketType:int}&ticketNumber&transactionNumber' +
          '&status&hasMc&{company:int}&{subsidiary:int}&{department:int}&{clinic:int}&clinicLocation&patient&doctor' +
          '&{employeeType:int}&minAmount&maxAmount&minCoveredAmount&maxCoveredAmount&{benefitGroupIds:int}' +
          '&minManualPayment&maxManualPayment&startDate&endDate&ticketStartDate&ticketEndDate&{clinicType:int}&insurerId' +
          '&isCoveredByInsurance&masterPolicy&isIdentificationNumber',
        templateUrl: require('./common/views/patientTickets/index.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
          ticketType: {
            value: null,
          },
          ticketNumber: {
            value: null,
          },
          transactionNumber: {
            value: null,
          },
          status: {
            value: null,
          },
          hasMc: {
            value: null,
          },
          company: {
            value: null,
          },
          subsidiary: {
            value: null,
          },
          department: {
            value: null,
          },
          clinic: {
            value: null,
          },
          clinicLocation: {
            value: null,
          },
          patient: {
            value: null,
          },
          doctor: {
            value: null,
          },
          employeeType: {
            value: null,
          },
          minAmount: {
            value: null,
          },
          maxAmount: {
            value: null,
          },
          minCoveredAmount: {
            value: null,
          },
          maxCoveredAmount: {
            value: null,
          },
          minManualPayment: {
            value: null,
          },
          maxManualPayment: {
            value: null,
          },
          startDate: {
            value: null,
          },
          endDate: {
            value: null,
          },
          ticketStartDate: {
            value: null,
          },
          ticketEndDate: {
            value: null,
          },
          clinicType: {
            value: null,
          },
          benefitGroupIds: {
            array: true,
            value: null,
          },
          serviceType: {
            value: null,
          },
          insurerId: {
            value: null,
          },
          isCoveredByInsurance: {
            value: null,
          },
          masterPolicy: {
            value: null,
          },
          isIdentificationNumber: {
            value: 'false',
          },
        },
      });
      $stateProvider.state('patientTicketAudit', {
        url: '/common/patientTickets/audit/:ticketNumber',
        templateUrl: require('./common/views/patientTickets/audit.html'),
      });

      $stateProvider.state('patientTicketDetail', {
        url: '/common/patientTickets/detail/:ticketNumber?{latePatientTicketSubmissionId:int}',
        templateUrl: require('./common/views/patientTickets/detail/index.html'),
        params: {
          ticketNumber: {
            value: null,
          },
          latePatientTicketSubmissionId: {
            value: null,
          },
        },
      });

      // Patient ticket singapore detail page.
      $stateProvider.state('patientTicketSgDetail', {
        url: '/common/patientTickets/detailv2/:ticketNumber?{latePatientTicketSubmissionId:int}',
        templateUrl: require('./common/views/patientTickets/sg/detail/patientTicketSgDetail.html'),
        params: {
          ticketNumber: {
            value: null,
          },
          latePatientTicketSubmissionId: {
            value: null,
          },
        },
      });

      $stateProvider.state('ticketEditMemberUtilization', {
        url: '/common/patientTickets/ticketEditMemberUtilization/:ticketNumber',
        templateUrl: require('./common/views/patientTickets/editMemberUtilization/editMemberUtilization.html'),
      });

      /* Common pharmacy */

      $stateProvider.state('patientTicketDetailPharmacy', {
        url: '/common/patientTickets/detail/:ticketNumber',
        templateUrl: require('./common/views/patientTickets/detail/index.html'),
      });

      if (
        abp.auth.isGranted('PatientTickets.Clinic.Create') ||
        abp.auth.isGranted('PatientTickets.Edit') ||
        abp.auth.isGranted('PatientTickets.Manual.Create') ||
        abp.auth.isGranted('PatientTickets.Manual.Edit') ||
        abp.auth.isGranted('PatientTickets.Host.Manual.Create') ||
        abp.auth.isGranted('PatientTickets.Host.Manual.Edit') ||
        abp.auth.isGranted('PatientTickets.Reimbursement.Create') ||
        abp.auth.isGranted('PatientTickets.Reimbursement.Edit')
      ) {
        $stateProvider.state('createEditPatientTicket', {
          url:
            '/common/patientTickets/createEdit/:ticketNumber?{employeeId:int}&{clinicLocationId:int}' +
            '&{clinicType:int}&{ticketType:int}&{claimId:int}&{patientSessionId:int}&{serviceType:int}&{hasTaxAmount:bool}',
          templateUrl: require('./common/views/patientTickets/createEdit.html'),
          dynamic: true,
          params: {
            employeeId: {
              value: null,
            },
            clinicType: {
              value: null,
            },
            clinicLocationId: {
              value: null,
            },
            claimId: {
              value: null,
            },
            patientSessionId: {
              value: null,
            },
            lateSubmissionReason: {
              value: null,
            },
            lateSubmissionRequestorName: {
              value: null,
            },
            serviceType: {
              value: null,
            },
            ticketNumber: {
              value: null,
            },
          },
        });
      }
      if (
        abp.auth.isGranted('PatientTickets.Manual.Create') ||
        abp.auth.isGranted('PatientTickets.Manual.Edit') ||
        abp.auth.isGranted('PatientTickets.Host.Manual.Create') ||
        abp.auth.isGranted('PatientTickets.Host.Manual.Edit')
      ) {
        $stateProvider.state('createEditManualTicket', {
          url: '/common/patientTickets/createEditManualTicket/:ticketNumber?{employeeId:int}&{clinicLocationId:int}&{clinicType:int}&{serviceType:int}',
          templateUrl: require('./common/views/patientTickets/createEditManualTicket.page.html'),
          dynamic: true,
          params: {
            employeeId: {
              value: null,
            },
            clinicType: {
              value: null,
            },
            clinicLocationId: {
              value: null,
            },
            serviceType: {
              value: null,
            },
            accountOwnerCountryCode: {
              value: null,
            },
            ticketNumber: {
              value: null,
            },
          },
        });
      }

      if (
        abp.auth.isGranted('PatientTickets.Clinic.Create') ||
        abp.auth.isGranted('PatientTickets.Edit')
      ) {
        $stateProvider.state('clinic.createEditPatientTicket', {
          url:
            '/clinic/patient/ticket/:ticketNumber?{employeeId:int}&{clinicLocationId:int}&{clinicType:int}&{ticketType:int}&{hasTaxAmount:bool}' +
            '&{patientSessionId:int}' +
            '&{serviceType:int}',
          templateUrl: require('./clinic/views/patient/ticket/index.html'),
          params: {
            ticketNumber: {
              value: null,
            },
            employeeId: {
              value: null,
            },
            clinicLocationId: {
              value: null,
            },
            clinicType: {
              value: null,
            },
            ticketType: {
              value: null,
            },
            patientSessionId: {
              value: null,
            },
            lateSubmissionReason: {
              value: null,
            },
            lateSubmissionRequestorName: {
              value: null,
            },
          },
        });
      }

      if (
        (abp.auth.isGranted('PatientTickets.Clinic.Create') ||
          abp.auth.isGranted('PatientTickets.Edit') ||
          abp.auth.isGranted('PatientTickets.Manual.Create') ||
          abp.auth.isGranted('PatientTickets.Manual.Edit') ||
          abp.auth.isGranted('PatientTickets.Host.Manual.Create') ||
          abp.auth.isGranted('PatientTickets.Host.Manual.Edit') ||
          abp.auth.isGranted('PatientTickets.Reimbursement.Create') ||
          abp.auth.isGranted('PatientTickets.Reimbursement.Edit')) &&
        abp.setting.getBoolean('Hms.Feature.InsuranceEnhancementModule')
      ) {
        $stateProvider.state('createEditSpecialistTicket', {
          url:
            '/common/patientTickets/createEditSpecialistTicket/:ticketNumber?{employeeId:int}&{clinicLocationId:int}' +
            '&{clinicType:int}&{ticketType:int}&{claimId:int}&{patientSessionId:int}&{serviceType:int}&{hasTaxAmount:bool}',
          templateUrl: require('./common/views/patientTickets/specialistTicket/createEditSpecialistTicket.page.html'),
          dynamic: true,
          params: {
            employeeId: {
              value: null,
            },
            clinicType: {
              value: null,
            },
            clinicLocationId: {
              value: null,
            },
            claimId: {
              value: null,
            },
            patientSessionId: {
              value: null,
            },
            lateSubmissionReason: {
              value: null,
            },
            lateSubmissionRequestorName: {
              value: null,
            },
            serviceType: {
              value: null,
            },
            ticketNumber: {
              value: null,
            },
            ticketType: {
              value: null,
            },
          },
        });
      }

      $stateProvider.state('clinic.previewTicket', {
        url:
          '/clinic/patient/previewTicket/:ticketNumber?{employeeId:int}&{clinicLocationId:int}&{clinicType:int}&{ticketType:int}' +
          '&{patientSessionId:int}',
        templateUrl: require('./clinic/views/patient/previewTicket.html'),
        params: {
          ticketNumber: {
            value: null,
          },
          employeeId: {
            value: null,
          },
          clinicType: {
            value: null,
          },
          clinicLocationId: {
            value: null,
          },
          patientSessionId: {
            value: null,
          },
          lateSubmissionReason: {
            value: null,
          },
          lateSubmissionRequestorName: {
            value: null,
          },
        },
      });

      $stateProvider.state('clinic.patientTicketSubmited', {
        url: '/clinic/patient/ticketSubmitted/:ticketNumber',
        templateUrl: require('./clinic/views/patient/ticketSubmitted/index.html'),
      });
    }

    if (abp.auth.isGranted('SaleTransactions')) {
      $stateProvider.state('saleTransactions', {
        url:
          '/common/saleTransactions?{category:int}&{tenantType:int}&{transactionCategory:int}&company&subsidiary&transactionNumber&{transactionType:int}' +
          '&{status:int}&minAmount&maxAmount&minAging&maxAging&startDate&endDate&paymentInstructionTransactionNumber&insurerId' +
          '&sorting&{skipCount:int}&{maxResultCount:int}&{countryCode:string}',
        templateUrl: require('./common/views/saleTransactions/index.html'),
        dynamic: true,
        params: {
          category: {
            value: null,
          },
          tenantType: {
            value: null,
          },
          transactionCategory: {
            value: null,
          },
          subsidiary: {
            value: null,
          },
          transactionType: {
            value: null,
          },
          status: {
            value: null,
          },
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
          countryCode: {
            value: null,
          },
        },
      });
      $stateProvider.state('invoiceDetail', {
        url: '/common/invoice/:transactionNumber',
        templateUrl: require('./common/views/saleTransactions/invoiceDetail.html'),
      });

      $stateProvider.state('invoiceDetailV2', {
        url: '/common/invoiceV2/:transactionNumber',
        templateUrl: require('./common/views/saleTransactions/invoiceDetail.v2.page.html'),
      });

      $stateProvider.state('subscriptionInvoice', {
        url: '/common/subscriptionInvoice/:transactionNumber',
        templateUrl: require('./common/views/saleTransactions/subscriptionInvoice.page.html'),
      });

      $stateProvider.state('singaporeInvoice', {
        url: '/common/invoiceSG/:transactionNumber',
        templateUrl: require('./common/views/saleTransactions/invoiceDetail.singapore.page.html'),
      });

      $stateProvider.state('singaporePanelInvoice', {
        url: '/common/panelInvoiceSG/:transactionNumber',
        templateUrl: require('./common/views/saleTransactions/invoiceDetail.singapore.panel.page.html'),
      });

      $stateProvider.state('singaporeReimbursementInvoice', {
        url: '/common/reimbursementInvoiceSG/:transactionNumber',
        templateUrl: require('./common/views/saleTransactions/invoiceDetail.singapore.reimbursement.page.html'),
      });

      $stateProvider.state('insurerInvoice', {
        url: '/common/insurerInvoice/:transactionNumber',
        templateUrl: require('./common/views/saleTransactions/insurerInvoice.page.html'),
      });

      $stateProvider.state('creditMemoDetail', {
        url: '/common/creditMemo/:transactionNumber',
        templateUrl: require('./common/views/saleTransactions/creditMemoDetail.html'),
      });
      $stateProvider.state('subscriptionCreditMemo', {
        url: '/common/subscriptionCreditMemo/:transactionNumber',
        templateUrl: require('./common/views/saleTransactions/subscriptionCreditMemo.page.html'),
      });
      $stateProvider.state('paymentDetail', {
        url: '/common/payment/:transactionNumber',
        templateUrl: require('./common/views/saleTransactions/paymentDetail.html'),
      });
      if (
        abp.auth.isGranted('SaleTransactions.Host.CreatePayment') ||
        abp.auth.isGranted('SaleTransactions.Host.EditPayment')
      ) {
        $stateProvider.state('createEditPayment', {
          url: '/common/payment/createEdit/:transactionNumber',
          templateUrl: require('./common/views/saleTransactions/createEditPayment.html'),
          params: {
            transactionNumber: {
              value: null,
            },
          },
        });
      }
      if (abp.auth.isGranted('SaleTransactions.Host.PaymentInstruction')) {
        $stateProvider.state('paymentInstructionRequests', {
          url: '/common/paymentInstructionRequests',
          templateUrl: require('./common/views/saleTransactions/paymentInstructionRequests.html'),
          dynamic: true,
        });

        $stateProvider.state('paymentInstruction', {
          url: '/common/paymentInstruction?transactionNumber&{requestId:int}',
          templateUrl: require('./common/views/saleTransactions/paymentInstruction.html'),
          params: {
            requestId: {
              value: 0,
            },
          },
        });

        $stateProvider.state('transferAdvice', {
          url: '/common/transferAdvice?transactionNumber&{requestId:int}&{skipCount:int}&{maxResultCount:int}',
          templateUrl: require('./common/views/saleTransactions/transferAdvice.html'),
          params: {
            requestId: {
              value: 0,
            },
            skipCount: {
              value: 0,
            },
            maxResultCount: {
              value: 100,
            },
          },
        });
      }
      $stateProvider.state('statement', {
        url: '/common/statement/:tenantId?subsidiaryId&{type:int}&startDate&endDate',
        templateUrl: require('./common/views/saleTransactions/statement.html'),
      });
      if (abp.auth.isGranted('SaleTransactions.Host.CreatePayment')) {
        $stateProvider.state('bulkMakePayments', {
          url: '/common/bulkPayments',
          templateUrl: require('./common/views/saleTransactions/bulkPayments.html'),
        });
      }
      $stateProvider.state('invoiceFinder', {
        url: '/common/invoiceFinder',
        templateUrl: require('./common/views/saleTransactions/invoiceFinder.html'),
      });

      if (abp.auth.isGranted('SaleTransactions.Host.LowBalanceNotificationLogs')) {
        $stateProvider.state('lowBalanceNotificationLogs', {
          url: '/common/lowBalanceNotificationLogs',
          templateUrl: require('./common/views/saleTransactions/lowBalanceNotificationLogs.html'),
          dynamic: true,
        });
      }
    }

    if (abp.auth.isGranted('Host.Claims') || abp.auth.isGranted('Corporate.Claims')) {
      $stateProvider.state('outpatientNpr', {
        url: '/common/claims?sorting&{skipCount:int}&{maxResultCount:int}&claimNumber&employee&status&ticketNumber&{corporateId:int}&{subsidiaryId:int}&serviceProvider&clType&submissionStartDate&submissionEndDate&receiptStartDate&receiptEndDate',
        templateUrl: require('./common/views/claims/index.html'),
        dynamic: true,
        params: {
          claimNumber: {
            value: null,
          },
          employee: {
            value: null,
          },
          status: {
            value: null,
          },
          ticketNumber: {
            value: null,
          },
          corporateId: {
            value: null,
          },
          subsidiaryId: {
            value: null,
          },
          serviceProvider: {
            value: null,
          },
          clinicType: {
            value: null,
          },
          submissionStartDate: {
            value: null,
          },
          submissionEndDate: {
            value: null,
          },
          receiptStartDate: {
            value: null,
          },
          receiptEndDate: {
            value: null,
          },
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });

      $stateProvider.state('inpatientNpr', {
        url:
          '/common/inpatientNprClaims?sorting&{skipCount:int}&{maxResultCount:int}&claimNumber&employee' +
          '&status&ticketNumber&{corporateId:int}&{subsidiaryId:int}&serviceProvider&clType&submissionStartDate' +
          '&submissionEndDate&receiptStartDate&receiptEndDate&insurer&{isCoveredByInsurer:bool}&masterPolicy',
        templateUrl: require('./common/views/inpatientNprClaims/inpatientNprClaims.page.html'),
        dynamic: true,
        params: {
          claimNumber: {
            value: null,
          },
          employee: {
            value: null,
          },
          status: {
            value: null,
          },
          ticketNumber: {
            value: null,
          },
          corporateId: {
            value: null,
          },
          subsidiaryId: {
            value: null,
          },
          serviceProvider: {
            value: null,
          },
          clinicType: {
            value: null,
          },
          submissionStartDate: {
            value: null,
          },
          submissionEndDate: {
            value: null,
          },
          receiptStartDate: {
            value: null,
          },
          receiptEndDate: {
            value: null,
          },
          insurer: {
            value: null,
          },
          isCoveredByInsurer: {
            value: null,
          },
          masterPolicy: {
            value: null,
          },
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });

      $stateProvider.state('claimListDetail', {
        url: '/common/claims/claimDetail/:claimNumber',
        templateUrl: require('./common/views/claims/details/index.html'),
      });

      $stateProvider.state('inpatientNprClaimDetail', {
        url: '/common/inpatientNprClaims/claimDetail/:claimNumber',
        templateUrl: require('./common/views/inpatientNprClaims/details/details.page.html'),
      });

      $stateProvider.state('processClaim', {
        url: '/host/claims/processClaim/:claimNumber',
        templateUrl: require('./common/views/claims/process/index.html'),
      });

      $stateProvider.state('inpatientNprProcessClaim', {
        url: '/common/inpatientNprClaims/processClaim/:claimNumber',
        templateUrl: require('./common/views/inpatientNprClaims/process/process.page.html'),
      });

      $stateProvider.state('inpatientNprProcessPrePostClaim', {
        url: '/common/nprClaims/processPrePostHospitalization/:claimNumber',
        templateUrl: require('./common/views/inpatientNprClaims/processPrePostHospitalization/processPrePostHospitalization.page.html'),
      });

      $stateProvider.state('host.createInpatientNprClaim', {
        url: '/host/employees/inpatientNprClaims?{claimType:int}{employeeId:int}&ticketNumber',
        templateUrl: require('./host/views/employees/inpatientNprClaims/inpatientNprClaim.page.html'),
        params: {
          claimType: {
            value: null,
          },
          employeeId: {
            value: null,
          },
          ticketNumber: {
            value: null,
          },
        },
      });
    }

    if (
      abp.auth.isGranted('GuaranteeLetters.Host') ||
      abp.auth.isGranted('GuaranteeLetters.Corporate')
    ) {
      $stateProvider.state('guaranteeLetters', {
        url:
          '/common/guaranteeLetters?sorting&{skipCount:int}&{maxResultCount:int}&letterNumber&claimNumber&ticketNumber' +
          '&status&{clinicLocation:int}&employee&company&approvalStartDate&approvalEndDate&appliedStartDate&appliedEndDate',
        templateUrl: require('./common/views/guaranteeLetters/index.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
          letterNumber: {
            value: null,
          },
          claimNumber: {
            value: null,
          },
          ticketNumber: {
            value: null,
          },
          status: {
            value: null,
          },
          clinicLocation: {
            value: null,
          },
          employee: {
            value: null,
          },
          company: {
            value: null,
          },
          approvalStartDate: {
            value: null,
          },
          approvalEndDate: {
            value: null,
          },
          appliedStartDate: {
            value: null,
          },
          appliedEndDate: {
            value: null,
          },
        },
      });

      if (
        abp.auth.isGranted('GuaranteeLetters.Host') ||
        abp.auth.isGranted('GuaranteeLetters.Corporate.Advance')
      ) {
        $stateProvider.state('guaranteeLetterDetail', {
          url: '/common/guaranteeLetters/details/:letterNumber?timeZoneId',
          templateUrl: require('./common/views/guaranteeLetters/details/index.html'),
        });
      }
    }

    if (abp.auth.isGranted('Clinic.GuaranteeLetterAndExtensionRequest')) {
      $stateProvider.state('specialistGuaranteeLetters', {
        url:
          '/clinic/guaranteeLetters?sorting&{skipCount:int}&{maxResultCount:int}&filter' +
          '&status&{clinicLocation:int}&checkedInStartDate&checkedInEndDate&letterNumber',
        templateUrl: require('./clinic/views/guaranteeLetters/guaranteeLetters.page.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
          filter: {
            value: null,
          },
          letterNumber: {
            value: null,
          },
          status: {
            value: null,
          },
          clinicLocation: {
            value: null,
          },
          checkedInStartDate: {
            value: null,
          },
          checkedInEndDate: {
            value: null,
          },
        },
      });
    }

    if (
      abp.auth.isGranted('Clinic.GuaranteeLetterAndExtensionRequest') &&
      abp.setting.getBoolean('Hms.Feature.PostHospitalizationGuaranteeLetterWorkflow')
    ) {
      $stateProvider.state('clinic.postHospitalizationGuaranteeLetters', {
        url:
          '/clinic/postHospitalization/guaranteeLetters?sorting&{skipCount:int}&{maxResultCount:int}&filter' +
          '&status&{clinicLocation:int}&checkedInStartDate&checkedInEndDate&letterNumber',
        templateUrl: require('./clinic/views/postHospitalization/guaranteeLetters/guaranteeLetters.page.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
          filter: {
            value: null,
          },
          letterNumber: {
            value: null,
          },
          status: {
            value: null,
          },
          clinicLocation: {
            value: null,
          },
          checkedInStartDate: {
            value: null,
          },
          checkedInEndDate: {
            value: null,
          },
        },
      });
    }

    if (
      abp.auth.isGranted('SpecialistClaims.Host') ||
      abp.auth.isGranted('SpecialistClaims.Clinic')
    ) {
      $stateProvider.state('specialistClaims', {
        url:
          '/common/specialistClaims?sorting&{claimStatus:int}&letterNumber&ticketNumber&claimNumber&filter&transactionNumber&status&corporateBillingStatus&panelBillingStatus&queryStatus' +
          '&claimStartDate&claimEndDate&patient&disciplineId&minAmount&maxAmount&{panelLocationId:int}&{organizationId:int}' +
          '&{skipCount:int}&{maxResultCount:int}',
        templateUrl: require('./common/views/specialistClaims/index.html'),
        dynamic: true,
        params: {
          claimStatus: {
            array: true,
            value: null,
          },
          letterNumber: {
            value: null,
          },
          ticketNumber: {
            value: null,
          },
          claimNumber: {
            value: null,
          },
          transactionNumber: {
            value: null,
          },
          corporateBillingStatus: {
            value: null,
          },
          panelBillingStatus: {
            value: null,
          },
          claimStartDate: {
            value: null,
          },
          claimEndDate: {
            value: null,
          },
          patient: {
            value: null,
          },
          disciplineId: {
            value: null,
          },
          referenceNumber: {
            value: null,
          },
          minAmount: {
            value: null,
          },
          maxAmount: {
            value: null,
          },
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
          panelLocationId: {
            value: null,
          },
          organizationId: {
            value: null,
          },
          queryStatus: {
            value: null,
          },
          requestStatus: {
            value: null,
          },
        },
      });

      $stateProvider.state('specialistClaimDetail', {
        url: '/common/specialistClaims/viewDetail/:claimNumber?{showZen:int}&timeZoneId',
        templateUrl: require('./common/views/specialistClaims/viewClaim/index.html'),
        params: {
          showZen: {
            value: null,
          },
          claimNumber: {
            value: null,
          },
          tab: {
            value: null,
          },
          timeZoneId: {
            value: null,
          },
        },
      });

      if (
        abp.auth.isGranted('SpecialistClaims.Host.Edit') ||
        abp.auth.isGranted('SpecialistClaims.Clinic.Edit') ||
        abp.auth.isGranted('SpecialistClaims.Clinic.Create')
      ) {
        $stateProvider.state('createEditSpecialistClaim', {
          url: '/common/specialistClaims/editClaim/:claimNumber?{employeeId:int}&{panelLocationId:int}&letterNumber&{claimWithTopUp:bool}&{formAb:int}&timeZoneId',
          templateUrl: require('./common/views/specialistClaims/editClaim/index.html'),
          params: {
            employeeId: {
              value: null,
            },
            panelLocationId: {
              value: null,
            },
            letterNumber: {
              value: null,
            },
            claimWithTopUp: {
              value: null,
            },
            formAb: {
              value: null,
            },
            claimNumber: {
              value: null,
            },
          },
        });
      }

      if (abp.auth.isGranted('SpecialistClaims.Host.Edit')) {
        $stateProvider.state('processSpecialistClaim', {
          url: '/common/specialistClaims/processClaim/:claimNumber?timeZoneId',
          templateUrl: require('./common/views/specialistClaims/processClaim/index.html'),
        });

        $stateProvider.state('processSpecialistClaimNew', {
          url: '/common/specialistClaims/processClaimNew/:claimNumber?timeZoneId',
          templateUrl: require('./common/views/specialistClaims/processClaim/processClaim.page.html'),
        });
      }

      if (abp.setting.getBoolean('Hms.Feature.SpecialistWorkflowEnhancement')) {
        $stateProvider.state('createEditSpecialistFinalRequest', {
          url: '/common/specialistClaims/finalRequest/:claimNumber?letterNumber&{claimWithTopUp:bool}&{formAb:int}&timeZoneId',
          templateUrl: require('./common/views/specialistClaims/finalRequest/finalRequest.page.html'),
          params: {
            claimNumber: {
              value: null,
            },
            letterNumber: {
              value: null,
            },
            claimWithTopUp: {
              value: null,
            },
            formAb: {
              value: null,
            },
          },
        });

        $stateProvider.state('specialistClaimRequestDetail', {
          url: '/common/specialistClaims/details/:claimNumber?timeZoneId',
          templateUrl: require('./common/views/specialistClaims/details/details.page.html'),
          params: {
            activeTab: {
              value: 1,
            },
            timeZoneId: {
              value: null,
            },
          },
        });

        /* Host create specialist query */

        $stateProvider.state('createSpecialistQuery', {
          url: '/host/specialistQuery/createQuery?letterNumber&claimNumber&timeZoneId',
          templateUrl: require('./common/views/specialistClaims/query/createQuery/createQuery.page.html'),
          params: {
            letterNumber: {
              value: null,
            },
            claimNumber: {
              value: null,
            },
            timeZoneId: {
              value: null,
            },
          },
        });

        /* Host and panel view specialist query */

        $stateProvider.state('viewSpecialistQuery', {
          url: '/common/query/viewSpecialistQuery?claimNumber&queryNumber&timeZoneId',
          templateUrl: require('./common/views/specialistClaims/query/viewQuery/viewQuery.page.html'),
          dynamic: true,
          params: {
            letterNumber: {
              value: null,
            },
            queryNumber: {
              value: null,
            },
            claimNumber: {
              value: null,
            },
            timeZoneId: {
              value: null,
            },
          },
        });

        /* Panel reply specialist query */

        $stateProvider.state('clinic.replySpecialistQuery', {
          url: '/clinic/specialistQuery/query?claimNumber&queryNumber&timeZoneId',
          templateUrl: require('./clinic/views/specialistQuery/query.page.html'),
          params: {
            letterNumber: {
              value: null,
            },
            claimNumber: {
              value: null,
            },
            queryNumber: {
              value: null,
            },
            timeZoneId: {
              value: null,
            },
          },
        });

        /* Panel request adjustment */

        $stateProvider.state('specialistRequestAdjustment', {
          url: '/common/specialistClaims/requestAdjustment/:claimNumber?timeZoneId',
          templateUrl: require('./common/views/specialistClaims/requestAdjustment/requestAdjustment.page.html'),
        });
      }
    }

    if (
      abp.features.isEnabled('HasCorporateModule') &&
      abp.auth.isGranted('Corporate.BenefitGroups')
    ) {
      $stateProvider.state('corporate.benefitGroups', {
        url: '/corporate/benefitGroups',
        templateUrl: require('./corporate/views/benefitGroups/index.html'),
      });

      if (
        abp.auth.isGranted('Corporate.BenefitGroups.Create') ||
        abp.auth.isGranted('Corporate.BenefitGroups.Edit')
      ) {
        $stateProvider.state('corporate.createEditBenefitGroup', {
          url: '/corporate/benefitGroups/createEdit/:id?{isPreEmployment:bool}',
          templateUrl: require('./corporate/views/benefitGroups/createEdit/createEditBenefitGroup.page.html'),
          params: {
            id: {
              value: null,
            },
            isPreEmployment: {
              value: false,
            },
            isCopy: {
              value: false,
            },
          },
        });
      }

      $stateProvider.state('corporate.benefitGroupHistory', {
        url: '/corporate/benefitGroupHistory/:id?name',
        templateUrl: require('./corporate/views/benefitGroups/history/history.page.html'),
      });
    }

    if (abp.features.isEnabled('HasCorporateModule')) {
      if (abp.auth.isGranted('Corporate.ClaimPolicies')) {
        $stateProvider.state('corporate.claimPolicies', {
          url: '/corporate/claimPolicies',
          templateUrl: require('./corporate/views/claimPolicies/index.html'),
        });

        if (
          abp.auth.isGranted('Corporate.ClaimPolicies.Create') ||
          abp.auth.isGranted('Corporate.ClaimPolicies.Edit')
        ) {
          $stateProvider.state('createEditClaimPolicies', {
            url: '/corporate/claimPolicies/createEditClaimPolicies/:id',
            templateUrl: require('./corporate/views/claimPolicies/createEditClaimPolicy.html'),
            params: {
              id: '',
            },
          });
        }

        $stateProvider.state('corporate.compareClaimPolicies', {
          url: '/corporate/claimPolicies/compare/:id',
          templateUrl: require('./corporate/views/claimPolicies/compare.html'),
        });
      }
    }

    if (
      abp.setting.getBoolean('Hms.Feature.SubscriptionManagementEnhancement') &&
      abp.features.isEnabled('HasCorporateModule') &&
      abp.auth.isGranted('Corporate.Subscriptions')
    ) {
      $stateProvider.state('corporate.subscriptions', {
        url: '/corporate/subscriptions',
        templateUrl: require('./corporate/views/subscriptions/index.html'),
      });

      $stateProvider.state('corporate.subscriptionDetail', {
        url: '/corporate/subscriptions/detail/{subscriptionNumber}',
        templateUrl: require('./corporate/views/subscriptions/detail/detail.page.html'),
      });
    }

    if (abp.features.isEnabled('HasCorporateModule') && abp.auth.isGranted('Corporate.Employees')) {
      $stateProvider.state('corporate.employees', {
        url: '/corporate/employees?{employeeType:int}&filterText&{isActive:bool}&{hasMobileApp:bool}&{hasDataConsent:bool}&{benefitGroup:int}&{subsidiary:int}&{department:int}&sorting&{skipCount:int}&{maxResultCount:int}&{states:int}',
        templateUrl: require('./corporate/views/employees/index.html'),
        dynamic: true,
        params: {
          employeeType: {
            value: null,
          },
          isActive: {
            value: null,
          },
          hasMobileApp: {
            value: null,
          },
          hasDataConsent: {
            value: null,
          },
          benefitGroup: {
            array: true,
            value: null,
          },
          subsidiary: {
            array: true,
            value: null,
          },
          department: {
            array: true,
            value: null,
          },
          states: {
            array: true,
            value: null,
          },
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });

      $stateProvider.state('corporate.employeeHistory', {
        url: '/corporate/employeeHistory/:id?name',
        templateUrl: require('./corporate/views/employees/viewHistory.html'),
      });

      $stateProvider.state('corporate.memberDataConsent', {
        url: '/corporate/memberDataConsent/:id?name',
        templateUrl: require('./corporate/views/employees/dataConsent/dataConsent.page.html'),
      });
    }

    if (abp.features.isEnabled('HasCorporateModule') && abp.features.isEnabled('HasInsurance')) {
      $stateProvider.state('corporate.policyHolder', {
        url: '/corporate/policyHolder?filterText&{employeeType:int}&{states:int}&{plan:int}&{subsidiary:int}&{department:int}&sorting&{skipCount:int}&{maxResultCount:int}',
        templateUrl: require('./corporate/views/policyHolder/policyHolder.page.html'),
        dynamic: true,
        params: {
          filterText: {
            value: null,
          },
          employeeType: {
            value: null,
          },
          states: {
            array: true,
            value: null,
          },
          plan: {
            value: null,
          },
          subsidiary: {
            array: true,
            value: null,
          },
          department: {
            array: true,
            value: null,
          },
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });

      $stateProvider.state('corporate.insurancePlans', {
        url: '/corporate/insurancePlans?sorting&{skipCount:int}&{maxResultCount:int}',
        templateUrl: require('./corporate/views/insurancePlans/insurancePlans.page.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });
    }

    if (
      abp.features.isEnabled('HasCorporateModule') &&
      abp.auth.isGranted('Corporate.SubsidiaryDepartments')
    ) {
      $stateProvider.state('corporate.subsidiaryDepartments', {
        url: '/corporate/subsidiaryDepartments?{subsidiary:int}',
        templateUrl: require('./corporate/views/subsidiaryDepartments/index.html'),
        params: {
          subsidiary: {
            value: null,
          },
        },
      });
    }

    if (abp.features.isEnabled('HasCorporateModule')) {
      if (abp.auth.isGranted('Corporate.PanelLocations')) {
        $stateProvider.state('corporate.panelList', {
          url: '/corporate/panelList?sorting&{skipCount:int}&{maxResultCount:int}&{serviceType:int}&{ratesType:int}&countrySubdivisionId&city&{is24By7:bool}&{hasXray:bool}&{midnightSurcharge:bool}&{hasFemaleDoctor:bool}&languages&benefitGroupId&panelName',
          templateUrl: require('./common/views/panelList/panelList.page.html'),
          dynamic: true,
          params: {
            serviceType: {
              value: null,
            },
            ratesType: {
              value: null,
            },
            countrySubdivisionId: {
              value: null,
            },
            city: {
              value: null,
            },
            is24By7: {
              value: null,
            },
            hasXray: {
              value: null,
            },
            midnightSurcharge: {
              value: null,
            },
            hasFemaleDoctor: {
              value: null,
            },
            languages: {
              value: null,
            },
            benefitGroupId: {
              value: null,
            },
            skipCount: {
              value: 0,
            },
            maxResultCount: {
              value: appConstants.grid.defaultPageSize,
            },
          },
        });
      }

      if (abp.auth.isGranted('PanelRequests.Corporate')) {
        $stateProvider.state('corporate.panelRequests', {
          url: '/corporate/panelRequests?filterText&sorting&{skipCount:int}&{maxResultCount:int}&recruitmentStatus&countrySubdivisionId',
          templateUrl: require('./corporate/views/panelRequests/panelRequests.page.html'),
          dynamic: true,
          params: {
            recruitmentStatus: {
              value: null,
            },
            countrySubdivisionId: {
              value: null,
            },
            skipCount: {
              value: 0,
            },
            maxResultCount: {
              value: appConstants.grid.defaultPageSize,
            },
          },
        });
      }

      if (abp.auth.isGranted('PanelRequests.Corporate.Create')) {
        $stateProvider.state('corporate.createPanelRequest', {
          url: '/corporate/panelRequests/createPanelRequest?accountOwnerCountry',
          templateUrl: require('./common/views/panelRequests/createPanelRequest/createPanelRequest.page.html'),
          params: {
            accountOwnerCountry: {
              value: null,
            },
          },
        });
      }

      if (abp.auth.isGranted('Corporate.ClinicAccess')) {
        $stateProvider.state('corporate.panelAccess', {
          url: '/corporate/panelAccessPolicies',
          templateUrl: require('./corporate/views/panelAccesses/panelAccesses.page.html'),
        });
      }
    }

    if (abp.features.isEnabled('HasCorporateModule')) {
      $stateProvider.state('corporate.latePatientTicketSubmission', {
        url: '/corporate/latePatientTicketSubmission/:latePatientTicketSubmissionId',
        templateUrl: require('./corporate/views/latePatientTicketSubmission/latePatientTicketSubmission.page.html'),
      });
    }

    if (abp.features.isEnabled('HasClinicModule') && abp.auth.isGranted('Clinic.Doctors')) {
      $stateProvider.state('clinic.doctors', {
        url: '/clinic/doctors?filterText',
        templateUrl: require('./clinic/views/doctors/index.html'),
      });
    }

    if (abp.auth.isGranted('Host.MimsClass')) {
      $stateProvider.state('host.mimsClass', {
        url: '/host/MimsClass?filterText&sorting&{skipCount:int}&{maxResultCount:int}',
        templateUrl: require('./host/views/mimsClasses/index.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });
    }

    if (abp.auth.isGranted('Host.DirectorDashboard')) {
      // TODO: depreciate director's dashboard
      // $stateProvider.state('host.directorDashboard', {
      //   url: '/host/directorDashboard',
      //   templateUrl: require('./host/views/directorDashboard/index.html'),
      // });
      // $urlRouterProvider.otherwise('/host/directorDashboard');
      $urlRouterProvider.otherwise('/welcome');
    }

    if (abp.auth.isGranted('Host.OperationsDashboard')) {
      $stateProvider.state('host.operationsDashboard', {
        url: '/host/operationsDashboard?targetWidget&{showLateSubmission:bool}',
        templateUrl: require('./host/views/operationsDashboard/operationsDashboard.page.html'),
        params: {
          targetWidget: {
            value: null,
          },
          showLateSubmission: {
            value: null,
          },
        },
      });

      /* Insurance claims batch action page */

      $stateProvider.state('host.insuranceClaimsBatchAction', {
        url: '/host/operationsDashboard/insuranceClaims/batchAction?{activeTab:int}&insurerBatchId&chosenTicketNumbers&{completeDraft:bool}',
        templateUrl: require('./host/views/operationsDashboard/insuranceClaims/batchAction/batchAction.page.html'),
        params: {
          activeTab: {
            value: null,
          },
          insurerBatchId: {
            value: null,
          },
          chosenTicketNumbers: {
            array: true,
            value: null,
          },
          completeDraft: {
            value: false,
          },
        },
      });

      /* To view insurance claims details from the operations dashboard listing. */

      $stateProvider.state('insuranceClaimDetails', {
        url: '/host/insuranceClaimDetails?id&{ticketNumber}',
        templateUrl: require('./host/views/operationsDashboard/insuranceClaims/details/details.page.html'),
        params: {
          id: {
            value: null,
          },
          ticketNumber: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('Host.Clinics')) {
      $stateProvider.state('host.panelList', {
        url: '/host/panelList?sorting&{skipCount:int}&{maxResultCount:int}&{serviceType:int}&{ratesType:int}&countrySubdivisionId&city&{is24By7:bool}&{hasXray:bool}&{midnightSurcharge:bool}&{hasFemaleDoctor:bool}&languages&benefitGroupId&corporateId',
        templateUrl: require('./common/views/panelList/panelList.page.html'),
        dynamic: true,
        params: {
          serviceType: {
            value: null,
          },
          ratesType: {
            value: null,
          },
          countrySubdivisionId: {
            value: null,
          },
          city: {
            value: null,
          },
          is24By7: {
            value: null,
          },
          hasXray: {
            value: null,
          },
          midnightSurcharge: {
            value: null,
          },
          hasFemaleDoctor: {
            value: null,
          },
          languages: {
            value: null,
          },
          benefitGroupId: {
            value: null,
          },
          corporateId: {
            value: null,
          },
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });
    }

    if (abp.auth.isGranted('PanelRequests.Host')) {
      $stateProvider.state('host.panelRecruitment', {
        url: '/host/panelRecruitment',
        templateUrl: require('./host/views/panelRecruitment/index.html'),
      });
      if (abp.auth.isGranted('PanelRequests.Host.Create')) {
        $stateProvider.state('host.createPanelRequest', {
          url: '/host/panelRecruitment/createPanelRequest?&eligibleCountries',
          templateUrl: require('./common/views/panelRequests/createPanelRequest/createPanelRequest.page.html'),
          params: {
            eligibleCountries: {
              array: true,
              value: null,
            },
          },
        });
      }

      $stateProvider.state('host.panelRequest', {
        url: '/host/panelRequest/:requestNumber',
        templateUrl: require('./host/views/panelRecruitment/panelRequest/panelRequest.page.html'),
      });
    }

    if (abp.features.isEnabled('HasCorporateModule') && abp.auth.isGranted('Corporate.Dashboard')) {
      $stateProvider.state('corporate.dashboard', {
        url: '/corporate/dashboard?{employeeId:int}&{employeeType:int}&{department:int}&{subsidiary:int}&{employeeActive:int}&{employeeState:int}&startDate&endDate',
        templateUrl: require('./corporate/views/dashboard/dashboard.html'),
        dynamic: true,
        params: {
          employeeActive: {
            value: null,
          },
          subsidiary: {
            value: null,
          },
          department: {
            array: true,
            value: null,
          },
          employeeType: {
            value: null,
          },
          employeeId: {
            value: null,
          },
          employeeState: {
            value: null,
          },
          startDate: {
            value: null,
          },
          endDate: {
            value: null,
          },
        },
      });

      $urlRouterProvider.otherwise('/corporate/dashboard');
    }

    if (abp.features.isEnabled('HasClinicModule') && !abp.features.isEnabled('HasPharmacyModule')) {
      $stateProvider.state('clinic.main', {
        url: '/clinic/main',
        templateUrl: require('./clinic/views/main/index.html'),
      });
      $stateProvider.state('clinic.checkin', {
        url: '/clinic/patients?filterText',
        templateUrl: require('./clinic/views/patient/index.html'),
        dynamic: true,
        params: {
          filterText: {
            value: null,
          },
        },
      });

      $stateProvider.state('clinic.newCheckIn', {
        url: '/clinic/patients?filterText',
        templateUrl: require('./clinic/views/patient/index.html'),
        dynamic: true,
        params: {
          filterText: {
            value: null,
          },
        },
      });

      if (abp.features.isEnabled('ClinicModule.PanelDashboard')) {
        $stateProvider.state('clinic.panelDashboard', {
          url: '/clinic/panelDashboard',
          templateUrl: require('./clinic/views/panelDashboard/panelDashboard.page.html'),
        });
      }

      $urlRouterProvider.otherwise('/clinic/main');
    }

    if (abp.auth.isGranted('Pharmacy.Prescriptions')) {
      $stateProvider.state('prescriptions', {
        url:
          '/pharmacy/prescriptions?category' +
          '&prescriptionNumber&referenceNumber&customer&customerOrganization' +
          '&startDate&endDate',
        templateUrl: require('./pharmacy/views/prescriptions/index.html'),
        dynamic: true,
        params: {
          category: {
            value: 'new',
          },
          prescriptionNumber: {
            value: null,
          },
          referenceNumber: {
            value: null,
          },
          customer: {
            value: null,
          },
          customerOrganization: {
            value: null,
          },
          startDate: {
            value: null,
          },
          endDate: {
            value: null,
          },
        },
      });

      $stateProvider.state('fulfillments', {
        url:
          '/pharmacy/fulfillments/:fulfillmentNumber?transactionNumber&customerOrganizationName' +
          '&prescriberOrganizationName&customer&sorting&{skipCount:int}&{maxResultCount:int}' +
          '&minAmount&maxAmount&minCoveredAmount&maxCoveredAmount' +
          '&minManualPayment&maxManualPayment&startDate&endDate',
        templateUrl: require('./pharmacy/views/fulfillments/fulfillments.page.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
          fulfillmentNumber: {
            value: null,
          },
          transactionNumber: {
            value: null,
          },
          customerOrganizationName: {
            value: null,
          },
          prescriberOrganizationName: {
            value: null,
          },
          customer: {
            value: null,
          },
          minAmount: {
            value: null,
          },
          maxAmount: {
            value: null,
          },
          minCoveredAmount: {
            value: null,
          },
          maxCoveredAmount: {
            value: null,
          },
          minManualPayment: {
            value: null,
          },
          maxManualPayment: {
            value: null,
          },
          startDate: {
            value: null,
          },
          endDate: {
            value: null,
          },
          billingStatus: {
            value: null,
          },
        },
      });

      $stateProvider.state('prescriptionDetail', {
        url: '/pharmacy/prescription/:prescriptionNumber?fulfillmentNumber',
        templateUrl: require('./pharmacy/views/prescriptions/detail.html'),
      });
      $urlRouterProvider.otherwise('/pharmacy/prescriptions');

      $stateProvider.state('ticketDetail', {
        url: '/pharmacy/fulfillments/patientTicket/:ticketNumber',
        templateUrl: require('./pharmacy/views/fulfillments/patientTicket.html'),
      });
      $urlRouterProvider.otherwise('/pharmacy/fulfillments');

      $stateProvider.state('fulfillmentProcessing', {
        url: '/pharmacy/prescription/origin/:prescriptionNumber?fulfillmentNumber',
        templateUrl: require('./pharmacy/views/prescriptions/origin/index.html'),
      });
      $urlRouterProvider.otherwise('/pharmacy/prescriptions');
    }

    if (abp.auth.isGranted('Clinic.SpecialistDashboard')) {
      $stateProvider.state('clinic.specialistDashboard', {
        url: '/clinic/specialistDashboard',
        templateUrl: require('./clinic/views/specialistDashboard/index.html'),
      });
      $urlRouterProvider.otherwise('/clinic/specialistDashboard');
    }

    if (abp.auth.isGranted('SignatureProfiles.Host')) {
      $stateProvider.state('host.signatureProfiles', {
        url: '/common/signatureProfiles',
        templateUrl: require('./common/views/signatureProfiles/index.html'),
      });
    }

    if (abp.auth.isGranted('Host.CustomEmailTemplates')) {
      $stateProvider.state('host.customEmailTemplates', {
        url: '/host/emailTemplates',
        templateUrl: require('./host/views/customEmailTemplates/index.html'),
      });

      $stateProvider.state('host.createEditCustomEmailTemplate', {
        url: '/host/emailTemplates/createEditTemplate/:templateId',
        templateUrl: require('./host/views/customEmailTemplates/createEditTemplate.html'),
        params: {
          templateId: '',
        },
      });
    }

    if (abp.features.isEnabled('Specialist.Enabled')) {
      $stateProvider.state('signatureProfiles', {
        url: '/common/signatureProfiles/personal',
        templateUrl: require('./common/views/signatureProfiles/personal/index.html'),
      });
    }

    if (abp.auth.isGranted('Administration.Tenant.Settings')) {
      $stateProvider.state('tenant.settings', {
        url: '/tenant/settings',
        templateUrl: require('./tenant/views/settings/index.html'),
        params: {
          tab: null,
        },
      });
    }

    if (abp.features.isEnabled('HasCorporateModule')) {
      $stateProvider.state('corporate.insurances', {
        url: '/corporate/insurances',
        templateUrl: require('./corporate/views/insurances/index.html'),
      });
    }

    if (abp.auth.isGranted('Corporate.Reports.ClinicsReport')) {
      $stateProvider.state('corporate.clinicsReport', {
        url:
          '/corporate/clinicsReport?{ticketType:int}&{employeeType:int}&{clinicLocation:int}&employee&nationalId' +
          '&employeeNumber&benefitGroup&{subsidiary:int}&{department:int}&{financialYear:int}&{clinicType:int}' +
          '&{serviceType:int}&startDate&endDate',
        templateUrl: require('./corporate/views/clinicsReport/index.html'),
        dynamic: true,
        params: {
          ticketType: {
            value: null,
          },
          clinicLocation: {
            value: null,
          },
          employeeType: {
            value: null,
          },
          clinicType: {
            value: null,
          },
          serviceType: {
            value: null,
          },
          subsidiary: {
            value: null,
          },
          department: {
            array: true,
            value: null,
          },
          financialYear: {
            value: null,
          },
          startDate: {
            value: null,
          },
          endDate: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('Corporate.Reports.EmployeesReport')) {
      $stateProvider.state('corporate.employeesReport', {
        url: '/corporate/employeesReport?{ticketType:int}&{employeeType:int}&{ticketCountMin:int}&{ticketCountMax:int}&{mcTakenMin:int}&{mcTakenMax:int}&{coveredAmountMin:int}&{coveredAmountMax:int}&{employeePortionMin:int}&{employeePortionMax:int}&employee&nationalId&employeeNumber&benefitGroup&{subsidiary:int}&{department:int}&{clinicType:int}&{serviceType:int}&{financialYear:int}&startDate&endDate&isActive&{employeeState:int}',
        templateUrl: require('./corporate/views/employeesReport/index.html'),
        dynamic: true,
        params: {
          ticketType: {
            value: null,
          },
          employeeType: {
            value: null,
          },
          clinicType: {
            value: null,
          },
          serviceType: {
            value: null,
          },
          financialYear: {
            value: null,
          },
          ticketCountMin: {
            value: null,
          },
          ticketCountMax: {
            value: null,
          },
          mcTakenMin: {
            value: null,
          },
          mcTakenMax: {
            value: null,
          },
          coveredAmountMin: {
            value: null,
          },
          coveredAmountMax: {
            value: null,
          },
          employeePortionMin: {
            value: null,
          },
          employeePortionMax: {
            value: null,
          },
          subsidiary: {
            value: null,
          },
          department: {
            array: true,
            value: null,
          },
          isActive: {
            value: null,
          },
          employeeState: {
            value: null,
          },
          startDate: {
            value: null,
          },
          endDate: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('Corporate.Reports.EmployeeTicketsReport')) {
      $stateProvider.state('corporate.employeeTicketsReport', {
        url: '/corporate/employeeTicketsReport?{ticketType:int}&{employeeType:int}&employee&nationalId&employeeNumber&{clinicType:int}&{serviceType:int}&{clinicLocation:int}&benefitGroup&{subsidiary:int}&{department:int}&{financialYear:int}&startDate&endDate&treatment&scope',
        templateUrl: require('./corporate/views/employeeTicketsReport/index.html'),
        dynamic: true,
        params: {
          ticketType: {
            value: null,
          },
          employeeType: {
            value: null,
          },
          clinicType: {
            value: null,
          },
          serviceType: {
            value: null,
          },
          clinicLocation: {
            value: null,
          },
          financialYear: {
            value: null,
          },
          scope: {
            value: '',
          },
          subsidiary: {
            value: null,
          },
          department: {
            array: true,
            value: null,
          },
          startDate: {
            value: null,
          },
          endDate: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('Corporate.Reports.DepartmentsReport')) {
      $stateProvider.state('corporate.departmentsReport', {
        url: '/corporate/departmentsReport?{ticketType:int}&{employeeType:int}&{clinicType:int}&{serviceType:int}&{clinicLocation:int}&benefitGroup&{subsidiary:int}&{department:int}&{financialYear:int}&startDate&endDate&transactionNumber',
        templateUrl: require('./corporate/views/departmentsReport/index.html'),
        dynamic: true,
        params: {
          ticketType: {
            value: null,
          },
          employeeType: {
            value: null,
          },
          clinicType: {
            value: null,
          },
          serviceType: {
            value: null,
          },
          clinicLocation: {
            value: null,
          },
          subsidiary: {
            value: null,
          },
          department: {
            array: true,
            value: null,
          },
          financialYear: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('Corporate.Reports.TreatmentsReport')) {
      $stateProvider.state('corporate.treatmentsReport', {
        url:
          '/corporate/treatmentsReport?{ticketType:int}&{employeeType:int}&{clinicLocation:int}&treatment&employee&nationalId' +
          '&employeeNumber&benefitGroup&{subsidiary:int}&{clinicType:int}&{serviceType:int}&{department:int}&{financialYear:int}&startDate&endDate',
        templateUrl: require('./corporate/views/treatmentsReport/index.html'),
        dynamic: true,
        params: {
          ticketType: {
            value: null,
          },
          employeeType: {
            value: null,
          },
          clinicType: {
            value: null,
          },
          serviceType: {
            value: null,
          },
          clinicLocation: {
            value: null,
          },
          subsidiary: {
            value: null,
          },
          department: {
            array: true,
            value: null,
          },
          financialYear: {
            value: null,
          },
          startDate: {
            value: null,
          },
          endDate: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('Corporate.Reports.McDaysReport')) {
      $stateProvider.state('corporate.mcDaysReport', {
        url:
          '/corporate/mcDaysReport?{ticketType:int}&{employeeType:int}&{type:int}&{leaveType:int}&{clinicLocation:int}&employee&nationalId' +
          '&employeeNumber&benefitGroup&{subsidiary:int}&{serviceType:int}&{department:int}&{financialYear:int}&startDate&endDate',
        templateUrl: require('./corporate/views/mcDaysReport/index.html'),
        dynamic: true,
        params: {
          ticketType: {
            value: null,
          },
          employeeType: {
            value: null,
          },
          type: {
            value: 0,
          },
          leaveType: {
            value: 0,
          },
          clinicLocation: {
            value: null,
          },
          serviceType: {
            value: null,
          },
          financialYear: {
            value: null,
          },
          subsidiary: {
            value: null,
          },
          department: {
            array: true,
            value: null,
          },
          startDate: {
            value: null,
          },
          endDate: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('Corporate.Reports.AnnualUtilizationReport')) {
      $stateProvider.state('corporate.annualUtilizationReport', {
        url: '/corporate/annualUtilizationReport?{financialYear:int}&startDate&endDate&{employeeType:int}&employee&nationalId&employeeNumber&{benefitGroupIds:int}&{subsidiaryIds:int}&{departmentIds:int}&isActive&{employeeState:int}',
        templateUrl: require('./corporate/views/annualUtilizationReport/index.html'),
        dynamic: true,
        params: {
          financialYear: {
            value: null,
          },
          employeeType: {
            value: null,
          },
          benefitGroupIds: {
            array: true,
            value: null,
          },
          subsidiaryIds: {
            array: true,
            value: null,
          },
          departmentIds: {
            array: true,
            value: null,
          },
          isActive: {
            value: null,
          },
          employeeState: {
            value: null,
          },
          startDate: {
            value: null,
          },
          endDate: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('Corporate.Reports.EmployeeMovementReport')) {
      $stateProvider.state('corporate.employeeMovementReport', {
        url: '/corporate/employeeMovementReport?{financialYear:int}&startDate&endDate',
        templateUrl: require('./corporate/views/employeeMovementReport/index.html'),
        dynamic: true,
        params: {
          financialYear: {
            value: null,
          },
          startDate: {
            value: null,
          },
          endDate: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('Clinic.Reports.CheckInReport')) {
      $stateProvider.state('clinic.checkInReport', {
        url: '/clinic/checkInReport?filter&sorting&{skipCount:int}&{maxResultCount:int}&startDate&endDate&{clinicLocationId:int}&{status:int}',
        templateUrl: require('./common/views/checkInReport/index.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
          clinicLocationId: {
            value: null,
          },
          status: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('Corporate.Reports.CheckInReport')) {
      $stateProvider.state('corporate.checkInReport', {
        url: '/corporate/checkInReport?filter&sorting&{skipCount:int}&{maxResultCount:int}&startDate&endDate&status',
        templateUrl: require('./common/views/checkInReport/index.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
          status: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('Host.Reports.CheckInReport')) {
      $stateProvider.state('host.checkInReport', {
        url: '/host/checkInReport?filter&sorting&{skipCount:int}&{maxResultCount:int}&startDate&endDate&status',
        templateUrl: require('./common/views/checkInReport/index.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
          status: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('Host.Reports.AgingReport')) {
      $stateProvider.state('host.agingReport', {
        url: '/host/agingReport?companyType&minimumAging',
        templateUrl: require('./host/views/agingReport/index.html'),
        dynamic: true,
      });
    }

    if (abp.auth.isGranted('Host.Reports.EscrowReport')) {
      $stateProvider.state('host.escrowReport', {
        url: '/host/escrowReport?{minBalancePercentage:int}&{maxBalancePercentage:int}',
        templateUrl: require('./host/views/escrowReport/index.html'),
        dynamic: true,
        params: {
          minBalancePercentage: {
            value: 0,
          },
          maxBalancePercentage: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('Host.Reports.DailySalesStatistic')) {
      $stateProvider.state('host.dailySalesStatistic', {
        url: '/host/dailySalesStatistic?creationDate&{filter}&sorting&{skipCount:int}&{maxResultCount:int}',
        templateUrl: require('./host/views/dailySalesStatistic/index.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
          creationDate: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('Host.Employees')) {
      $stateProvider.state('host.employees', {
        url: '/host/employees?filter&{includeRelated:bool}&checkInDate',
        templateUrl: require('./host/views/employees/employees.page.html'),
        dynamic: true,
        params: {
          includeRelated: {
            value: false,
          },
          checkInDate: {
            value: null,
          },
        },
      });

      $stateProvider.state('host.employeeHistory', {
        url: '/host/employeeHistory/:id?name',
        templateUrl: require('./host/views/employees/viewHistory.html'),
      });
    }

    if (abp.auth.isGranted('Host.PublicUsers')) {
      $stateProvider.state('host.publicUsers', {
        url: '/host/publicUsers?filter&sorting&{skipCount:int}&{maxResultCount:int}',
        templateUrl: require('./host/views/publicUsers/index.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });
      $stateProvider.state('host.notificationLogs', {
        url: '/host/publicUsers/notificationLogs/:userId?sorting&{skipCount:int}&{maxResultCount:int}',
        templateUrl: require('./host/views/publicUsers/notificationLogs/notificationLogs.page.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });
    }

    if (abp.auth.isGranted('Host.MasterOperators')) {
      $stateProvider.state('host.masterOperators', {
        url: '/host/masterOperators',
        templateUrl: require('./host/views/masterOperators/masterOperators.page.html'),
      });

      if (
        abp.auth.isGranted('Host.MasterOperators.Create') ||
        abp.auth.isGranted('Host.MasterOperators.Edit')
      ) {
        $stateProvider.state('host.createEditMasterOperator', {
          url: '/host/masterOperators/createEditMasterOperator/:masterOperatorId',
          templateUrl: require('./host/views/masterOperators/createEditMasterOperator/createEditMasterOperator.page.html'),
          params: {
            masterOperatorId: {
              value: null,
            },
          },
        });
      }
    }

    if (abp.auth.isGranted('Host.Drugs')) {
      $stateProvider.state('host.drugs', {
        url: '/host/drugs?sorting&productName&activeIngredients&{type:int}&manufacturer&{mimsClass:int}&{skipCount:int}&{maxResultCount:int}',
        templateUrl: require('./host/views/drugs/index.html'),
        dynamic: true,
        params: {
          productName: {
            value: null,
          },
          activeIngredients: {
            value: null,
          },
          mimsClass: {
            value: null,
          },
          type: {
            value: null,
          },
          manufacturer: {
            value: null,
          },
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });

      if (abp.auth.isGranted('Host.Drugs.Create') || abp.auth.isGranted('Host.Drug.Edit')) {
        $stateProvider.state('createEditDrug', {
          url: '/host/drugs/createEdit/:drugId',
          templateUrl: require('./host/views/drugs/createEdit.html'),
          params: {
            drugId: '',
          },
        });
      }
    }

    if (abp.auth.isGranted('Host.SmsServiceLogs')) {
      $stateProvider.state('host.smsLogs', {
        url: '/host/smsLogs?startDate&endDate',
        templateUrl: require('./host/views/smsLogs/index.html'),
        dynamic: true,
        params: {
          startDate: {
            value: null,
          },
          endDate: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('Host.DebugTools')) {
      $stateProvider.state('host.debugTools', {
        url: '/host/debugTools',
        templateUrl: require('./host/views/debugTools/index.html'),
      });

      $stateProvider.state('host.clearCache', {
        url: '/host/clearCache',
        templateUrl: require('./host/views/debugTools/clearCache/index.html'),
      });

      $stateProvider.state('host.smsSender', {
        url: '/host/smsSender',
        templateUrl: require('./host/views/debugTools/smsSender/index.html'),
      });

      if (abp.auth.isGranted('HealthMetrics.Mailing')) {
        $stateProvider.state('host.emailTester', {
          url: '/host/emailTester',
          templateUrl: require('./host/views/debugTools/emailTester/index.html'),
        });
      }

      $stateProvider.state('host.dataExportTester', {
        url: '/host/dataExportTester',
        templateUrl: require('./host/views/debugTools/dataExportTester/index.html'),
      });
    }

    if (abp.auth.isGranted('Host.UserAgreementVersions')) {
      $stateProvider.state('host.userAgreements', {
        url: '/host/userAgreements',
        templateUrl: require('./host/views/userAgreements/userAgreementList.page.html'),
        dynamic: true,
        params: {
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });

      if (abp.auth.isGranted('Host.UserAgreementVersions.CreateAndEdit')) {
        $stateProvider.state('createOrEditUserAgreement', {
          url: '/host/userAgreements/createEdit/:versionId',
          templateUrl: require('./host/views/userAgreements/editUserAgreement.page.html'),
          params: {
            versionId: '',
          },
        });
      }
    }

    if (abp.auth.isGranted('Host.PublicHolidays')) {
      $stateProvider.state('host.publicHolidays', {
        url: '/host/publicHolidays?filter&{year:int}&states&{skipCount:int}&{maxResultCount:int}&startDate&endDate',
        templateUrl: require('./host/views/publicHolidays/publicHolidays.page.html'),
        dynamic: true,
        params: {
          year: {
            value: null,
          },
          startDate: {
            value: null,
          },
          endDate: {
            value: null,
          },
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });
    }

    if (abp.auth.isGranted('Host.DiseaseClassifications')) {
      $stateProvider.state('host.diseaseClassifications', {
        url: '/host/diseaseClassifications',
        templateUrl: require('./host/views/diseaseClassifications/diseaseClassifications.page.html'),
      });
    }

    /* Hospital homepage */

    if (App.isHospital() && (App.isInpatient() || App.isHealthScreening())) {
      $stateProvider.state('clinic.hospitalHomepage', {
        url: '/clinic/hospital/homepage',
        templateUrl: require('./clinic/views/hospital/homepage/homepage.page.html'),
      });
      $urlRouterProvider.otherwise('/clinic/hospital/homepage');
    }

    /* Inpatient Module */

    if (abp.setting.getBoolean('Hms.Feature.InpatientModule')) {
      /* Hospital side */

      if (App.isHospital() && App.isInpatient()) {
        /* Hospital inpatient dashboard */

        if (abp.auth.isGranted('Dashboard.Inpatient.Hospital')) {
          $stateProvider.state('clinic.inpatientDashboard', {
            url: '/clinic/hospital/inpatientDashboard?filter&{admissionType:int}&glNumber&admissionNumber',
            templateUrl: require('./clinic/views/hospital/inpatientDashboard/inpatientDashboard.page.html'),
            dynamic: true,
            params: {
              admissionType: {
                value: null,
              },
            },
          });
        }

        /* Inpatient admmission details */

        $stateProvider.state('clinic.patientAdmissionDetails', {
          url: '/clinic/patients/admissionDetails?letterNumber',
          templateUrl: require('./clinic/views/patient/admissionDetails/admissionDetails.page.html'),
          params: {
            letterNumber: {
              value: null,
            },
          },
        });

        /* Inpatient request igl */

        $stateProvider.state('clinic.iglRequest', {
          url: '/clinic/hospital/requestIgl?{employeeId:int}&{panelLocationId:int}&requestId',
          templateUrl: require('./clinic/views/hospital/requestIgl/requestIgl.page.html'),
          params: {
            employeeId: {
              value: null,
            },
            panelLocationId: {
              value: null,
            },
            requestId: {
              value: null,
            },
          },
        });

        /* Inpatient request top up */

        $stateProvider.state('clinic.topUpRequest', {
          url: '/clinic/hospital/topUpRequest/:letterNumber?&{panelLocationId:int}&{requestType:int}&requestId',
          templateUrl: require('./clinic/views/hospital/topUpRequest/topUpRequest.page.html'),
          params: {
            requestType: {
              value: null,
            },
            panelLocationId: {
              value: null,
            },
            requestId: {
              value: null,
            },
          },
        });

        /* Inpatient answer query */

        $stateProvider.state('clinic.hospitalQuery', {
          url: '/clinic/hospital/query?letterNumber&queryNumber&timeZoneId',
          templateUrl: require('./clinic/views/hospital/query/query.page.html'),
          params: {
            letterNumber: {
              value: null,
            },
            queryNumber: {
              value: null,
            },
          },
        });

        /* Inpatient request fgl */

        $stateProvider.state('clinic.fglRequest', {
          url: '/clinic/hospital/fglRequest/:letterNumber',
          templateUrl: require('./clinic/views/hospital/fglRequest/fglRequest.page.html'),
        });

        /* Inpatient GL listing */

        $stateProvider.state('clinic.inpatientGuaranteeLetters', {
          url:
            '/clinic/hospital/guaranteeLetters?{skipCount:int}&{maxResultCount:int}&filter&sorting&stateStatus' +
            '&patient&letterNumber&admissionType&attendingDoctorId&scheduledAdmissionStartDate&scheduledAdmissionEndDate',
          templateUrl: require('./clinic/views/hospital/guaranteeLetters/guaranteeLetters.page.html'),
          dynamic: true,
          params: {
            skipCount: {
              value: 0,
            },
            maxResultCount: {
              value: appConstants.grid.defaultPageSize,
            },
            filter: {
              value: null,
            },
            sorting: {
              value: null,
            },
            stateStatus: {
              value: null,
            },
            patient: {
              value: null,
            },
            letterNumber: {
              value: null,
            },
            latestRequestType: {
              value: null,
            },
            admissionType: {
              value: null,
            },
            attendingDoctorId: {
              value: null,
            },
            scheduledAdmissionStartDate: {
              value: null,
            },
            scheduledAdmissionEndDate: {
              value: null,
            },
          },
        });

        /* Inpatient request adjustment */

        $stateProvider.state('clinic.adjustmentRequest', {
          url: '/clinic/hospital/adjustmentRequest?&letterNumber&claimNumber',
          templateUrl: require('./clinic/views/hospital/adjustmentRequest/adjustmentRequest.page.html'),
          params: {
            letterNumber: {
              value: null,
            },
            claimNumber: {
              value: null,
            },
          },
        });

        /* Inpatient patient search */

        $stateProvider.state('clinic.hospitalPatient', {
          url: '/clinic/hospital/patient?filterText',
          templateUrl: require('./clinic/views/hospital/patient/patient.page.html'),
          dynamic: true,
        });

        /* Inpatient claim listing */

        $stateProvider.state('clinic.inpatientClaims', {
          url:
            '/clinic/hospital/inpatientClaims?sorting&{skipCount:int}&{maxResultCount:int}&status&letterNumber&transactionNumber' +
            '&claimNumber&billingStatus&submissionStartDate&submissionEndDate',
          templateUrl: require('./clinic/views/hospital/inpatientClaims/inpatientClaims.page.html'),
          dynamic: true,
          params: {
            skipCount: {
              value: 0,
            },
            maxResultCount: {
              value: appConstants.grid.defaultPageSize,
            },
            status: {
              value: null,
            },
            letterNumber: {
              value: null,
            },
            transactionNumber: {
              value: null,
            },
            claimNumber: {
              value: null,
            },
            billingStatus: {
              value: null,
            },
            submissionStartDate: {
              value: null,
            },
            submissionEndDate: {
              value: null,
            },
          },
        });

        /* Inpatient claim details */

        $stateProvider.state('clinic.inpatientClaimDetails', {
          url: '/clinic/hospital/inpatientClaims/details/:claimNumber?timeZoneId',
          templateUrl: require('./clinic/views/hospital/inpatientClaims/details/details.page.html'),
        });

        /* Inpatient forms listing */

        $stateProvider.state('clinic.inpatientForms', {
          url: '/clinic/hospital/forms',
          templateUrl: require('./clinic/views/hospital/forms/forms.page.html'),
        });

        /* Inpatient request submission preview */

        $stateProvider.state('clinic.previewIglSubmission', {
          url: '/clinic/hospital/previewSubmission/iglRequest?{employeeId:int}&{panelLocationId:int}&requestId',
          templateUrl: require('./clinic/views/patient/previewSubmission/iglRequest/iglRequest.page.html'),
          params: {
            employeeId: {
              value: null,
            },
            panelLocationId: {
              value: null,
            },
            requestId: {
              value: null,
            },
          },
        });

        /* Inpatient topupAddOn request submission preview */

        $stateProvider.state('clinic.previewTopupAddOnSubmission', {
          url: '/clinic/hospital/previewSubmission/topupAddOn?{employeeId:int}&{panelLocationId:int}&requestId',
          templateUrl: require('./clinic/views/patient/previewSubmission/topupAddOnRequest/topupAddOnRequest.page.html'),
          params: {
            employeeId: {
              value: null,
            },
            panelLocationId: {
              value: null,
            },
            requestId: {
              value: null,
            },
          },
        });

        /* Inpatient adjustment request submission preview */

        $stateProvider.state('clinic.previewAdjustmentSubmission', {
          url: '/clinic/hospital/previewSubmission/adjustmentRequest?{employeeId:int}&{panelLocationId:int}&requestId',
          templateUrl: require('./clinic/views/patient/previewSubmission/adjustmentRequest/adjustmentRequest.page.html'),
          params: {
            employeeId: {
              value: null,
            },
            panelLocationId: {
              value: null,
            },
            requestId: {
              value: null,
            },
          },
        });

        /* Inpatient final gl request submission preview */

        $stateProvider.state('clinic.previewFglSubmission', {
          url: '/clinic/hospital/previewSubmission/finalglRequest?{employeeId:int}&{panelLocationId:int}&requestId',
          templateUrl: require('./clinic/views/patient/previewSubmission/finalglRequest/finalglRequest.page.html'),
          params: {
            employeeId: {
              value: null,
            },
            panelLocationId: {
              value: null,
            },
            requestId: {
              value: null,
            },
          },
        });
      }

      /* Host side */

      /* Host process IGL */

      $stateProvider.state('host.iglRequest', {
        url: '/host/inpatientGuaranteeLetters/iglProcessing/:letterNumber?requestId&{memberId:int}',
        templateUrl: require('./host/views/inpatientGuaranteeLetters/iglProcessing/iglProcessing.page.html'),
        params: {
          letterNumber: {
            value: null,
          },
          requestId: {
            value: null,
          },
          memberId: {
            value: null,
          },
        },
      });

      /* Host process check-in GL */
      $stateProvider.state('host.checkInRequest', {
        url: '/host/inpatientGuaranteeLetters/checkInProcessing/:letterNumber?requestId&{memberId:int}',
        templateUrl: require('./host/views/inpatientGuaranteeLetters/checkInProcessing/checkInProcessing.page.html'),
        params: {
          letterNumber: {
            value: null,
          },
          requestId: {
            value: null,
          },
          memberId: {
            value: null,
          },
        },
      });

      /* Host process fgl */

      $stateProvider.state('host.fglRequest', {
        url: '/host/claims/inpatientClaims/:letterNumber?claimNumber&timeZoneId',
        templateUrl: require('./host/views/inpatientClaims/fglProcessing/fglProcessing.page.html'),
        params: {
          claimNumber: {
            value: null,
          },
        },
      });

      /* Host process top up */

      $stateProvider.state('host.topupAddonRequest', {
        url: '/host/inpatientGuaranteeLetters/topupAddonProcessing/:letterNumber?requestId&{memberId:int}',
        templateUrl: require('./host/views/inpatientGuaranteeLetters/topupAddonProcessing/topupAddonProcessing.page.html'),
        params: {
          requestId: {
            value: null,
          },
          memberId: {
            value: null,
          },
        },
      });

      /* Host process adjustment */

      $stateProvider.state('host.adjustmentRequest', {
        url: '/host/claims/inpatientClaims/adjustmentRequest/:letterNumber?claimNumber&timeZoneId',
        templateUrl: require('./host/views/inpatientClaims/adjustmentRequest/adjustmentRequest.page.html'),
        params: {
          letterNumber: {
            value: null,
          },
          claimNumber: {
            value: null,
          },
        },
      });

      /* Host create inpatient query */

      $stateProvider.state('createQuery', {
        url: '/host/query/createQuery/:letterNumber?claimNumber&timeZoneId',
        templateUrl: require('./host/views/inpatientGuaranteeLetters/details/query/createQuery/createQuery.page.html'),
        params: {
          claimNumber: {
            value: null,
          },
        },
      });

      /* host view inpatient query */

      $stateProvider.state('viewQuery', {
        url: '/host/query/viewQuery?letterNumber&queryNumber&claimNumber&timeZoneId',
        templateUrl: require('./host/views/inpatientGuaranteeLetters/details/query/viewQuery/viewQuery.page.html'),
        params: {
          letterNumber: {
            value: null,
          },
          queryNumber: {
            value: null,
          },
          claimNumber: {
            value: null,
          },
        },
      });

      /* Host claim details */

      $stateProvider.state('host.inpatientClaimDetail', {
        url: '/host/inpatientClaims/details/:claimNumber?timeZoneId?serviceType',
        templateUrl: require('./host/views/inpatientClaims/details/details.page.html'),
      });

      /* Host claims listing */

      $stateProvider.state('host.inpatientClaims', {
        url:
          '/host/inpatientClaims?{skipCount:int}&{maxResultCount:int}&claimNumber&employee&status&ticketNumber&{organizationId:int}' +
          '&{subsidiaryId:int}&panelType&submissionStartDate&submissionEndDate&transactionNumber&letterNumber&processor&billingStatus' +
          '&masterPolicy&insurer&{coveredBy:int}',
        templateUrl: require('./host/views/inpatientClaims/inpatientClaims.page.html'),
        dynamic: true,
        params: {
          status: {
            value: null,
          },
          claimNumber: {
            value: null,
          },
          ticketNumber: {
            value: null,
          },
          transactionNumber: {
            value: null,
          },
          letterNumber: {
            value: null,
          },
          submissionStartDate: {
            value: null,
          },
          submissionEndDate: {
            value: null,
          },
          employee: {
            value: null,
          },
          organizationId: {
            value: null,
          },
          subsidiaryId: {
            value: null,
          },
          panelType: {
            value: null,
          },
          processor: {
            value: null,
          },
          billingStatus: {
            value: null,
          },
          insurer: {
            value: null,
          },
          coveredBy: {
            value: null,
          },
          masterPolicy: {
            value: null,
          },
          skipCount: {
            value: 0,
          },
          maxResultCount: {
            value: appConstants.grid.defaultPageSize,
          },
        },
      });

      /* Host GL */

      if (abp.auth.isGranted('GuaranteeLetters.Inpatient.Host')) {
        $stateProvider.state('host.inpatientGuaranteeLetters', {
          url:
            '/host/inpatientGuaranteeLetters?sorting&{skipCount:int}}&{maxResultCount:int}&letterNumber&claimNumber&ticketNumber&serviceType' +
            '&status&{panelLocation:int}&processorId&patient&subsidiary&latestRequestType&latestRequestStatus&queryStatus' +
            '&approvalStartDate&approvalEndDate&scheduledAdmissionStartDate&scheduledAdmissionEndDate&checkedInStartDate&checkedInEndDate&insurerId&isCoveredByInsurance&masterPolicyNumber',
          templateUrl: require('./host/views/inpatientGuaranteeLetters/inpatientGuaranteeLetters.page.html'),
          dynamic: true,
          params: {
            skipCount: {
              value: 0,
            },
            maxResultCount: {
              value: appConstants.grid.defaultPageSize,
            },
            letterNumber: {
              value: null,
            },
            claimNumber: {
              value: null,
            },
            ticketNumber: {
              value: null,
            },
            serviceType: {
              value: null,
            },
            status: {
              value: null,
            },
            panelLocationId: {
              value: null,
            },
            processorId: {
              value: null,
            },
            patient: {
              value: null,
            },
            subsidiary: {
              value: null,
            },
            latestRequestType: {
              value: null,
            },
            latestRequestStatus: {
              value: null,
            },
            queryStatus: {
              value: null,
            },
            approvalStartDate: {
              value: null,
            },
            approvalEndDate: {
              value: null,
            },
            scheduledAdmissionStartDate: {
              value: null,
            },
            scheduledAdmissionEndDate: {
              value: null,
            },
            checkedInStartDate: {
              value: null,
            },
            checkedInEndDate: {
              value: null,
            },
            insurerId: {
              value: null,
            },
            isCoveredByInsurance: {
              value: null,
            },
            masterPolicyNumber: {
              value: null,
            },
          },
        });

        /* Host GL details */

        $stateProvider.state('inpatientGuaranteeLettersDetails', {
          url: '/host/inpatientGuaranteeLetters/details/:letterNumber?timeZoneId?serviceType',
          templateUrl: require('./host/views/inpatientGuaranteeLetters/details/details.page.html'),
          params: {
            letterNumber: {
              value: null,
            },
            timeZoneId: {
              value: null,
            },
            tab: {
              value: null,
            },
          },
        });
      }

      /* Corporate side */

      if (abp.features.isEnabled('HasCorporateModule')) {
        /* Corporate inpatient GL listing */

        $stateProvider.state('corporate.inpatientGuaranteeLetter', {
          url:
            '/corporate/guaranteeLetters/inpatient?sorting&{skipCount:int}&{maxResultCount:int}&letterNumber&claimNumber&ticketNumber' +
            '&status&{clinicLocation:int}&employee&company&approvalStartDate&approvalEndDate&appliedStartDate&appliedEndDate',
          templateUrl: require('./corporate/views/inpatientGuaranteeLetters/inpatientGuaranteeLetters.page.html'),
          dynamic: true,
          params: {
            skipCount: {
              value: 0,
            },
            maxResultCount: {
              value: appConstants.grid.defaultPageSize,
            },
            letterNumber: {
              value: null,
            },
            claimNumber: {
              value: null,
            },
            ticketNumber: {
              value: null,
            },
            status: {
              value: null,
            },
            clinicLocation: {
              value: null,
            },
            employee: {
              value: null,
            },
            company: {
              value: null,
            },
            approvalStartDate: {
              value: null,
            },
            approvalEndDate: {
              value: null,
            },
            appliedStartDate: {
              value: null,
            },
            appliedEndDate: {
              value: null,
            },
          },
        });

        /* Corporate inpatient benefit group */

        $stateProvider.state('corporate.inpatientBenefitGroup', {
          url: '/corporate/benefitGroups/inpatient',
          templateUrl: require('./corporate/views/benefitGroups/inpatient/inpatient.page.html'),
        });

        /* Corporate inpatient BG details */

        $stateProvider.state('corporate.inpatientBenefitGroupDetail', {
          url: '/corporate/benefitGroups/inpatient/benefitDetail/:inpatientBenefitGroupId/:serviceType',
          templateUrl: require('./corporate/views/benefitGroups/inpatient/benefitDetail/benefitDetail.page.html'),
          params: {
            inpatientBenefitGroupId: {
              value: null,
            },
            serviceType: {
              value: null,
            },
          },
        });
      }

      /* Common */

      /* Inpatient ticket */

      $stateProvider.state('inpatientTicketDetail', {
        url: '/common/inpatientTickets/detail/:ticketNumber',
        templateUrl: require('./common/views/inpatientTickets/detail/detail.page.html'),
      });

      $stateProvider.state('inpatientTicketAuditHistory', {
        url: '/common/inpatientTickets/detail/auditHistory/:ticketNumber',
        templateUrl: require('./common/views/inpatientTickets/detail/auditHistory/auditHistory.page.html'),
      });

      /* Doraemon */
      if (abp.auth.isGranted('GuaranteeLetter.Doraemon.Host')) {
        $stateProvider.state('host.doraemonGl', {
          url:
            '/host/doraemon/gl?sorting&{skipCount:int}&{maxResultCount:int}&employee&letterNumber&claimNumber&ticketNumber' +
            '&status&issueNumber&requestType&{panelLocation:int}&subsidiary&approvalStartDate&approvalEndDate&scheduledAdmissionStartDate' +
            '&scheduledAdmissionEndDate&checkedInStartDate&checkedInEndDate&insurerId&isCoveredByInsurance&masterPolicyNumber',
          templateUrl: require('./host/views/doraemon/gl/gl.page.html'),
          dynamic: true,
          params: {
            skipCount: {
              value: 0,
            },
            maxResultCount: {
              value: appConstants.grid.defaultPageSize,
            },
            employee: {
              value: null,
            },
            letterNumber: {
              value: null,
            },
            claimNumber: {
              value: null,
            },
            ticketNumber: {
              value: null,
            },
            status: {
              value: null,
            },
            issueNumber: {
              value: null,
            },
            requestType: {
              value: null,
            },
            panelLocation: {
              value: null,
            },
            subsidiary: {
              value: null,
            },
            approvalStartDate: {
              value: null,
            },
            approvalEndDate: {
              value: null,
            },
            scheduledAdmissionStartDate: {
              value: null,
            },
            scheduledAdmissionEndDate: {
              value: null,
            },
            checkedInStartDate: {
              value: null,
            },
            checkedInEndDate: {
              value: null,
            },
            insurerId: {
              value: null,
            },
            isCoveredByInsurance: {
              value: null,
            },
            masterPolicyNumber: {
              value: null,
            },
          },
        });

        $stateProvider.state('host.doraemonViewQuery', {
          url: '/host/doraemon/query/viewQuery/:letterNumber/:queryNumber',
          templateUrl: require('./host/views/doraemon/query/viewQuery/viewQuery.page.html'),
          params: {
            letterNumber: {
              value: null,
            },
            queryNumber: {
              value: null,
            },
          },
        });

        $stateProvider.state('host.doraemonGlDetails', {
          url: '/host/doraemon/glDetails/:letterNumber',
          templateUrl: require('./host/views/doraemon/glDetails/glDetails.page.html'),
        });
      }
    }

    if (abp.auth.isGranted('Host.FormManagement')) {
      $stateProvider.state('host.form', {
        url: '/host/forms',
        templateUrl: require('./host/views/forms/forms.page.html'),
      });

      $stateProvider.state('host.formDetails', {
        url: '/host/forms/details/formDetails?formTemplateId',
        templateUrl: require('./host/views/forms/details/formDetails.page.html'),
        params: {
          formTemplateId: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('Host.MasterLicensingFees')) {
      $stateProvider.state('host.licensingFees', {
        url: '/host/licensingFees',
        templateUrl: require('./host/views/licensingFees/licensingFees.page.html'),
      });
    }

    if (abp.auth.isGranted('Host.Clinics.LicensingFees')) {
      $stateProvider.state('host.panelLicensingFees', {
        url: '/host/panelLicensingFees?{panelId:int}',
        templateUrl: require('./host/views/clinics/panelLicensingFees/panelLicensingFees.page.html'),
        params: {
          panelId: {
            value: null,
          },
        },
      });
    }

    if (
      abp.auth.isGranted('Host.Clinics.LicensingFees') ||
      abp.auth.isGranted('Host.MasterLicensingFees')
    ) {
      $stateProvider.state('host.licensingFeeDetails', {
        url: '/host/licensingFees/details?countryCode&{serviceType:int}&{panelId:int}',
        templateUrl: require('./host/views/licensingFees/details/licensingFeeDetails.page.html'),
        params: {
          serviceType: {
            value: null,
          },
          countryCode: {
            value: null,
          },
          panelId: {
            value: null,
          },
        },
      });
    }

    if (
      abp.auth.isGranted('Host.Clinics.LicensingFees.Edit') ||
      abp.auth.isGranted('Host.MasterLicensingFees.Edit')
    ) {
      $stateProvider.state('host.createEditLicensingFee', {
        url: '/host/licensingFees/createEdit?licensingFeeId&countryCode&{serviceType:int}&{panelId:int}',
        templateUrl: require('./host/views/licensingFees/createEdit/createEditLicensingFee.page.html'),
        params: {
          licensingFeeId: {
            value: null,
          },
          serviceType: {
            value: null,
          },
          countryCode: {
            value: null,
          },
          panelId: {
            value: null,
          },
        },
      });
    }

    /* Host inpatient treatment categories */

    if (
      abp.setting.getBoolean('Hms.Feature.InsuranceEnhancementModule') &&
      abp.auth.isGranted('Host.InpatientTreatmentCategories')
    ) {
      $stateProvider.state('host.inpatientTreatmentCategories', {
        url: '/host/inpatientTreatmentCategory',
        templateUrl: require('./host/views/inpatientTreatmentCategories/inpatientTreatmentCategories.page.html'),
      });
    }

    // #region Health Screening Module

    if (abp.setting.getBoolean('Hms.Feature.HealthScreeningModule')) {
      // #region Panel related

      if (abp.features.isEnabled('HasHealthScreeningService')) {
        if (abp.auth.isGranted('Dashboard.HealthScreening.Panel')) {
          /* Health screening dashboard */

          $stateProvider.state('clinic.healthScreeningDashboard', {
            url: '/clinic/healthScreeningDashboard',
            templateUrl: require('./clinic/views/healthScreening/dashboard/healthScreeningDashboard.page.html'),
          });
        }

        if (abp.auth.isGranted('Clinic.Employees')) {
          /* Health screening check-in page */

          $stateProvider.state('clinic.healthScreeningCheckIn', {
            url: '/clinic/healthScreeningCheckIns?filterText',
            templateUrl: require('./clinic/views/healthScreening/checkIn/healthScreeningCheckIn.page.html'),
            dynamic: true,
          });
        }

        if (
          abp.auth.isGranted('Claims.HealthScreening.Panel.Create') ||
          abp.auth.isGranted('PatientTickets.Clinic.Create')
        ) {
          /* Health screening submit final type request (a.k.a claim) */

          $stateProvider.state('createEditHealthScreeningFinalRequest', {
            url: '/common/healthScreeningClaims/finalRequest/:claimNumber?letterNumber&timeZoneId',
            templateUrl: require('./common/views/healthScreening/finalRequest/finalRequest.page.html'),
            params: {
              letterNumber: {
                value: null,
              },
              claimNumber: {
                value: null,
              },
            },
          });
        }

        if (abp.auth.isGranted('Claims.HealthScreening.Panel')) {
          /* Health screening query reply */

          $stateProvider.state('clinic.replyHealthScreeningQuery', {
            url: '/clinic/healthScreeningQuery/query?claimNumber&queryNumber&timeZoneId',
            templateUrl: require('./clinic/views/outpatientQuery/query.page.html'),
            params: {
              letterNumber: {
                value: null,
              },
              claimNumber: {
                value: null,
              },
              queryNumber: {
                value: null,
              },
            },
          });
        }
      }

      // #endregion Panel related

      // #region Host related

      if (App.isHost() && abp.auth.isGranted('Claims.HealthScreening.Host')) {
        if (abp.auth.isGranted('Claims.HealthScreening.Host.Edit')) {
          /* Health screening final type request processing (a.k.a claim processing) */

          $stateProvider.state('processFinalHealthScreeningRequest', {
            url: '/host/claims/healthScreening/processFinalRequest/:claimNumber?letterNumber&timeZoneId',
            templateUrl: require('./host/views/healthScreeningClaims/processFinalRequest/process.page.html'),
            params: {
              letterNumber: {
                value: null,
              },
            },
          });
        }

        /* Health screening query creation */

        $stateProvider.state('createHealthScreeningQuery', {
          url: '/host/healthScreeningQuery/createQuery?letterNumber&claimNumber&timeZoneId',
          templateUrl: require('./common/views/components/outpatientClaimQuery/createQuery/createQuery.page.html'),
          params: {
            letterNumber: {
              value: null,
            },
            claimNumber: {
              value: null,
            },
          },
        });
      }

      // #endregion Host related

      // #region Commons
      if (
        abp.auth.isGranted('GuaranteeLetters.HealthScreening.Panel') ||
        abp.auth.isGranted('GuaranteeLetters.HealthScreening.Host')
      ) {
        /* Health screening guarantee letter listing */

        $stateProvider.state('healthScreeningGuaranteeLetters', {
          url:
            '/common/guaranteeLetters/healthScreening?sorting&{skipCount:int}&{maxResultCount:int}&filter' +
            '&status&{panelLocation:int}&checkedInStartDate&checkedInEndDate&letterNumber&claimNumber' +
            '&ticketNumber&company',
          templateUrl: require('./common/views/healthScreening/guaranteeLetters/guaranteeLetters.page.html'),
          dynamic: true,
          params: {
            skipCount: {
              value: 0,
            },
            maxResultCount: {
              value: appConstants.grid.defaultPageSize,
            },
            filter: {
              value: null,
            },
            letterNumber: {
              value: null,
            },
            claimNumber: {
              value: null,
            },
            ticketNumber: {
              value: null,
            },
            company: {
              value: null,
            },
            status: {
              value: null,
            },
            panelLocation: {
              value: null,
            },
            checkedInStartDate: {
              value: null,
            },
            checkedInEndDate: {
              value: null,
            },
          },
        });

        /* Health screening guarantee letter details */

        $stateProvider.state('healthScreeningGuaranteeLetterDetail', {
          url: '/common/guaranteeLetters/healthScreening/guaranteeLetter/:letterNumber?timeZoneId',
          templateUrl: require('./common/views/healthScreening/guaranteeLetters/details/details.page.html'),
        });
      }

      if (
        abp.auth.isGranted('Claims.HealthScreening.Panel') ||
        abp.auth.isGranted('Claims.HealthScreening.Host')
      ) {
        /* Health screening guarantee final type request listing (a.k.a claim listing) */

        $stateProvider.state('healthScreeningClaims', {
          url: '/common/healthScreeningClaims',
          templateUrl: require('./common/views/healthScreening/claims/healthScreeningClaims.page.html'),
          dynamic: true,
        });

        /* Health screening guarantee final type request details (a.k.a claim details) */

        $stateProvider.state('healthScreeningClaimDetail', {
          url: '/common/claims/healthScreening/claim/:claimNumber?timeZoneId',
          templateUrl: require('./common/views/healthScreening/claims/details/details.page.html'),
          params: {
            activeTab: {
              value: 1,
            },
          },
        });

        /* Health screening query view */

        $stateProvider.state('viewHealthScreeningQuery', {
          url: '/common/query/viewHealthScreeningQuery?claimNumber&queryNumber&timeZoneId',
          templateUrl: require('./common/views/components/outpatientClaimQuery/viewQuery/viewQuery.page.html'),
          params: {
            letterNumber: {
              value: null,
            },
            queryNumber: {
              value: null,
            },
            claimNumber: {
              value: null,
            },
          },
        });
      }
      // #endregion Commons
    }

    // #endregion Health Screening Module

    // #region Post Hospitalization Module

    if (
      abp.setting.getBoolean('Hms.Feature.PostHospitalizationGuaranteeLetterWorkflow') ||
      abp.auth.isGranted('GuaranteeLetters.HealthScreening.Panel') ||
      abp.auth.isGranted('GuaranteeLetters.HealthScreening.Host')
    ) {
      /* Post-Hospitalization guarantee letter listing */

      $stateProvider.state('postHospitalizationGuaranteeLetters', {
        url: '/common/guaranteeLetters/postHospitalization',
        templateUrl: require('./common/views/postHospitalization/guaranteeLetters/guaranteeLetters.page.html'),
        dynamic: true,
        params: {},
      });

      /* Post hospitalization guarantee letter details */

        $stateProvider.state('postHospitalizationGuaranteeLetterDetail', {
          url: '/common/guaranteeLetters/postHospitalization/guaranteeLetter/:letterNumber?timeZoneId?serviceType',
          templateUrl: require('./common/views/postHospitalization/guaranteeLetters/details/index.page.html'),
          params: {
            letterNumber: {
              value: null,
            },
            timeZoneId: {
              value: null,
            },
            serviceType: {
              value: null,
            },
          },
        });

      /* View post-hospitalisation guarantee letter query */

      $stateProvider.state('viewPostHospitalizationQuery', {
        url: '/common/query/viewPostHospitalizationQuery?letterNumber&queryNumber&claimNumber&timeZoneId',
        templateUrl: require('./common/views/postHospitalization/guaranteeLetters/details/query/viewQuery/viewQuery.page.html'),
        params: {
          letterNumber: {
            value: null,
          },
          queryNumber: {
            value: null,
          },
          claimNumber: {
            value: null,
          },
        },
      });

      /* Create post-hospitalisation guarantee letter query */

      $stateProvider.state('createPostHospitalizationQuery', {
        url: '/host/query/createPostHospitalizationQuery/:letterNumber?claimNumber&timeZoneId',
        templateUrl: require('./common/views/postHospitalization/guaranteeLetters/details/query/createQuery/createQuery.page.html'),
        params: {
          claimNumber: {
            value: null,
          },
        },
      });

      $stateProvider.state('postHospitalizationIglProcess', {
        url: '/host/guaranteeLetters/postHospitalization/iglProcessing/:letterNumber?requestId&{memberId:int}',
        templateUrl: require('./common/views/postHospitalization/guaranteeLetters/processing/iglProcessing.page.html'),
        params: {
          letterNumber: {
            value: null,
          },
          requestId: {
            value: null,
          },
          memberId: {
            value: null,
          },
        },
      });

      /* Post-hospitalisation submit final guarantee letter request */

      $stateProvider.state('postHospitalizationFglRequest', {
        url: '/common/postHospitalization/claims/fglRequest/:claimNumber?letterNumber&{claimWithTopUp:bool}&timeZoneId',
        templateUrl: require('./common/views/postHospitalization/claims/postHospitalizationFglRequest/postHospitalizationFglRequest.page.html'),
        params: {
          claimNumber: {
            value: null,
          },
          letterNumber: {
            value: null,
          },
          claimWithTopUp: {
            value: null,
          },
        },
      });

      /* Post-hospitalisation claim details */

      $stateProvider.state('postHospitalizationClaimDetail', {
        url: '/postHospitalizationClaims/details/:claimNumber?timeZoneId?glNumber',
        templateUrl: require('./common/views/postHospitalization/claims/details/details.page.html'),
      });

      /* Host process phgl claim */

      $stateProvider.state('processPostHospitalizationClaim', {
        url: '/postHospitalizationClaims/processClaim/:claimNumber&timeZoneId',
        templateUrl: require('./common/views/postHospitalization/claims/processClaim/processClaim.page.html'),
      });

      /* Create & edit post-hospitalization ticket */

      $stateProvider.state('createEditPostHospitalizationTicket', {
        url:
          '/common/patientTickets/createEditPostHospitalizationTicket/:ticketNumber?{employeeId:int}&{clinicLocationId:int}' +
          '&{clinicType:int}&{ticketType:int}&{claimId:int}&{patientSessionId:int}&{serviceType:int}&{hasTaxAmount:bool}',
        templateUrl: require('./common/views/patientTickets/postHospitalizationTicket/createEditPostHospitalizationTicket.page.html'),
        dynamic: true,
        params: {
          employeeId: {
            value: null,
          },
          clinicType: {
            value: null,
          },
          clinicLocationId: {
            value: null,
          },
          claimId: {
            value: null,
          },
          patientSessionId: {
            value: null,
          },
          lateSubmissionReason: {
            value: null,
          },
          lateSubmissionRequestorName: {
            value: null,
          },
          serviceType: {
            value: null,
          },
          ticketNumber: {
            value: null,
          },
          ticketType: {
            value: null,
          },
        },
      });
    }

    // #endregion Post Hospitalization Module
  }

  function runApp($rootScope, $state, amMoment) {
    $rootScope.apiEndpoint = __API_ENDPOINT__;

    $rootScope.$state = $state;
    $rootScope.l = abp.localization.getSource('Hms');
    $rootScope.resolveAppPath = (relativeUri) => new URL(relativeUri, abp.appPath).toString();

    // eslint-disable-next-line no-console
    $rootScope.$on('$stateChangeError', console.log.bind(console));
    $rootScope.safeApply = safeApply;

    amMoment.changeLocale(abp.localization.currentCulture.name);

    function safeApply(n) {
      // `this` should be `$root` scope.
      const t = this.$root.$$phase;
      if (t === '$apply' || t === '$digest') {
        if (n && typeof n === 'function') n();
        else this.$apply(n);
      }
    }
  }
})();
