(function () {
    var componentId = 'hostClinicsPanelNotificationsEditorComponent';
    var app = angular.module('app');

    function controller($scope, clinicSvc, enumTenantClinicType, constsAbpConfig) {
        function init() {
        }

        var vm = this;
        vm.constsAbpConfig = constsAbpConfig;
        vm.loading = 0;
        vm.saving = 0;
        vm.isEditing = false;

        vm.enums = {};
        vm.enums.tenantClinicType = enumTenantClinicType;

        vm.$onInit = function () {
            vm.container.addWidget(this);
        };

        vm.isDirty = function () {
            return $scope.panelCreateEditNotificationsForm && $scope.panelCreateEditNotificationsForm.$dirty;
        };

        vm.setPristine = function () {
            $scope.panelCreateEditNotificationsForm.$setPristine();
        };

        // Force validation on this form.
        // Return true if valid, otherwise, false.

        vm.validateForm = function () {
            var form = $scope.panelCreateEditNotificationsForm;
            App.touchFormErrors(form);
            return form.$valid;
        };

        // Generate the payload from this form to send to ClinicAppService API.

        vm.getPayload = function () {
            return {
                notifications: vm.data
            };
        };

        // Ask container to save this widget.

        vm.save = function () {
            vm.container.saveWidget(vm);
        };

        init();
    }

    app.component(componentId, {
        require: {
            container: '^hostClinicsPanelEditorContainerComponent'
        },
        bindings: {
            data: '<',
            panelType: '<'
        },
        templateUrl: require('/App/host/views/clinics/panelEditor/panelNotificationsEditor/panelNotificationsEditor.component.html'),
        controller: ['$scope', 'abp.services.app.clinic', 'Hms.MultiTenancy.TenantClinicType', 'Abp.Configuration.Setting', controller],
        controllerAs: 'vm'
    });
})();
