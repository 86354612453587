(function () {
  var controllerId = 'clinic.views.specialistDashboard.index';
  var app = angular.module('app');

  app.controller(controllerId, [
    '$uibModal',
    '$interval',
    '$scope',
    'abp.services.app.specialistDashboard',
    'abp.services.app.commonLookup',
    function ($uibModal, $interval, $scope, specialistDashboardSvc, commonLookupSvc) {
      function init() {
        if (vm.applicationHasSpecialistWorkflowEnhancement) {
          refresh();

          var tickerInterval = $interval(tickerJob, 6000);
          $scope.$on('$destroy', function () {
            if (angular.isDefined(tickerInterval)) {
              $interval.cancel(tickerInterval);
              tickerInterval = undefined;
            }
          });

          var tickerCount = 0;
          function tickerJob() {
            if (!(++tickerCount % 10) && !vm.loading && !isSilentLoading) {
              refresh(true);
            }
          }
        }
        getTimeZoneId();
      }

      var vm = this;
      vm.loading = 0;
      var isSilentLoading = 0;
      vm.viewType = 'grid';
      vm.isInpatient = App.isInpatient();

      vm.applicationHasSpecialistWorkflowEnhancement = abp.setting.getBoolean(
        'Hms.Feature.SpecialistWorkflowEnhancement'
      );

      // Show more functions for each widget.

      vm.showMorePendingSubmission = showMorePendingSubmission;
      vm.showMoreOutstanding = showMoreOutstanding;
      vm.showMoreLateSubmission = showMoreLateSubmission;
      vm.showMoreGlPendingApproval = showMoreGlPendingApproval;
      vm.showMoreSubmitted = showMoreSubmitted;
      vm.showMoreRejected = showMoreRejected;

      // Skip counts for each widget to "show more".

      vm.pendingSubmissionSkipCount = 0;
      vm.outstandingSkipCount = 0;
      vm.lateSubmissionSkipCount = 0;
      vm.glPendingApprovalSkipCount = 0;
      vm.submittedSkipCount = 0;
      vm.rejectedSkipCount = 0;

      // Max count

      vm.maxResultCount = 5;

      // Widgets for tab view.

      vm.widgets = {
        pendingSubmission: {
          name: App.localize('PendingSubmission'),
          btnTarget: '#a',
          active: 0,
          totalCount: 0,
          isRed: true,
        },
        outstanding: {
          name: App.localize('Outstanding'),
          btnTarget: '#b',
          active: 1,
          totalCount: 0,
          isRed: true,
        },
        lateSubmission: {
          name: App.localize('LateSubmission'),
          btnTarget: '#c',
          active: 2,
          totalCount: 0,
          isRed: true,
        },
        glPendingApproval: {
          name: App.localize('GlPendingApproval'),
          btnId: 'btnSpecialistClaims',
          btnTarget: '#d',
          active: 3,
          totalCount: 0,
          isRed: false,
        },
        submitted: {
          name: App.localize('Submitted'),
          btnTarget: '#e',
          hasPermission: true,
          active: 4,
          totalCount: 0,
          isRed: false,
        },
        rejected: {
          name: App.localize('Rejected'),
          btnTarget: '#f',
          active: 5,
          totalCount: 0,
          isRed: false,
        },
      };

      function refresh(silentLoad) {
        vm.pendingSubmissionSkipCount = 0;
        vm.outstandingSkipCount = 0;
        vm.lateSubmissionSkipCount = 0;
        vm.glPendingApprovalSkipCount = 0;
        vm.submittedSkipCount = 0;
        vm.rejectedSkipCount = 0;

        if (vm.loading || isSilentLoading) return;

        // Pending submission

        if (silentLoad) isSilentLoading++;
        else vm.loading++;

        specialistDashboardSvc
          .getClaimsPendingSubmission(
            {
              skipCount: vm.pendingSubmissionSkipCount,
              maxResultCount: vm.maxResultCount,
            },
            {
              abpHandleError: false,
            }
          )
          .success(function (data) {
            vm.pendingSubmission = data.items;
            vm.pendingSubmissionTotalCount = data.totalCount;
            vm.widgets.pendingSubmission.totalCount = vm.pendingSubmissionTotalCount;
            vm.pendingSubmissionHasMore =
              vm.pendingSubmission.length < vm.pendingSubmissionTotalCount;
          })
          .finally(function () {
            if (silentLoad) isSilentLoading--;
            else vm.loading--;
          });

        // Outstanding

        if (silentLoad) isSilentLoading++;
        else vm.loading++;

        specialistDashboardSvc
          .getOutstandingClaimServiceRequests(
            {
              skipCount: vm.outstandingSkipCount,
              maxResultCount: vm.maxResultCount,
            },
            {
              abpHandleError: false,
            }
          )
          .success(function (data) {
            vm.outstanding = data.items;
            vm.outstandingTotalCount = data.totalCount;
            vm.widgets.outstanding.totalCount = vm.outstandingTotalCount;
            vm.outstandingHasMore = vm.outstanding.length < vm.outstandingTotalCount;
          })
          .finally(function () {
            if (silentLoad) isSilentLoading--;
            else vm.loading--;
          });

        // Late submission

        if (silentLoad) isSilentLoading++;
        else vm.loading++;

        specialistDashboardSvc
          .getClaimsPendingLateSubmission(
            {
              skipCount: vm.lateSubmissionSkipCount,
              maxResultCount: vm.maxResultCount,
            },
            {
              abpHandleError: false,
            }
          )
          .success(function (data) {
            vm.lateSubmission = data.items;
            vm.lateSubmissionTotalCount = data.totalCount;
            vm.widgets.lateSubmission.totalCount = vm.lateSubmissionTotalCount;
            vm.lateSubmissionHasMore = vm.lateSubmission.length < vm.lateSubmissionTotalCount;
          })
          .finally(function () {
            if (silentLoad) isSilentLoading--;
            else vm.loading--;
          });

        // GL pending approval

        if (silentLoad) isSilentLoading++;
        else vm.loading++;

        specialistDashboardSvc
          .getGuaranteeLettersPendingApproval(
            {
              skipCount: vm.glPendingApprovalSkipCount,
              maxResultCount: vm.maxResultCount,
            },
            {
              abpHandleError: false,
            }
          )
          .success(function (data) {
            vm.glPendingApproval = data.items;
            vm.glPendingApprovalTotalCount = data.totalCount;
            vm.widgets.glPendingApproval.totalCount = vm.glPendingApprovalTotalCount;
            vm.glPendingApprovalHasMore =
              vm.glPendingApproval.length < vm.glPendingApprovalTotalCount;
          })
          .finally(function () {
            if (silentLoad) isSilentLoading--;
            else vm.loading--;
          });

        // Submitted

        if (silentLoad) isSilentLoading++;
        else vm.loading++;

        specialistDashboardSvc
          .getPendingReviewClaimServiceRequests(
            {
              skipCount: vm.submittedSkipCount,
              maxResultCount: vm.maxResultCount,
            },
            {
              abpHandleError: false,
            }
          )
          .success(function (data) {
            vm.submitted = data.items;
            vm.submittedTotalCount = data.totalCount;
            vm.widgets.submitted.totalCount = vm.submittedTotalCount;
            vm.submittedHasMore = vm.submitted.length < vm.submittedTotalCount;
          })
          .finally(function () {
            if (silentLoad) isSilentLoading--;
            else vm.loading--;
          });

        // Rejected

        if (silentLoad) isSilentLoading++;
        else vm.loading++;

        specialistDashboardSvc
          .getRejectedClaimServiceRequests(
            {
              skipCount: vm.rejectedSkipCount,
              maxResultCount: vm.maxResultCount,
            },
            {
              abpHandleError: false,
            }
          )
          .success(function (data) {
            vm.rejected = data.items;
            vm.rejectedTotalCount = data.totalCount;
            vm.widgets.rejected.totalCount = vm.rejectedTotalCount;
            vm.rejectedHasMore = vm.rejected.length < vm.rejectedTotalCount;
          })
          .finally(function () {
            if (silentLoad) isSilentLoading--;
            else vm.loading--;
          });
      }

      //#region "Show more" functions for each widget.

      function showMorePendingSubmission() {
        if (!vm.isLoading && vm.pendingSubmissionHasMore) {
          vm.pendingSubmissionSkipCount += 5;

          specialistDashboardSvc
            .getClaimsPendingSubmission(
              {
                skipCount: vm.pendingSubmissionSkipCount,
                maxResultCount: vm.maxResultCount,
              },
              {
                abpHandleError: false,
              }
            )
            .success(function (data) {
              vm.pendingSubmission = vm.pendingSubmission.concat(data.items);
              vm.pendingSubmissionHasMore = vm.pendingSubmission.length < data.totalCount;
            })
            .finally(function () {
              if (silentLoad) isSilentLoading--;
              else vm.loading--;
            });
        }
      }

      function showMoreOutstanding() {
        if (!vm.isLoading && vm.outstandingHasMore) {
          vm.outstandingSkipCount += 5;

          specialistDashboardSvc
            .getOutstandingClaimServiceRequests(
              {
                skipCount: vm.outstandingSkipCount,
                maxResultCount: vm.maxResultCount,
              },
              {
                abpHandleError: false,
              }
            )
            .success(function (data) {
              vm.outstanding = vm.outstanding.concat(data.items);
              vm.outstandingHasMore = vm.outstanding.length < data.totalCount;
            })
            .finally(function () {
              if (silentLoad) isSilentLoading--;
              else vm.loading--;
            });
        }
      }

      function showMoreLateSubmission() {
        if (!vm.isLoading && vm.lateSubmissionHasMore) {
          vm.lateSubmissionSkipCount += 5;

          specialistDashboardSvc
            .getClaimsPendingLateSubmission(
              {
                skipCount: vm.lateSubmissionSkipCount,
                maxResultCount: vm.maxResultCount,
              },
              {
                abpHandleError: false,
              }
            )
            .success(function (data) {
              vm.lateSubmission = vm.lateSubmission.concat(data.items);
              vm.lateSubmissionHasMore = vm.lateSubmission.length < data.totalCount;
            })
            .finally(function () {
              if (silentLoad) isSilentLoading--;
              else vm.loading--;
            });
        }
      }

      function showMoreGlPendingApproval() {
        if (!vm.isLoading && vm.glPendingApprovalHasMore) {
          vm.glPendingApprovalSkipCount += 5;

          specialistDashboardSvc
            .getGuaranteeLettersPendingApproval(
              {
                skipCount: vm.glPendingApprovalSkipCount,
                maxResultCount: vm.maxResultCount,
              },
              {
                abpHandleError: false,
              }
            )
            .success(function (data) {
              vm.glPendingApproval = vm.glPendingApproval.concat(data.items);
              vm.glPendingApprovalHasMore = vm.glPendingApproval.length < data.totalCount;
            })
            .finally(function () {
              if (silentLoad) isSilentLoading--;
              else vm.loading--;
            });
        }
      }

      function showMoreSubmitted() {
        if (!vm.isLoading && vm.submittedHasMore) {
          vm.submittedSkipCount += 5;

          specialistDashboardSvc
            .getPendingReviewClaimServiceRequests(
              {
                skipCount: vm.submittedSkipCount,
                maxResultCount: vm.maxResultCount,
              },
              {
                abpHandleError: false,
              }
            )
            .success(function (data) {
              vm.submitted = vm.submitted.concat(data.items);
              vm.submittedHasMore = vm.submitted.length < data.totalCount;
            })
            .finally(function () {
              if (silentLoad) isSilentLoading--;
              else vm.loading--;
            });
        }
      }

      function showMoreRejected() {
        if (!vm.isLoading && vm.rejectedHasMore) {
          vm.rejectedSkipCount += 5;

          specialistDashboardSvc
            .getRejectedClaimServiceRequests(
              {
                skipCount: vm.rejectedSkipCount,
                maxResultCount: vm.maxResultCount,
              },
              {
                abpHandleError: false,
              }
            )
            .success(function (data) {
              vm.rejected = vm.rejected.concat(data.items);
              vm.rejectedHasMore = vm.rejected.length < data.totalCount;
            })
            .finally(function () {
              if (silentLoad) isSilentLoading--;
              else vm.loading--;
            });
        }
      }
      //#endregion "Show more" functions for each widget.

      function getTimeZoneId() {
        vm.loading += 1;
        commonLookupSvc.getTenantIanaTimeZoneId({})
          .success((data) => {
            vm.timeZoneId = data;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }

      init();
    },
  ]);
})();
