import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller('common.views.components.outpatientClaimQuery.createQuery', CreateQueryController);

  CreateQueryController.$inject = [
    '$state',
    '$stateParams',
    '$location',
    'abp.services.app.serviceRequestQuery',
    'abp.services.app.specialistClaim',
    'abp.services.app.healthScreeningClaim',
    'appConstants',
  ];

  function CreateQueryController(
    $state,
    $stateParams,
    $location,
    serviceRequestQuerySvc,
    specialistClaimSvc,
    healthScreeningClaimSvc,
    appConstants
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.claimNumber = $stateParams.claimNumber;
    vm.timeZoneId = $stateParams.timeZoneId;

    vm.claimType = null;
    vm.breadcrumbClaimType = null;
    vm.breadcrumbClaimsListingLink = null;
    vm.breadcrumbClaimsDetailsLink = null;

    vm.guaranteeLetterNumber = $stateParams.letterNumber;

    vm.remarks = [
      {
        name: 'Uploaded documents do not match GL holder (Please upload the correct one)',
        value: 0,
      },
      {
        name: 'Invalid documents (Please upload the correct one)',
        value: 1,
      },
      {
        name: 'Uploaded documents are not clear (Please re-upload)',
        value: 2,
      },
      {
        name: 'Uploaded files are broken (Please re-upload)',
        value: 3,
      },
      {
        name: 'Others',
        value: 4,
      },
    ];

    vm.queryItems = [
      {
        isGeneralAttention: false,
        doctorId: '',
        type: 1,
        questions: [{}],
        checkLists: [
          {
            text: 'Detailed tax invoice',
            value: false,
          },
          {
            text: 'Patient consent & Claim form',
            value: false,
          },
          {
            text: 'Diagnosis form',
            value: false,
          },
          {
            text: 'Others',
            value: false,
            customText: '',
          },
        ],
        newAttachments: [],
        remark: '',
        invalidChecklist: false,
        invalidRemark: false,
        invalidDoctor: false,
        invalidQuestion: false,
      },
    ];

    /* Attachments */

    vm.uploadConfig = {
      objectType: 'QueryItemAttachment',
      itemsLimit: 5,
      accept: 'image/*,application/pdf',
      filters: [
        {
          name: 'fileFilter',
          fn(n) {
            const fileExtensions = `|${appConstants.fileUpload.imageExtensions
              .concat(appConstants.fileUpload.pdfExtensions)
              .join('|')}|`;
            const t = `|${n.type.slice(n.type.lastIndexOf('/') + 1)}|`;

            if (fileExtensions.indexOf(t) === -1) {
              abp.message.warn(App.localize('File_Warn_FileType'));
              return false;
            }
            return true;
          },
        },
        {
          name: 'sizeFilter',
          fn(n) {
            const sizeLimit = 52428800;
            if (n.size > sizeLimit) {
              abp.message.warn(App.localize('File_Warn_XSizeLimit', sizeLimit / 1024 / 1024));
              return false;
            }
            return true;
          },
        },
      ],
    };

    /* End Attachments */

    vm.submit = submit;
    vm.addQuestion = addQuestion;
    vm.addQueryItem = addQueryItem;
    vm.addItem = addItem;
    vm.deleteQuestion = deleteQuestion;
    vm.deleteQueryItem = deleteQueryItem;
    vm.deleteItem = deleteItem;

    init();

    /* Functions */

    function init() {
      vm.loading += 1;
      const currentUrl = $location.path().split('/');

      switch (currentUrl[2]) {
        case 'healthScreeningQuery':
          vm.claimType = 'healthScreening';
          vm.breadcrumbClaimType = App.localize('HealthScreening');
          vm.breadcrumbClaimsListingLink = '#/common/healthScreeningClaims';
          vm.breadcrumbClaimsDetailsLink = `#/common/claims/healthScreening/claim/${vm.claimNumber}?timeZoneId=${vm.timeZoneId}`;
          getHealthScreeningClaimDoctors();
          break;
        case 'specialistQuery':
          vm.claimType = 'specialist';
          vm.breadcrumbClaimType = App.localize('OutpatientSpecialist');
          vm.breadcrumbClaimsListingLink = '#/common/specialistClaims';
          vm.breadcrumbClaimsDetailsLink = `#/common/specialistClaims/details/${vm.claimNumber}?timeZoneId=${vm.timeZoneId}`;
          getSpecialistClaimDoctors();
          break;

        // no default
      }
    }

    // #region getClaimDoctors

    function getHealthScreeningClaimDoctors() {
      healthScreeningClaimSvc
        .getClaimDoctors({ claimNumber: vm.claimNumber })
        .success((data) => {
          vm.doctors = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getSpecialistClaimDoctors() {
      specialistClaimSvc
        .getClaimDoctors({ claimNumber: vm.claimNumber })
        .success((data) => {
          vm.doctors = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // #endregion getClaimDoctors

    function submit() {
      if (!validateQuery()) {
        return;
      }

      _.forEach(vm.queryItems, (query) => {
        const q = query;

        // Move remark and checklists to questions.

        if (q.type === 2) {
          q.questions = [];
          _.forEach(q.checkLists, (checkList) => {
            if (checkList.value) {
              if (checkList.text === 'Others')
                q.questions.push({
                  content: null,
                  documentName: checkList.customText,
                });
              else
                q.questions.push({
                  content: null,
                  documentName: checkList.text,
                });
            }
          });

          q.questions.push({
            content: q.remark.name,
            documentName: null,
          });
        }
      });

      swal(
        {
          title: App.localize('ConfirmCreateQuerySubmission'),
          text: App.localize('ConfirmQuerySubmissionNarrative'),
          type: 'warning',
          confirmButtonColor: '#23c6c8',
          confirmButtonText: App.localize('Yes'),
          cancelButtonText: App.localize('No'),
          showCancelButton: true,
          closeOnConfirm: false,
        },
        (isConfirm) => {
          if (isConfirm) {
            submitQuery();
          }
          swal.close();
        }
      );
    }

    function submitQuery() {
      vm.loading += 1;

      const input = {
        claimNumber: vm.claimNumber,
        serviceRequestQueryItems: vm.queryItems,
      };
      serviceRequestQuerySvc
        .createQuery(input)
        .success((data) => {
          vm.queryNumber = data.queryNumber;
          const message = [];
          message.push(App.localize('SpecialistCreateQuerySuccessNarrative1'));
          message.push(App.localize('SpecialistCreateQuerySuccessNarrative2'));

          swal(
            {
              title: App.localize('SuccessfullySubmitted'),
              text: message.join(' '),
              html: true,
              type: 'success',
              confirmButtonColor: '#23c6c8',
              confirmButtonText: App.localize('ViewQuery'),
              cancelButtonText: App.localize('ReturnToClaimList'),
              showCancelButton: true,
              closeOnConfirm: false,
            },
            (isConfirm) => {
              swal.close();

              if (isConfirm) {
                $state.go('viewHealthScreeningQuery', {
                  queryNumber: vm.queryNumber,
                  claimNumber: vm.claimNumber,
                  timeZoneId: vm.timeZoneId,
                });
              } else {
                $state.go('healthScreeningClaims', {
                  claimNumber: vm.claimNumber,
                });
              }
            }
          );
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function validateQuery() {
      let isFormValid = true;

      // Validate query item.

      if (_.isEmpty(vm.queryItems)) {
        abp.message.warn(App.localize('EmptyQueryItemWarning'));
        return false;
      }

      _.forEach(vm.queryItems, (query) => {
        const q = query;

        const isQuestionEmpty = _.some(q.questions, (question) => _.isEmpty(question.content));

        const isChecklistEmpty = !_.some(q.checkLists, (checkList) => {
          if (checkList.text === 'Others' && checkList.value)
            return !_.isEmpty(checkList.customText);
          return checkList.value;
        });

        // Validate doctor.

        const invalidDoctor = !q.isGeneralAttention && !q.doctorId;

        // Validate questions.

        const invalidQuestion = q.type === 1 && (_.isEmpty(q.questions) || isQuestionEmpty);

        // Validate remark.

        const invalidRemark = q.type === 2 && (_.isEmpty(q.remark) || _.isEmpty(q.remark.name));

        // Validate checklist.

        const invalidChecklist = q.type === 2 && isChecklistEmpty;

        if (invalidDoctor || invalidQuestion || invalidRemark || invalidChecklist) {
          isFormValid = false;
        }

        q.invalidRemark = invalidRemark;
        q.invalidChecklist = invalidChecklist;
        q.invalidDoctor = invalidDoctor;
        q.invalidQuestion = invalidQuestion;
      });

      if (!isFormValid) {
        abp.message.warn(App.localize('QueryIncompleteNarrative'), App.localize('QueryIncomplete'));
        return false;
      }
      return true;
    }

    /* Query items */

    function addQueryItem() {
      vm.queryItems.push({
        isGeneralAttention: false,
        doctorId: '',
        type: 1,
        newAttachments: [],
        questions: [{}],
        items: [{}],
        checkLists: [
          {
            text: 'Detailed tax invoice',
            value: false,
          },
          {
            text: 'Patient consent & Claim form',
            value: false,
          },
          {
            text: 'Diagnosis form',
            value: false,
          },
          {
            text: 'Others',
            value: false,
          },
        ],
        invalidChecklist: false,
        invalidRemark: false,
        invalidDoctor: false,
        invalidQuestion: false,
      });
    }

    function deleteQueryItem(index) {
      vm.queryItems.splice(index, 1);
    }

    /* End Query items */

    /* Query question */

    function addQuestion(questionObject) {
      questionObject.push({});
    }

    function deleteQuestion(questionObject, index) {
      questionObject.splice(index, 1);
    }

    /* End Query question */

    /* Query document item */

    function addItem(itemObject) {
      itemObject.push({});
    }

    function deleteItem(itemObject, index) {
      itemObject.splice(index, 1);
    }

    /* End Query document item */

    /* End Functions */
  }
})();
