import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller('common.views.postHospitalizationClaims.postHospitalizationFglRequest', FinalRequestPageController);

  FinalRequestPageController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$sce',
    '$filter',
    'abp.services.app.specialistPanelServiceRequest',
    'abp.services.app.postHospitalizationClaim',
  ];

  function FinalRequestPageController(
    $scope,
    $state,
    $stateParams,
    $sce,
    $filter,
    specialistPanelServiceRequestSvc,
    postHospitalizationClaimSvc
  ) {
    const vm = this;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.loading = 0;
    vm.saving = 0;
    vm.formAbSubmission = null;
    vm.uploadFormAbOnly = true;
    vm.coPayLabel = App.localize('Copay');
    vm.isClinic = App.isClinic();
    vm.isHost = App.isHost();

    vm.claimNumber = $stateParams.claimNumber;
    vm.letterNumber = $stateParams.letterNumber;
    vm.claimWithTopUp = $stateParams.claimWithTopUp;
    vm.timeZoneId = $stateParams.timeZoneId;
    vm.isCreate = !vm.claimNumber;
    vm.request = null;
    vm.canTopUp = false;
    vm.doctors = [];
    vm.newTaxInvoiceAttachments = [];
    vm.newFormABAttachments = [];

    vm.permissions = {
      createDoctor: abp.auth.isGranted('Clinic.Doctors.Create'),
      createClaim: abp.auth.isGranted('SpecialistClaims.Clinic.Create'),
      editClaim: vm.isHost
        ? abp.auth.isGranted('SpecialistClaims.Host.Edit')
        : abp.auth.isGranted('SpecialistClaims.Clinic.Edit'),
    };

    vm.getTopUpRequestAmount = getTopUpRequestAmount;
    vm.hasTaxInvoice = hasTaxInvoice;
    vm.isFormAbValid = isFormAbValid;
    vm.getCoPay = getCoPay;
    vm.getExceededAmount = getExceededAmount;
    vm.getCollectAmount = getCollectAmount;
    vm.save = save;

    init();

    function init() {
      vm.loading += 1;
      specialistPanelServiceRequestSvc
        .getFinalRequestDetails({
          claimNumber: vm.claimNumber,
          letterNumber: vm.letterNumber,
        })
        .success((data) => {
          vm.request = data;

          // Updates param

          vm.claimNumber = data.claimNumber;
          vm.employeeId = data.patient.id;
          vm.panelLocationId = data.panelLocationId;
          if (!vm.request.billAmount) vm.request.billAmount = null;

          if (vm.request.guaranteeLetter.coPayValue) {
            let label = '';
            if (vm.request.guaranteeLetter.isCoPayPercentage) {
              label = `${vm.request.guaranteeLetter.coPayValue}%`;
            } else {
              label = $filter('currencyFormat')(
                vm.request.guaranteeLetter.coPayValue,
                vm.currencyCode
              );
            }
            vm.coPayLabel = App.localize('CopayX', label);
          }

          refreshDoctors();
          vm.uploadFormAbOnly = !vm.isCreate && vm.isClinic && vm.request.hasPatientTicket;
          if (vm.uploadFormAbOnly) {
            vm.formAbSubmission = 1;
          }

          if (vm.request.formABAttachments && vm.request.formABAttachments.length > 0) {
            vm.formAbSubmission = 1;
          }

          vm.claimWithTopUp = vm.claimWithTopUp || vm.request.guaranteeLetter.hasTopUpRequest;
          vm.canTopUp =
            !vm.isHost &&
            !vm.request.guaranteeLetter.isFinalized &&
            vm.request.guaranteeLetter.isSoftCap;

          if (vm.request.isLateSubmissionRequest) {
            const message = [];
            message.push(
              `<li class="m-b-xs"><span>${App.localize('LateClaimNoteNarrative1')}</span></li>`
            );
            message.push(
              `<li class="m-b-xs"><span>${App.localize('LateClaimNoteNarrative2')}</span></li>`
            );
            message.push(
              `<li class="m-b-xs"><span>${App.localize('LateClaimNoteNarrative3')}</span></li>`
            );
            message.push(
              `<li class="m-b-xs"><span>${App.localize('LateClaimNoteNarrative4')}</span></li>`
            );
            message.push(
              `<li class="m-b-xs"><span>${App.localize('LateClaimNoteNarrative5')}</span></li>`
            );
            message.push(
              `<li class="m-b-xs"><span>${App.localize('LateClaimNoteNarrative6')}</span></li>`
            );

            vm.lateSubmissionNotes = $sce.trustAsHtml(message.join(''));

            vm.claimWithTopUp = null;
            vm.formAbSubmission = 1;

            if (vm.isCreate) {
              _.each(vm.request.documentChecklistItems, (item) => {
                const i = item;
                i.required = i.isMandatory;
                i.isChecked = i.isSelected;
              });
            }
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    /* Validate Operations */

    function hasTaxInvoice() {
      if (!vm.request) return false;
      return (
        vm.newTaxInvoiceAttachments.length ||
        _.some(vm.request.taxInvoiceAttachments, ['isDelete', false])
      );
    }

    function isFormAbValid() {
      if (!vm.formAbSubmission) return false;
      if (vm.formAbSubmission === 2) return true;
      return (
        vm.newFormABAttachments.length || _.some(vm.request.formABAttachments, ['isDelete', false])
      );
    }

    /* End of Validate Operations */

    function refreshDoctors() {
      vm.loading += 1;
      postHospitalizationClaimSvc
        .getLocationDoctors({
          id: vm.panelLocationId,
          letterNumber: vm.letterNumber,
        })
        .success((data) => {
          vm.doctors = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    /* Calculations */

    function getCoPay() {
      if (!vm.request || !vm.request.guaranteeLetter) return 0;
      const amt = vm.request.billAmount || 0;
      if (!amt) return 0;
      let coPay = vm.request.guaranteeLetter.coPayValue;
      if (coPay && vm.request.guaranteeLetter.isCoPayPercentage) {
        coPay = (vm.request.guaranteeLetter.coPayValue * amt) / 100;
      }
      coPay = Math.round(Math.max(Math.min(coPay, amt), 0) * 100) / 100;
      return coPay;
    }

    function getExceededAmount() {
      if (!vm.request) return 0;
      const amt = vm.request.billAmount || 0;
      const coveredAmt = amt - vm.getCoPay();
      return Math.max(coveredAmt - vm.request.guaranteeLetter.guaranteedAmount, 0);
    }

    function getCollectAmount() {
      return vm.getCoPay() + vm.getExceededAmount();
    }

    function getTopUpRequestAmount() {
      return Math.max(vm.request.billAmount - vm.request.guaranteeLetter.guaranteedAmount, 0);
    }

    /* End of Calculations */

    /* Submit Claim */

    function save() {
      if (vm.saving) return;

      vm.invalidChecklist = _.some(
        vm.request.documentChecklistItems,
        (d) => d.required && !d.isChecked
      );
      vm.invalidFormAb = !vm.isFormAbValid();
      vm.invalidTaxInvoice = !vm.hasTaxInvoice();

      const isFormValid = App.isFormValid($scope.editForm);
      if (!isFormValid || vm.invalidFormAb || vm.invalidTaxInvoice || vm.invalidChecklist) {
        return;
      }

      const uploadAbNow = vm.formAbSubmission === 1;

      const formABAttachmentIds = [];
      if (uploadAbNow) {
        _.each(vm.request.formABAttachments, (attachment) => {
          if (!attachment.isDelete) formABAttachmentIds.push(attachment.id);
        });
      }

      const taxInvoiceAttachmentIds = [];
      _.each(vm.request.taxInvoiceAttachments, (attachment) => {
        if (!attachment.isDelete) taxInvoiceAttachmentIds.push(attachment.id);
      });

      const input = {
        claimNumber: vm.claimNumber,
        actualVisitDate: vm.request.actualVisitDate,
        referenceNumber: vm.request.referenceNumber,
        billAmount: vm.request.billAmount,
        attendingDoctors: vm.request.attendingDoctors,
        formABAttachmentIds,
        formABAttachments: uploadAbNow ? vm.newFormABAttachments : null,
        taxInvoiceAttachmentIds,
        taxInvoiceAttachment: _.first(vm.newTaxInvoiceAttachments),
        letterNumber: vm.letterNumber,
      };

      if (vm.isCreate || (!vm.isCreate && vm.request.status === 1)) {
        input.claimWithTopUp = vm.claimWithTopUp;
      }

      if (vm.request.isLateSubmissionRequest && vm.isCreate) {
        input.lateSubmissionReason = tidyReason();

        const checklistItems = [];
        _.each(vm.request.documentChecklistItems, (item) => {
          checklistItems.push({
            text: item.text,
            isSelected: item.isChecked,
            isMandatory: item.required,
          });
        });
        input.documentChecklistItems = checklistItems;

        swal(
          {
            title: App.localize('ConfirmSubmission'),
            text: App.localize('ConfirmLateSubmissionNarrative'),
            type: 'warning',
            confirmButtonColor: '#1ab394',
            confirmButtonText: App.localize('Yes'),
            cancelButtonText: App.localize('No'),
            showCancelButton: true,
            closeOnConfirm: false,
          },
          (isConfirm) => {
            if (isConfirm) {
              submitFinalRequest(input);
            }
            swal.close();
          }
        );
      } else {
        submitFinalRequest(input);
      }
    }

    function submitFinalRequest(input) {
      // Submit claim.

      vm.saving += 1;
      specialistPanelServiceRequestSvc
        .submitFinalRequest(input)
        .success((data) => {
          vm.claimNumber = data.claimNumber;
          abp.notify.success(App.localize('SuccessfullySubmitted'));
          if (vm.isHost) returnToClaimDetail();
          else showSuccessModal();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function showSuccessModal() {
      const exceededAmount = vm.getCollectAmount();

      const message = [];
      message.push(App.localize('SpecialistClaimSuccessNarrative1'));
      message.push(App.localize('SpecialistClaimSuccessNarrative2'));
      message.push(App.localize('SpecialistClaimSuccessNarrative3'));

      const exceededAmountText =
        exceededAmount && vm.request.status !== 5
          ? `<b class="text-danger">${App.localize(
              'RememberCollectXPaymentFromPatient',
              vm.currencyCode + exceededAmount
            )}</b><br /><br />`
          : '';

      swal(
        {
          title: App.localize('SuccessfullySubmitted'),
          text: exceededAmountText + message.join(' '),
          html: true,
          type: 'success',
          confirmButtonColor: '#1ab394',
          confirmButtonText: App.localize('ViewClaim'),
          cancelButtonText: App.localize('ReturnToPatientSearch'),
          showCancelButton: true,
          closeOnConfirm: false,
        },
        (isConfirm) => {
          if (isConfirm) {
            returnToClaimDetail();
          } else {
            $state.go('clinic.checkin');
          }
          swal.close();
        }
      );
    }

    /* End of Submit Claim */

    /* Late submission claim */

    function tidyReason() {
      let reason = _.trim(vm.request.reason);
      if (reason === 'Others') {
        const remarks = _.trim(vm.request.remarks);
        if (remarks) {
          reason += `: ${remarks}`;
        } else {
          reason = '';
        }
      }
      return reason;
    }

    /* End of Late submission claim */

    function returnToClaimDetail() {
      $state.go('postHospitalizationClaimDetail', {
        claimNumber: vm.claimNumber,
        glNumber: vm.letterNumber,
        timeZoneId: vm.timeZoneId,
      });
    }
  }
})();
