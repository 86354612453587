import swal from 'sweetalert';
import PromptQueue from '../../../../lib/prompt-queue';

(() => {
  angular
    .module('app')
    .controller('common.views.patientTickets.createEditNew', NewTicketEditController);

  NewTicketEditController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$uibModal',
    '$timeout',
    '$filter',
    '$window',
    'abp.services.app.patientTicket',
    'abp.services.app.commonLookup',
    'lookupModal',
    'abp.services.app.clinicEmployee',
    'Hms.PatientTickets.PatientTicketType',
    'Hms.MultiTenancy.TenantClinicType',
    'Hms.PatientTickets.PatientTicketReimbursementMode',
    'Hms.PatientTickets.ServiceFeePayor',
    'Hms.PatientTickets.PatientTicketItem',
    'Hms.PaymentAccounts.PaymentAccount',
    'Abp.Configuration.Setting',
    'moment',
  ];

  function NewTicketEditController(
    $scope,
    $state,
    $stateParams,
    $uibModal,
    $timeout,
    $filter,
    $window,
    patientTicketSvc,
    commonLookupSvc,
    lookupModal,
    clinicEmployeeSvc,
    enumPatientTicketType,
    enumTenantClinicType,
    enumPatientTicketReimbursementMode,
    enumServiceFeePayor,
    constsPatientTicketItem,
    constsPaymentAccount,
    constsAbpConfig,
    moment
  ) {
    const vm = this;

    vm.constsPatientTicketItem = constsPatientTicketItem;
    vm.constsPaymentAccount = constsPaymentAccount;
    vm.constsPaymentAccount = constsAbpConfig;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.defaultMaxAllowance = parseFloat(abp.setting.get('Hms.General.MaxAllowanceAmountLimit'));
    vm.supportEmail = abp.setting.get('Hms.General.ClinicSupportEmail');
    vm.loading = 0;
    vm.saving = 0;
    vm.savingDraft = 0;

    vm.ticketDate = moment();
    vm.ticketNumber = $stateParams.ticketNumber;
    vm.claimId = $stateParams.claimId;
    vm.employeeId = $stateParams.employeeId;
    vm.isCreate = $stateParams.employeeId;
    vm.patientSessionId = $stateParams.patientSessionId;
    vm.isLateSubmission = $stateParams.patientSessionId > 0;
    vm.lateSubmissionReason = $stateParams.lateSubmissionReason;
    vm.lateSubmissionRequestorName = $stateParams.lateSubmissionRequestorName;
    vm.doctors = [];
    vm.treatments = [];
    vm.patient = null;
    vm.clinic = {};
    vm.isClinic = App.isClinic();
    vm.isCorporate = App.isCorporate();
    vm.isMcStartDateAlsoCheckedInDate = false;
    vm.isCtTicket = false;
    vm.report = null;
    vm.claim = null;
    vm.needMc = false;
    vm.patientHasMc = null;
    vm.isEditingSpecialistDetail = false;
    vm.hasMultipleCheckInWithSharedPool = false;
    vm.isOutpatientTicket = false;

    vm.enums = {
      patientTicketType: enumPatientTicketType,
      tenantClinicType: enumTenantClinicType,
      patientTicketReimbursementMode: enumPatientTicketReimbursementMode,
      serviceFeePayor: enumServiceFeePayor,
    };

    vm.saveSpecialistDetailTrigger = 0;
    vm.showActualDateRemark = showActualDateRemark;
    vm.permissions = {
      createDoctor: abp.auth.isGranted('Clinic.Doctors.Create'),
      changeServiceFeePayor: abp.auth.isGranted(
        'PatientTickets.Reimbursement.ChangeServiceFeePayor'
      ),
    };
    vm.ticket = {
      ticketType: 0, // Initialize to Normal ticket to hide a section.
      treatments: [],
    };
    vm.autocomplete = {
      options: {
        componentRestrictions: {
          country: abp.setting.get('Hms.General.DefaultCountryCode'),
        },
        fields: ['formatted_address', 'name'],
      },
      callback(place) {
        vm.ticket.manualClinicName = place.name;
        vm.ticket.manualClinicLocation = place.formatted_address;
      },
    };
    vm.uploadConfig = {
      objectType: 'TicketAttachment',
    };

    vm.updatedTicketDate = updatedTicketDate;
    vm.createDoctor = createDoctor;
    vm.changeLocation = changeLocation;
    vm.needMc = needMc;
    vm.nextStage = nextStage;
    vm.getDependentDescription = getDependentDescription;
    vm.treatmentHasValue = treatmentHasValue;
    vm.checkRemarkRequired = checkRemarkRequired;
    vm.getRemarkPlaceholder = getRemarkPlaceholder;
    vm.addTreatments = addTreatments;
    vm.clearTreatments = clearTreatments;
    vm.removeTreatment = removeTreatment;
    vm.checkDuplicateTreatment = checkDuplicateTreatment;
    vm.updateTreatment = updateTreatment;
    vm.refreshTreatmentAndMcRemarks = refreshTreatmentAndMcRemarks;
    vm.hasUncoveredTreatments = hasUncoveredTreatments;
    vm.getAllowanceDisplay = getAllowanceDisplay;
    vm.getTreatmentTotalAmount = getTreatmentTotalAmount;
    vm.calculateAmountCoverage = calculateAmountCoverage;
    vm.getManualPaymentAmount = getManualPaymentAmount;
    vm.getCoveredAmount = getCoveredAmount;
    vm.getUncoveredAmount = getUncoveredAmount;
    vm.issueMc = issueMc;
    vm.removeMc = removeMc;
    vm.setMcStartDate = setMcStartDate;
    vm.getMcDurationDescription = getMcDurationDescription;
    vm.getMcStartDateChoice = getMcStartDateChoice;
    vm.isInvalidMc = isInvalidMc;
    vm.save = save;
    vm.resetBloodPressureRemark = resetBloodPressureRemark;
    vm.saveDraft = saveDraft;
    vm.isInvalidAttachment = isInvalidAttachment;
    vm.updatePlaceholder = updatePlaceholder;
    vm.clearAll = clearAll;
    vm.checkMultipleCheckInWithSharedPool = checkMultipleCheckInWithSharedPool;

    init();

    function init() {
      vm.loading += 1;
      patientTicketSvc
        .getTicketForEdit({
          employeeId: $stateParams.employeeId,
          ticketNumber: $stateParams.ticketNumber,
          clinicLocationId: $stateParams.clinicLocationId,
          clinicType: $stateParams.clinicType,
          ticketType: $stateParams.ticketType,
          claimId: $stateParams.claimId,
          patientSessionId: $stateParams.patientSessionId,
        })
        .success((data) => {
          vm.doctors = data.doctors || [];
          vm.banks = data.banks || [];
          vm.patient = data.patient;
          vm.clinic = data.clinic || {};
          vm.treatments = data.treatments || [];
          vm.claim = data.claim;
          vm.corporateBalance = data.corporateBalance;

          if (!vm.isCreate) {
            vm.ticketNumber = data.ticket.ticketNumber;
            vm.employeeId = data.patient.id;
          }
          vm.ticketDate = moment(data.ticket.ticketDate);

          vm.ticket = data.ticket;
          vm.report = data.ticket.report;

          // HACK: HM-3416 Get by service type in future.

          vm.isOutpatientTicket =
            vm.ticket.clinicType === 5 ||
            (vm.ticket.clinicType === 7 && vm.ticket.serviceType === 5);

          // Prefill with draft if any.

          if (data.ticketDraft) {
            const draft = JSON.parse(data.ticketDraft);
            vm.ticket.doctorId = draft.doctorId;
            vm.ticket.treatments = draft.treatments;
            vm.ticket.medicalCertificate = draft.medicalCertificate;
            vm.report = draft.report;
          }

          if (vm.isLateSubmission) {
            validateLateSubmission(data.patientSessionId);
          }

          // Process remark tags.
          // If has claim, push the default claim message and remarks.

          if (!vm.ticket.treatments) {
            vm.ticket.treatments = [];
          }
          _.each(vm.ticket.treatments, (t) => {
            t.treatment = _.find(vm.treatments, ['id', t.treatmentId]);
            setRemarkTags(t);
            if (!vm.isCreate && vm.ticket.ticketType === 3) t.isDone = true;
          });

          if (vm.claimId) {
            if (!vm.ticket.treatments.length) {
              vm.ticket.treatments.push({});
            }
            const refTreatment = vm.ticket.treatments[0];
            refTreatment.remark = vm.claim.treatmentRemarks;
            if (refTreatment.remark && !vm.isOutpatientTicket) {
              refTreatment.remarkTags = _.chain(_.split(vm.claim.treatmentRemarks, ','))
                .map((remark) => _.trim(remark || ''))
                .filter((remark) => remark.length > 0)
                .uniqBy((remark) => remark.toUpperCase())
                .value();
            }
            refTreatment.amount = vm.claim.receiptAmount;

            if (vm.claim.specialistDisciplineId)
              refTreatment.treatment = _.find(vm.treatments, [
                'specialistDisciplineId',
                vm.claim.specialistDisciplineId,
              ]);
          }

          refreshTicketTreatments();

          // If is pre-employment ticket, get the treatment that represent package.

          if (vm.ticket.ticketType === 3) {
            vm.package = _.first(vm.ticket.treatments);
            vm.packageTreatments = _.tail(vm.ticket.treatments);
          }

          vm.isCtTicket = vm.ticket.clinicType >= 200 && vm.ticket.clinicType <= 209;
          vm.isInpatient = vm.ticket.clinicType === 251;
          vm.namePlaceholder =
            vm.isCtTicket || vm.isInpatient
              ? App.localize('ProviderName')
              : App.localize('PanelName');
          vm.addressPlaceholder =
            vm.isCtTicket || vm.isInpatient
              ? App.localize('ProviderAddress')
              : App.localize('PanelAddress');

          vm.attachments = vm.ticket.attachments;
          vm.newAttachments = [];

          if (vm.claim) {
            _.forEach(vm.claim.attachments, (attachment) => {
              attachment.isImage = attachment.contentType.startsWith('image/');
            });
          }

          vm.needMc =
            vm.ticket &&
            vm.ticket.ticketType !== 3 &&
            (vm.ticket.clinicType === 1 ||
              vm.ticket.clinicType === 2 ||
              vm.isOutpatientTicket ||
              vm.isCtTicket);

          if ((!vm.isCreate && vm.ticket.ticketType === 0) || vm.isLateSubmission) {
            vm.patientHasMc = vm.ticket.medicalCertificate ? 1 : 0;
          }

          if (vm.ticket.medicalCertificate) {
            vm.ticket.medicalCertificate.startDate = moment(vm.ticket.medicalCertificate.startDate);
            vm.ticket.medicalCertificate.endDate = moment(vm.ticket.medicalCertificate.endDate);
            vm.ticket.checkedInTime = moment(vm.ticket.checkedInTime);
            vm.isMcStartDateAlsoCheckedInDate =
              vm.ticket.medicalCertificate.startDate.diff(
                vm.ticket.checkedInTime.startOf('days'),
                'days'
              ) === 0;
            if (vm.isCreate && vm.claim) {
              vm.ticket.medicalCertificate.remark = vm.claim.treatmentRemarks || 'n/a';
            }
          }

          const strAmt = $filter('currencyFormat')(vm.ticket.coveredAmount, vm.currencyCode);
          vm.reviseCoveredAmountUpwardErrorMessage = App.localize(
            'ReviseCoveredAmountUpwardErrorMessage',
            strAmt
          );

          if (vm.clinic.clinicLocationId) {
            refreshDoctors();
          } else if (vm.isCreate) {
            vm.changeLocation();
          }
          vm.permissions.editActualVisitDate =
            vm.isOutpatientTicket && abp.auth.isGranted('PatientTickets.Host.Specialist.Edit');

          if (vm.isClinic && vm.isCreate) {
            vm.checkMultipleCheckInWithSharedPool();
            vm.multipleCheckInWarningMessage = App.localize(
              'MultipleCheckInWithSharedPoolWarningMessage',
              vm.supportEmail
            );
          }
        })
        .error(() => {
          $window.history.back();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function refreshDoctors() {
      if (vm.isCreate) {
        patientTicketSvc
          .getLocationDoctors({
            id: vm.clinic.clinicLocationId,
          })
          .success((data) => {
            vm.doctors = data.items;
          })
          .finally(() => {
            if (!vm.doctors.length) {
              vm.createDoctor();
            }
          });
      }
    }

    // Increse this value to trigger specialist detail component method.

    function showActualDateRemark() {
      return (
        vm.permissions.editActualVisitDate &&
        vm.ticket &&
        vm.ticket.actualVisitDate &&
        moment(vm.ticket.ticketDate).format('LL') !== moment(vm.ticket.actualVisitDate).format('LL')
      );
    }

    function updatedTicketDate() {
      if (vm.isCreate || vm.ticket.ticketType === 1) {
        refreshAllowance();
      }

      if (!vm.ticket.bankingDetails.isInvoiced && vm.ticket.ticketType === 2) {
        checkActivePremiumSubscription();
      }

      if (vm.isCreate && vm.ticket.medicalCertificate) {
        vm.ticket.medicalCertificate.startDate = moment(vm.ticketDate).startOf('day');
        vm.ticket.medicalCertificate.endDate = moment(vm.ticketDate).startOf('day');
      }
    }

    function createDoctor() {
      if (vm.isCreate && vm.permissions.createDoctor) {
        $uibModal
          .open({
            templateUrl: require('../../../clinic/views/doctors/createOrEditModal.html'),
            controller: 'clinic.views.doctors.createOrEditModal as vm',
            backdrop: 'static',
            resolve: {
              doctorId: null,
              clinicLocationId: vm.clinic.clinicLocationId,
            },
          })
          .result.then(() => {
            refreshDoctors();
          });
      }
    }

    function changeLocation() {
      if (vm.ticket.ticketType === 0) {
        lookupModal.open({
          title: App.localize('SelectPanelLocation'),
          serviceMethod: commonLookupSvc.findClinicLocations,
          callback(location) {
            vm.clinic.clinicLocationId = parseInt(location.value, 10);
            vm.clinic.name = location.name;
            vm.clinic.address = location.address;
            vm.clinic.contactNumber = location.contactNumber;
            vm.clinic.midnightSurcharge = location.midnightSurcharge;
            vm.ticket.doctorId = null;
            refreshDoctors();
          },
        });
      }
    }

    function needMc() {
      return (
        vm.ticket &&
        vm.ticket.ticketType !== 3 &&
        (vm.ticket.clinicType === 1 ||
          vm.ticket.clinicType === 2 ||
          vm.isOutpatientTicket ||
          vm.isCtTicket ||
          vm.isInpatient)
      );
    }

    function nextStage() {
      vm.stage += 1;
      if (!vm.needMc()) {
        vm.stage += 1;
      }
    }

    function getDependentDescription() {
      return App.localize(
        'DependentDescription',
        vm.patient.sponsorName,
        $filter('nationalIdFilter')(vm.patient.sponsorNationalId)
      );
    }

    /* Treatment Management */

    function treatmentHasValue(treatment) {
      return treatment.treatment || hasRemark(treatment) || treatment.amount;
    }

    function checkRemarkRequired(treatment) {
      const isNormalOrPreemployment = vm.ticket.ticketType === 0 || vm.ticket.ticketType === 3;
      return (
        vm.treatmentHasValue(treatment) &&
        ((isNormalOrPreemployment && treatment.treatment && treatment.treatment.requiresRemark) ||
          vm.isCtTicket ||
          vm.isInpatient)
      );
    }

    function getRemarkPlaceholder(treatment) {
      if (!treatment.treatment) return '';
      if (vm.ticket.clinicType === 1) {
        // If remark is provided for the treatment.

        if (hasRemark(treatment)) {
          return '';
        }

        return _.toUpper(_.trim(treatment.treatment.displayName || '')) ===
          _.toUpper('Consultation')
          ? App.localize('ConsultationRemarksNarrative')
          : App.localize('TreatmentRemarksNarrative');
      }
      if (vm.checkRemarkRequired(treatment)) return App.localize('PleaseExplain');
      return '';
    }

    function hasTreatments() {
      return _.some(vm.ticket.treatments, (t) => vm.treatmentHasValue(t));
    }

    function refreshTicketTreatments() {
      // For CT ticket, auto-assign treatment if other fields are filled.
      // And unassign if other fields are empty.
      // This adjustment is for amount calculation to be able to handle custom treatment normally.

      if (vm.isCtTicket || vm.isInpatient) {
        const defaultCt = _.first(vm.treatments);
        _.each(vm.ticket.treatments, (t) => {
          if ((hasRemark(t) || t.amount) && (vm.isCtTicket || vm.isInpatient)) {
            t.treatment = defaultCt;
            t.treatmentId = t.treatment.id;
          } else {
            t.treatment = null;
            t.treatmentId = null;
          }
        });
      }

      if (vm.ticket.ticketType !== 3) {
        let hasBlank = _.some(vm.ticket.treatments, (t) => !vm.treatmentHasValue(t));

        while (!hasBlank || vm.ticket.treatments.length < 2) {
          vm.ticket.treatments.push({});
          hasBlank = true;
        }
      }
    }

    function addTreatments() {
      for (let i = 0; i < 4; i += 1) {
        vm.ticket.treatments.push({});
      }
    }

    function clearTreatments() {
      vm.ticket.treatments = [];
      vm.refreshTreatmentAndMcRemarks();
    }

    function removeTreatment(index) {
      if (index < vm.ticket.treatments.length) {
        vm.ticket.treatments.splice(index, 1);
        vm.refreshTreatmentAndMcRemarks();
      }
    }

    function checkDuplicateTreatment(treatment) {
      if (treatment.isDuplicable) return false;

      return _.some(vm.ticket.treatments, (t) => t.treatmentId === treatment.id);
    }

    function updateTreatment(treatment) {
      if (treatment.treatment) {
        if (vm.checkDuplicateTreatment(treatment.treatment)) {
          abp.message.error(App.localize('DuplicateTreatmentValidationErrorMessage'));
          treatment.treatmentId = null;
          treatment.treatment = null;
          treatment.amount = null;
          return;
        }

        treatment.treatmentId = treatment.treatment.id;
        if (treatment.treatment.defaultRate) {
          $timeout(() => {
            treatment.amount = treatment.treatment.defaultRate;
          }, 50);
        }
      } else {
        treatment.treatmentId = null;
      }
      vm.refreshTreatmentAndMcRemarks();
    }

    function refreshTreatmentAndMcRemarks() {
      refreshTicketTreatments();
      updateMcRemarks();
    }

    function hasUncoveredTreatments() {
      return _.some(
        vm.ticket.treatments,
        (treatment) => treatment.treatment && treatment.treatment.notCovered
      );
    }

    /* End of Treatment Management */

    /* Amounts Calculation */

    function getAllowanceDisplay() {
      return Math.min(
        vm.patient.allowance,
        vm.ticket.ticketType === 2 && vm.ticket.clinicType === 251
          ? vm.corporateBalance
          : vm.defaultMaxAllowance
      );
    }

    // Refresh patient's allowance. Only for NPR & Manual tickets.
    function refreshAllowance() {
      vm.loading += 1;
      patientTicketSvc
        .getAllowance({
          employeeId: vm.employeeId,
          panelLocationId: vm.clinic ? vm.clinic.clinicLocationId : null,
          clinicType: vm.ticket.clinicType,
          ticketType: vm.ticket.ticketType,
          ticketDate: vm.ticketDate,
        })
        .success((data) => {
          vm.patient.allowance = data.employeeAllowance;
          vm.corporateBalance = data.tenantBalance;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTreatmentTotalAmount() {
      let amount = 0;
      _.each(vm.ticket.treatments, (t) => {
        if (t.treatment && (t.amount || t.uncoveredAmount)) {
          amount += t.amount || 0;
          amount += t.uncoveredAmount || 0;
        }
      });
      return amount;
    }

    function calculateAmountCoverage() {
      let claimable = 0;
      let total = 0;
      let uncovered = 0;
      _.each(vm.ticket.treatments, (t) => {
        if (t.treatment) {
          if (!t.treatment.notCovered) {
            claimable += t.amount || 0;
            uncovered += t.uncoveredAmount || 0;
          } else {
            uncovered += t.amount || 0;
          }
          total += t.amount || 0;
          total += t.uncoveredAmount || 0;
        }
      });

      if (vm.isCreate) {
        if (vm.patient && vm.ticket && claimable) {
          // Apply co-pay for normal/reimbursement tickets

          const isNormalOrReimbursement = vm.ticket.ticketType === 0 || vm.ticket.ticketType === 2;
          if (vm.patient.coPay && isNormalOrReimbursement) {
            let coPay = vm.patient.coPay.value;
            if (vm.patient.coPay.isPercentage) {
              coPay *= claimable / 100;
            }
            if (coPay < 0) {
              coPay = 0;
            } else if (coPay > claimable) {
              coPay = claimable;
            }
            claimable -= coPay;
          }

          // Check against allowance

          if (claimable > vm.patient.allowance) {
            claimable = vm.patient.allowance;
          }
        }
      } else if (vm.ticket) {
        claimable -= !vm.isOutpatientTicket ? vm.ticket.manualPayment : vm.ticket.coPayAmount;
      }

      // Round number to 2 decimal places.

      total = App.roundAmount(total);
      claimable = App.roundAmount(claimable);
      let manualPayment = total - claimable;

      if (vm.ticket && vm.isOutpatientTicket) {
        // Apply the copay amount if exists.

        const copayAmount = vm.ticket.coPayAmount || 0;
        manualPayment = uncovered + copayAmount;
      }

      return {
        claimable,
        uncovered,
        manualPayment: App.roundAmount(manualPayment),
      };
    }

    function getManualPaymentAmount() {
      return vm.calculateAmountCoverage().manualPayment;
    }

    function getCoveredAmount() {
      return vm.calculateAmountCoverage().claimable;
    }

    function getUncoveredAmount() {
      return vm.calculateAmountCoverage().uncovered;
    }

    /* End of Amounts Calculation */

    /* Medical Certificate Management */

    function updateMcRemarks() {
      if (vm.ticket.medicalCertificate && !vm.isCtTicket && !vm.claimId) {
        const remark = _.chain(vm.ticket.treatments)
          .filter((t) => t.treatment && t.treatment.displayName)
          .map((t) => t.treatment.displayName)
          .join(', ')
          .value();
        vm.ticket.medicalCertificate.remark = remark;
      }
    }

    function issueMc() {
      vm.patientHasMc = !vm.patientHasMc;
      const firstDate = moment(
        vm.ticket.ticketType !== 0 ? vm.ticketDate : vm.ticket.checkedInTime
      ).startOf('day');
      vm.ticket.medicalCertificate = {
        startDate: firstDate,
        endDate: firstDate,
      };
      vm.isMcStartDateAlsoCheckedInDate = 1;
      updateMcRemarks();
    }

    function removeMc() {
      vm.ticket.medicalCertificate = null;
      if (vm.ticket.ticketType === 0) {
        vm.patientHasMc = 0;
      }
    }

    function setMcStartDate(offset) {
      const checkInDate = moment(vm.ticket.checkedInTime).startOf('day');
      vm.ticket.medicalCertificate.startDate = checkInDate.add(offset, 'days');

      const mcEndDate = vm.ticket.medicalCertificate.endDate.startOf('day');
      if (mcEndDate.diff(vm.ticket.medicalCertificate.startDate, 'days') < 0) {
        vm.ticket.medicalCertificate.endDate = moment(vm.ticket.medicalCertificate.startDate);
      }
      vm.isMcStartDateAlsoCheckedInDate = offset === 0;
    }

    function getMcDurationDescription() {
      if (vm.ticket.medicalCertificate) {
        const startDate = moment(vm.ticket.medicalCertificate.startDate);
        const endDate = moment(vm.ticket.medicalCertificate.endDate);
        const duration = endDate.diff(startDate, 'days') + 1;
        return App.localize('XDays', duration);
      }
      return '';
    }

    function getMcStartDateChoice(offset) {
      return moment(vm.ticket.checkedInTime).add(offset, 'days').startOf('day');
    }

    function isInvalidMc() {
      return vm.needMc && vm.patientHasMc === null && vm.ticket.ticketType === 0;
    }

    /* End of Medical Certificate Management */

    function createOrEditTicket() {
      let canSubmit = true;
      const prompts = new PromptQueue();

      if (vm.ticket.ticketType === 0 || vm.ticket.ticketType === 3) {
        if (!vm.clinic.clinicLocationId) {
          abp.message.error(App.localize('InvalidPanelLocation'));
          return;
        }

        if (!vm.ticket.doctorId) {
          abp.message.error(
            App.localize(
              vm.ticket.clinicType === 4 ? 'OpticianOptometristNotSelected' : 'DoctorNotSelected'
            )
          );
          return;
        }
      } else {
        vm.clinic = {};
        vm.ticket.doctorId = null;
      }

      if (vm.ticket.ticketType !== 1 && !hasTreatments()) {
        abp.message.error(App.localize('EmptyTreatmentValidationErrorMessage'));
        return;
      }

      if (vm.isCreate) {
        if (vm.ticket.ticketType === 3) {
          const uncheckedTreatment = _.find(vm.ticket.treatments, {
            isDone: false,
          });
          if (uncheckedTreatment) {
            abp.message.error(App.localize('UncheckedTreatmentsWarningMessage'));
            return;
          }
        } else if (
          vm.isOutpatientTicket &&
          _.find(vm.ticket.treatments, (t) => vm.treatmentHasValue(t) && !t.treatmentId)
        ) {
          abp.message.error(App.localize('OneOrMoreInputValueIsInvalid'));
          return;
        }
      }

      let cleanTreatments = true;
      _.each(vm.ticket.treatments, (treatment) => {
        const t = treatment;
        if (vm.treatmentHasValue(t)) {
          if (!vm.isOutpatientTicket) t.remark = getRemark(t);
          if (t.amount < 0) {
            abp.message.error(App.localize('InvalidTreatmentRateAmount'));
            cleanTreatments = false;
            return false;
          }
          if (vm.ticket.ticketType === 0) {
            if (!t.amount) {
              abp.message.error(App.localize('InvalidTreatmentRateAmount'));
              cleanTreatments = false;
              return false;
            }
            if (
              vm.isCreate &&
              !vm.patient.bypassTreatmentMaxRate &&
              t.amount >
                (vm.clinic.midnightSurcharge && t.treatment.midnightSurcharge
                  ? t.treatment.midnightMaxRate
                  : t.treatment.maxRate)
            ) {
              abp.message.error(App.localize('TreatmentRateExceededMaxRate'));
              cleanTreatments = false;
              return false;
            }
          }
          if (vm.checkRemarkRequired(t) && !t.remark) {
            abp.message.error(App.localize('MissingTreatmentRemark'));
            cleanTreatments = false;
            return false;
          }
        }
        return true;
      });

      if (!cleanTreatments) return;

      if (vm.ticket.ticketType !== 0 && vm.ticketDate > moment()) {
        abp.message.error(App.localize('ClaimDateIsInFuture'));
        return;
      }

      if (vm.ticket.medicalCertificate) {
        const startDate = moment(vm.ticket.medicalCertificate.startDate);
        if (vm.ticket.ticketType === 1) {
          const mcDays = Math.ceil(vm.ticket.medicalCertificate.effectiveMcDays);
          if (Number.isNaN(Number(mcDays)) || mcDays < 0) {
            abp.message.error(App.localize('InvalidEffectiveMcDays'));
            return;
          }
          vm.ticket.medicalCertificate.endDate = moment(startDate).add(mcDays - 1, 'd');
        } else {
          const endDate = moment(vm.ticket.medicalCertificate.endDate);
          if (endDate < startDate) {
            abp.message.error(App.localize('McEndDateEarlierThanStartDate'));
            return;
          }
          vm.ticket.medicalCertificate.effectiveMcDays = endDate.diff(startDate, 'days') + 1;
        }

        const mcBenchmarkDay = vm.ticket.ticketType !== 0 ? vm.ticketDate : vm.ticket.checkedInTime;
        const mcStartDayOffset = startDate.diff(moment(mcBenchmarkDay).startOf('day'), 'days');
        if (mcStartDayOffset < 0) {
          abp.message.error(App.localize('McStartDateEarlierThanTicketDate'));
          return;
        }
        if (mcStartDayOffset > 1) {
          if (vm.ticket.ticketType === 2) {
            prompts.add(
              swal,
              {
                title: App.localize('AreYouSure'),
                text: App.localize('McStartDateTooFarFromTicketDateWarning'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1ab394',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                closeOnConfirm: true,
                closeOnCancel: true,
              },
              (isConfirm) => {
                canSubmit = isConfirm;
              }
            );
          } else {
            abp.message.error(App.localize('McStartDateTooFarFromTicketDate'));
            return;
          }
        }
      }

      if (vm.claim) {
        if (vm.getTreatmentTotalAmount() !== vm.claim.receiptAmount) {
          abp.message.error(App.localize('ClaimReceiptAmountMismatchWithTicketAmount'));
          return;
        }
        if (vm.getCoveredAmount() !== vm.ticket.coveredAmount) {
          prompts.add(
            swal,
            {
              title: App.localize('AreYouSure'),
              text: App.localize('ClaimReceiptAmountMismatchWithTicketCoveredAmountWarning'),
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#1ab394',
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              closeOnConfirm: true,
              closeOnCancel: true,
            },
            (isConfirm) => {
              canSubmit = isConfirm;
            }
          );
        }
      }

      // Validate pre-employment report.

      if (vm.report) {
        $scope.$broadcast('validateForm');
      }

      if ($scope.editForm.$invalid) {
        abp.message.error(App.localize('OneOrMoreInputValueIsInvalid'));
        return;
      }

      // Filter away attachments marked for deletion.

      const attachments = [];
      _.each(vm.attachments, (attachment) => {
        if (!attachment.isDelete) attachments.push(attachment.id);
      });

      // Construct input request.

      if (vm.isLateSubmission) {
        const draft = angular.toJson(vm.ticket);
        vm.ticket.latePatientTicketSubmission = {
          patientSessionId: vm.patientSessionId,
          reason: vm.lateSubmissionReason,
          requestorName: vm.lateSubmissionRequestorName,
          patientTicketDraftJson: draft,
        };
      }

      const input = {
        doctorId: vm.ticket.doctorId,
        treatments: _.filter(
          vm.ticket.treatments,
          (treatment) =>
            // Discard treatment without id or without remark if it is CT ticket.

            treatment.treatmentId > 0
        ),
        manualPayment: vm.getManualPaymentAmount(),
        clinicLocationId: vm.clinic.clinicLocationId,
        medicalCertificate: vm.ticket.medicalCertificate,
        bankingDetails: vm.ticket.bankingDetails,
        manualClinicName: vm.ticket.manualClinicName,
        manualClinicLocation: vm.ticket.manualClinicLocation,
        manualDoctorName: vm.ticket.manualDoctorName,
        clinicType: vm.ticket.clinicType,
        ticketDate: vm.ticketDate,
        ticketType: vm.ticket.ticketType,
        attachments,
        newAttachments: vm.newAttachments,
        latePatientTicketSubmission: vm.ticket.latePatientTicketSubmission
          ? vm.ticket.latePatientTicketSubmission
          : null,
        report: JSON.stringify(vm.report),
        actualVisit: {
          date: vm.ticket.actualVisitDate,
          remark: vm.ticket.actualVisitRemark,
        },
      };
      if (!vm.needMc) {
        input.medicalCertificate = null;
      }

      function createTicket() {
        if (vm.saving !== 0) return;

        vm.saving += 1;
        patientTicketSvc
          .createTicket(
            $.extend(input, {
              employeeId: vm.employeeId,
              claimId: vm.claimId,
            })
          )
          .success((data) => {
            vm.isUpdated = true;

            function promptWarning(title, text) {
              // Validations.

              const cleanTitle = _.isString(title) ? title : '';
              const cleanText = _.isString(text) ? text : '';

              $window.onbeforeunload = () => cleanTitle;

              swal(
                {
                  title: cleanTitle,
                  type: 'warning',
                  text: cleanText,
                  showConfirmButton: true,
                },
                () => {
                  $window.onbeforeunload = null; // unregister listener
                }
              );
              App.swal.disableButtons(5);
            }
            if (vm.ticket.ticketType === 0 && vm.getManualPaymentAmount()) {
              promptWarning(
                App.localize(
                  'RememberCollectXPaymentFromPatient',
                  vm.currencyCode + vm.getManualPaymentAmount()
                )
              );
            } else if (vm.ticket.ticketType === 1) {
              if (vm.getTreatmentTotalAmount() > vm.patient.allowanceDisplay) {
                abp.message.warn(App.localize('ExceededEntitlement'));
              }
            }
            if (data.latePatientTicketSubmissionId) {
              promptLateSubmissionSuccess();
            } else {
              abp.notify.info(App.localize('ClaimXIssued', data.ticketNumber));
              let nextState = 'patientTickets';
              let nextStateData = null;
              if (vm.isClinic) {
                nextState = 'clinic.checkin';
              } else if (data.nextClaimNumber) {
                nextState = 'claimListDetail';
                nextStateData = {
                  claimNumber: data.nextClaimNumber,
                };
              }
              showSuccessModal(
                data.ticketNumber,
                vm.ticket.medicalCertificate !== null,
                nextState,
                nextStateData
              );
            }
          })
          .finally(() => {
            vm.saving -= 1;
          });
      }

      function promptLateSubmissionSuccess() {
        swal(
          {
            title: App.localize('LateSubmissionRequestSent'),
            type: 'success',
            text: `${App.localize('LateSubmissionSuccessNarrative1')}<br />${App.localize(
              'LateSubmissionSuccessNarrative2'
            )}`,
            showConfirmButton: true,
            confirmButtonText: App.localize('Understood'),
            html: true,
          },
          () => {
            window.onbeforeunload = null; // unregister
          }
        );
        $state.go('clinic.panelDashboard');
      }

      function editTicket(remarks) {
        if (vm.saving !== 0) return;

        vm.saving += 1;
        patientTicketSvc
          .editTicketNew(
            $.extend(input, {
              ticketNumber: vm.ticketNumber,
              modificationRemarks: remarks,
              clinicType: $stateParams.clinicType,
            })
          )
          .success(() => {
            vm.isUpdated = true;
            vm.saveSpecialistDetailTrigger += 1;
            abp.notify.info(App.localize('SuccessfullySaved'));
            showSuccessModal(
              vm.ticketNumber,
              vm.ticket.medicalCertificate !== null,
              'patientTickets',
              null
            );
            swal.close();
          })
          .finally(() => {
            vm.saving -= 1;
          });
      }

      if (vm.isCreate) {
        if (vm.ticket.ticketType === 0 && vm.ticket.clinicType !== 4) {
          if (!vm.ticket.medicalCertificate) {
            const warning = vm.isClinic
              ? App.localize('PanelNoMcReminderWarning')
              : App.localize('NoMcReminderWarning');
            prompts.add(abp.message.confirm, warning, App.localize('AreYouSure'), (d) => {
              canSubmit = d;
              return d;
            });
          } else if (!vm.isMcStartDateAlsoCheckedInDate) {
            prompts.add(
              abp.message.confirm,
              App.localize('MCStartDateDifferentFromCheckInDate'),
              App.localize('AreYouSure'),
              (d) => {
                canSubmit = d;
                return d;
              }
            );
          }
        } else if (vm.ticket.ticketType === 3 && !vm.report.courieredDate) {
          prompts.add(
            abp.message.confirm,
            App.localize('NoCourieredDateWarning'),
            App.localize('AreYouSure'),
            (d) => {
              canSubmit = d;
              return d;
            }
          );
        }

        vm.saving += 1;
        prompts.run(() => {
          vm.saving -= 1;
          if (canSubmit) createTicket();
        });
      } else {
        prompts.add(
          swal,
          {
            title: vm.isClinic ? App.localize('EditPanelClaim') : App.localize('EditPatientTicket'),
            text: vm.isClinic
              ? App.localize('WhyEditPanelClaim')
              : App.localize('WhyEditPatientTicket'),
            type: 'input',
            showCancelButton: true,
            closeOnConfirm: false,
            confirmButtonColor: '#1ab394',
            inputPlaceholder: App.localize('PleaseExplain'),
          },
          (inputValue) => {
            vm.saving -= 1;
            vm.saving += 1;
            if (inputValue === false) {
              vm.saving -= 1;
              return false;
            }
            if ($.trim(inputValue || '') === '') {
              swal.showInputError(App.localize('PleaseExplain'));
              return false;
            }

            vm.saving -= 1;
            editTicket($.trim(inputValue));
            return true;
          }
        );

        vm.saving += 1;
        prompts.run();
      }
    }

    function save() {
      createOrEditTicket();
    }

    function showSuccessModal(ticketNumber, hasMc, nextState, nextStateData) {
      let callbackData = null;
      const obj = {
        ticketNumber,
        ticketType: vm.ticket.ticketType,
        hasMc,
        callback(data) {
          callbackData = data;
        },
      };

      $uibModal
        .open({
          templateUrl: require('./createEditSuccessModal.html'),
          controller: 'common.views.patientTickets.createEditSuccessModal as vm',
          backdrop: 'static',
          size: 'sm',
          resolve: {
            ticket() {
              return obj;
            },
          },
        })
        .result.then(() => {
          if (callbackData) {
            $state.go(callbackData.stateName, callbackData.stateData);
          } else {
            $state.go(nextState, nextStateData);
          }
        });
    }

    function resetBloodPressureRemark() {
      vm.report.measurement.bloodPressure.remark = null;
    }

    function saveDraft() {
      if (vm.savingDraft) return;

      const { ticketType } = vm.ticket;

      if (ticketType === 0 || ticketType === 3) {
        if (ticketType === 3) {
          vm.ticket.report = vm.report;
        } else if (!vm.isOutpatientTicket) {
          _.each(vm.ticket.treatments, (t) => {
            t.remark = getRemark(t);
          });
        }

        const ticketDraft = JSON.stringify(vm.ticket);
        const input = {
          employeeId: vm.employeeId,
          clinicLocationId: vm.clinic.clinicLocationId,
          ticketType,
          ticketDraft,
        };

        vm.savingDraft += 1;
        patientTicketSvc
          .saveTicketDraft(input)
          .success(() => {
            abp.notify.info(App.localize('DraftSaved'));
          })
          .finally(() => {
            vm.savingDraft -= 1;
          });
      }
    }

    /* Treatment remark functions */

    function setRemarkTags(t) {
      if (!vm.isOutpatientTicket) {
        t.remarkTags = _.filter(
          _.map(_.split(t.remark || '', ','), (x) => ({
            text: x.trim(),
          })),
          (d) => d.text
        );
      }
    }

    function getRemark(t) {
      return t.remarkTags
        ? _.join(
            _.map(t.remarkTags, (d) => d.text),
            ', '
          )
        : '';
    }

    function hasRemark(treatment) {
      if (vm.isOutpatientTicket) {
        return treatment.remark?.length;
      }
      return treatment.remarkTags?.length;
    }

    /* End of Treatment remark functions */

    function isInvalidAttachment() {
      return (
        vm.ticket.ticketType === 3 &&
        !vm.newAttachments.length &&
        (!vm.attachments.length || _.every(vm.attachments, (d) => d.isDelete))
      );
    }

    function updatePlaceholder($event, treatment) {
      const placeholder = treatment ? vm.getRemarkPlaceholder(treatment) : '';

      $($event.target).find('input').attr('placeholder', placeholder);
    }

    function checkActivePremiumSubscription() {
      vm.loading += 1;
      patientTicketSvc
        .checkActivePremiumSubscription({
          subsidiaryId: vm.patient.corporate.subsidiaryId,
          ticketDate: vm.ticketDate,
        })
        .success((data) => {
          const isChanged = vm.patient.corporate.hasActivePremiumSubscription !== data;
          if (isChanged) {
            vm.patient.corporate.hasActivePremiumSubscription = data;

            // Reset existing and if has active subsription set service fee payor to corporate.

            vm.ticket.bankingDetails.serviceFeePayor = !vm.patient.corporate
              .hasActivePremiumSubscription
              ? vm.patient.corporate.defaultServiceFeePayor
              : 1;
            vm.ticket.bankingDetails.serviceFeePayorRemark = null;
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function clearAll() {
      $state.reload();
    }

    function validateLateSubmission(patientSessionId) {
      if (
        vm.patientSessionId !== patientSessionId ||
        _.isNil($stateParams.lateSubmissionRequestorName) ||
        _.isNil($stateParams.lateSubmissionReason)
      ) {
        abp.message.error(App.localize('MissingLateSubmissionInformation'));
        $state.go('clinic.panelDashboard');
      }
    }

    function checkMultipleCheckInWithSharedPool() {
      vm.loading += 1;
      clinicEmployeeSvc
        .checkHasMultipleCheckInWithSharedPool({
          panelLocationId: vm.clinic.clinicLocationId,
          employeeId: vm.employeeId,
          ticketDate: vm.ticketDate,
          serviceType: vm.ticket.serviceType
        })
        .success((data) => {
          vm.hasMultipleCheckInWithSharedPool = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
