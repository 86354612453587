(() => {
  angular.module('app').component('dateInput', {
    templateUrl: require('./dateInput.html'),
    controller: DateInputController,
    controllerAs: 'vm',
    bindings: {
      date: '=',
      view: '@',
      minView: '@',
      format: '@',
      placeholder: '@',
      maskValue: '@',
      minDate: '<?',
      maxDate: '<?',
      customClearClick: '&?',
      disabled: '<?',
      required: '<?',
      onChange: '&',
      onSwitch: '&',
      name: '@'
    },
  });

  DateInputController.$inject = ['moment'];

  function DateInputController(moment) {
    const vm = this;

    vm.showPicker = false;
    vm.dateString =
      moment.isMoment(vm.date) && vm.date.isValid() ? vm.date.format(vm.format) : null;
    vm.minValid = moment.isMoment(vm.minDate);
    vm.maxValid = moment.isMoment(vm.maxDate);
    vm.changeDate = changeDate;
    vm.onDateChanged = onDateChanged;
    vm.togglePicker = togglePicker;
    vm.customClear = vm.customClearClick && _.isFunction(vm.customClearClick);

    function changeDate(modelName, newDate) {
      if (moment.isMoment(newDate) && newDate.isValid()) {
        vm.date = newDate;
        vm.dateString = newDate.format(vm.format);
      }

      togglePicker();

      // If a vm.dateString is not null, vm.onChange function will be called.
      if (vm.date && vm.onChange) {
        vm.onChange({ scheduledTerminationDate: vm.date });
      }

      if (vm.date && vm.onSwitch) {
        vm.onSwitch({ hospitalizationEffectiveDate: vm.date });
      }
    }

    function onDateChanged() {
      const dateObj = moment(vm.dateString, vm.format, true);
      if (moment.isMoment(dateObj) && dateObj.isValid()) {
        if (checkMin(dateObj)) {
          vm.date = dateObj;
        } 
        if (checkMax(dateObj)) {
          vm.date = dateObj;
        } else {
          vm.dateString = vm.date.format(vm.format);
        }
      }
    }

    function togglePicker() {
      vm.showPicker = !vm.showPicker;
    }

    function checkMin(value) {
      return vm.minValid
        ? moment.isMoment(value) && (vm.minDate.isSame(value) || vm.minDate.isBefore(value))
        : true;
    }

    function checkMax(value) {
      return vm.maxValid
        ? moment.isMoment(value) && (vm.maxDate.isSame(value) || vm.maxDate.isAfter(value))
        :true;
    }

  }
})();
