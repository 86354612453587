(function () {
  var app = angular.module('app');
  app.controller('clinic.views.specialistQuery.query',
    ['$scope', '$state', '$stateParams', 'abp.services.app.serviceRequestQuery', controller]);

  function controller($scope, $state, $stateParams, serviceRequestQuerySvc) {
    var vm = this;
    vm.loading = 0;
    vm.requestParams = {
      letterNumber: $stateParams.letterNumber,
      claimNumber: $stateParams.claimNumber,
      queryNumber: $stateParams.queryNumber,
      timeZoneId: $stateParams.timeZoneId
    }
    vm.saving = 0;
    vm.checklistItems = [];

    vm.submit = submit;
    vm.getDateTimeString = getDateTimeString;

    // Attachment.

    var fileExtensions = '|' + app.consts.fileUpload.imageExtensions.concat(app.consts.fileUpload.pdfExtensions).join('|') + '|';

    vm.uploadConfig = {
      objectType: 'QueryAttachment',
      accept: 'image/*,application/pdf',
      filters: [{
        name: 'fileFilter',
        fn: function (n) {
          var t = '|' + n.type.slice(n.type.lastIndexOf('/') + 1) + '|';
          if (fileExtensions.indexOf(t) === -1) {
            abp.message.warn(App.localize('File_Warn_FileType'));
            return false;
          }
          return true;
        }
      }, {
        name: 'sizeFilter',
        fn: function (n) {
          var sizeLimit = 52428800;
          if (n.size > sizeLimit) {
            abp.message.warn(App.localize('File_Warn_XSizeLimit', sizeLimit / 1024 / 1024));
            return false;
          }
          return true;
        }
      }]
    };

    // End attachment.

    // Functions

    function init() {
      getQuery();
    }

    function getQuery() {
      vm.loading++;
      serviceRequestQuerySvc.getQuery({
        id: vm.requestParams.queryNumber
      }).success(function (data) {
        vm.query = data;
        vm.query.newRepliedAttachments = []
        AddChecklistItems(vm.query.serviceRequestQueryItems);
      }).finally(function () {
        vm.loading--;
      });
    }

    function AddChecklistItems(items) {
      // To check if there is medical query
      // Only add once for all medical queries

      if (_.some(items, function (item) {
          return item.type === 1
        })) {
        vm.checklistItems.push({
          text: App.localize('CompletedMedicalQueryForm'),
          required: true
        });
      }

      // To add missing document names

      _.forEach(items, function (item) {
        if (item.type === 2) {
          _.forEach(item.questions, function (question) {
            if (question.documentName) {
              vm.checklistItems.push({
                text: question.documentName,
                required: true
              });
            }
          });
        }
      });
    }

    function submit() {
      if (!validateForm()) {
        return;
      }

      swal({
        title: App.localize('ConfirmReplyQuerySubmission'),
        text: App.localize('ConfirmQueryReplySubmissionNarrative'),
        type: 'warning',
        confirmButtonColor: '#23c6c8',
        confirmButtonText: App.localize('Yes'),
        cancelButtonText: App.localize('No'),
        showCancelButton: true,
        closeOnConfirm: false,
      }, function (isConfirm) {
        if (isConfirm) {
          replyQuery()
        }
        swal.close();
      });
    }

    function replyQuery() {
      vm.saving++;
      serviceRequestQuerySvc.replyQuery({
        id: vm.query.id,
        newRepliedAttachments: vm.query.newRepliedAttachments
      }).success(function () {
        var message = [];
        message.push(App.localize('SpecialistReplyQuerySuccessNarrative1'));
        message.push(App.localize('SpecialistReplyQuerySuccessNarrative2'));

        swal({
          title: App.localize('SuccessfullySubmitted'),
          text: message.join(' '),
          html: true,
          type: 'success',
          confirmButtonColor: '#23c6c8',
          confirmButtonText: App.localize('ViewQuery'),
          cancelButtonText: App.localize('ReturnToClaimList'),
          showCancelButton: true,
          closeOnConfirm: false,
        }, function (isConfirm) {
          swal.close();

          if (isConfirm) {
            $state.go('viewSpecialistQuery', {
              letterNumber: vm.guaranteeLetterNumber,
              queryNumber: vm.requestParams.queryNumber,
              timeZoneId: vm.requestParams.timeZoneId
            });
          } else {
            $state.go('specialistClaims');
          }
        });
      }).finally(function () {
        vm.saving--;
      });
    }

    function validateForm() {
      vm.invalidAttachment = vm.query.newRepliedAttachments.length === 0;
      vm.invalidChecklist = !App.isFormValid($scope.forms.uploadDocuments);
      return !(vm.invalidAttachment || vm.invalidChecklist);
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }

    init();
  }
})();
