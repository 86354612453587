import swal from 'sweetalert';

(() => {
  angular
    .module('finance')
    .controller(
      'finance.views.manualTransactionRequests.createEditManualTransactionRequest',
      CreateEditManualTransactionRequestController
    );

  CreateEditManualTransactionRequestController.$inject = [
    '$scope',
    '$stateParams',
    '$state',
    'abp.services.finance.manualTransactionRequests',
    'abp.services.finance.commonLookup',
    'abp.services.finance.paymentTerms',
    'abp.services.finance.manualBankAccount',
    'abp.services.finance.termsAndConditions',
    'abp.services.finance.transactionCategory',
    'abp.services.finance.tax',
    'Hms.Finance.TransactionType',
    'Hms.Finance.TransactionTenantType',
    'Hms.Finance.KnownBillCategoryNames',
    'Hms.Finance.ManualTransactionType',
    'Hms.Finance.ManualTransactionRequestStatus',
    'moment',
    'abp.services.app.commonLookup',
    'abp.services.finance.payments',
  ];

  function CreateEditManualTransactionRequestController(
    $scope,
    $stateParams,
    $state,
    manualTransactionRequestsSvc,
    commonLookupSvc,
    paymentTermsSvc,
    manualBankAccountSvc,
    termsAndConditionsSvc,
    transactionCategorySvc,
    taxSvc,
    enumTransactionType,
    enumTransactionTenantType,
    enumKnownBillCategoryNames,
    enumManualTransactionType,
    enumManualTransactionRequestStatus,
    moment,
    portalCommonLookupSvc,
    paymentsSvc
  ) {
    const vm = this;

    vm.loading = 0;
    vm.activeTab = 0;
    vm.isCreate = $stateParams.isCreate;
    vm.isSchedulePost = false;
    vm.today = moment();
    vm.manualTransactionRequestItems = [{}];
    vm.requestId = $stateParams.requestId;
    vm.discount = 0;
    vm.disablePostingDate = false;
    vm.isInstantPost = false;
    vm.totalDiscount = 0;
    vm.canSubmit = vm.manualTransactionRequestItems.every((item) => item.references == null);
    vm.invoices = [];
    vm.finalTab = 1;

    vm.enums = {
      transactionType: enumTransactionType,
      transactionTenantType: enumTransactionTenantType,
      knownBillCategoryNames: enumKnownBillCategoryNames,
      manualTransactionType: enumManualTransactionType,
      requestStatus: enumManualTransactionRequestStatus,
    };

    vm.lookupBillingAccount = lookupBillingAccount;
    vm.setAccountOwner = setAccountOwner;
    vm.getTransactionCategories = getTransactionCategories;
    vm.getCategoryRelatedData = getCategoryRelatedData;
    vm.next = next;
    vm.previous = previous;
    vm.addItems = addItems;
    vm.clearItems = clearItems;
    vm.localize = localize;
    vm.setRequestItem = setRequestItem;
    vm.deleteRequestItem = deleteRequestItem;
    vm.setRequestItemTax = setRequestItemTax;
    vm.setManualTransactionType = setManualTransactionType;
    vm.calculateRequestItemSubTotal = calculateRequestItemSubTotal;
    vm.calculateRequestItemTotal = calculateRequestItemTotal;
    vm.calculateTransactionDiscount = calculateTransactionDiscount;
    vm.calculateTotalDiscount = calculateTotalDiscount;
    vm.resetDiscount = resetDiscount;
    vm.calculateSubTotal = calculateSubTotal;
    vm.calculateTax = calculateTax;
    vm.calculateTotal = calculateTotal;
    vm.schedulePost = schedulePost;
    vm.saveDraft = saveDraft;
    vm.update = updateManualTransactionRequest;
    vm.delete = deleteManualTransactionRequest;
    vm.replaceHtmlTagSymbol = replaceHtmlTagSymbol;
    vm.resetPostingDate = resetPostingDate;
    vm.checkReferences = checkReferences;
    vm.getInvoices = getInvoices;
    vm.getAppliedAmount = getAppliedAmount;
    vm.payFull = payFull;
    vm.getDateString = getDateString;

    init();

    function init() {
      localizeTransactionTenantType();
      getAccountOwners();
      if (vm.isCreate) {
        if ($stateParams.tenancyName) {
          getRelatedBillingAccount();
        } else {
          lookupBillingAccount();
        }
      } else {
        getManualTransactionRequest();
      }
    }

    function localizeTransactionTenantType() {
      _.forEach(vm.enums.transactionTenantType, (transactionTenantType) => {
        transactionTenantType.displayName = App.localize(transactionTenantType.name);
      });
    }

    function lookupBillingAccount(search, billingAccountId) {
      const keyword = _.trim(search || '');

      if (keyword.length > 0 && keyword.length < 2) return;

      vm.loading += 1;
      commonLookupSvc
        .getBillingAccounts({
          filter: keyword,
          maxResultCount: 20,
          tenantType: vm.transactionTenantType,
          id: billingAccountId,
          isPaymentOrManualTransactionPage: true,
        })
        .success((data) => {
          if (data) {
            vm.billingAccounts = data.items;
            if (billingAccountId) {
              vm.billingAccount = _.find(vm.billingAccounts, ['id', billingAccountId]);
              vm.accountOwner = _.find(
                vm.accountOwners,
                (x) => x.id === vm.billingAccount.accountOwnerId
              );
            }
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function replaceHtmlTagSymbol(string) {
      return string.replace('<', '&#10092;').replace('>', '&#10093;');
    }

    function getManualTransactionRequest() {
      vm.loading += 1;
      manualTransactionRequestsSvc
        .getManualTransactionRequest({ Id: vm.requestId })
        .success((data) => {
          vm.manualTransactionRequest = data;
          populateFields(vm.manualTransactionRequest);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function populateFields(request) {
      vm.loading += 1;
      commonLookupSvc
        .getBillingAccounts({
          id: request.billingAccountId,
        })
        .success((data) => {
          if (data) {
            [vm.billingAccount] = data.items;
            vm.currencyCode = vm.billingAccount.currencyCode;
            vm.transactionTenantType = getTransactionTenantTypeDisplayName(request.tenantType);
            vm.transactionCategoryId = request.transactionCategoryId;
            vm.paymentTerm = request.paymentTermId;
            vm.isTaxable = request.isTaxable;
            vm.manualRequestCategoryDescription = request.description;
            vm.bankAccountId = request.bankAccountId;
            vm.chosenTermsAndConditionsId = request.termsAndConditionsId;
            vm.discount = request.discount;
            vm.discountType = request.discountType;
            vm.total = request.amount;
            vm.transactionDate = moment(request.transactionDate);
            vm.postingDate = request.postingDate === null ? null : moment(request.postingDate);
            vm.status = _.find(vm.enums.requestStatus, (x) => x.id === request.status);
            vm.emailNotification = request.emailNotification;
            vm.manualTransactionRequestItems = request.items;
            vm.isDraft = vm.status.id === vm.enums.requestStatus.Draft.id;
            vm.creditedInvoices = request.invoices;

            _.forEach(vm.manualTransactionRequestItems, (item) => {
              item.subTotal = item.quantity * item.pricePerUnit;
            });

            setAccountOwner(vm.billingAccount.accountOwnerId, true);
            getCategoryRelatedData(vm.manualTransactionRequest.transactionCategoryId);
            setManualTransactionType(request.transactionType);
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getAccountOwners() {
      commonLookupSvc.getAccountOwners().success((data) => {
        vm.accountOwners = data.items;
      });
    }

    function setAccountOwner(accountOwnerId, isEdit) {
      vm.accountOwner = _.find(vm.accountOwners, (x) => x.id === accountOwnerId);
      if (!isEdit)
        vm.manualTransactionType = null;

      if (vm.accountOwner) {
        getManualTransactionTenantTypeByBillingAccount(vm.billingAccount.id);
        getManualTransactionTypes(vm.billingAccount.type);
        getTransactionCategories(vm.accountOwner.id);
        getPaymentTerms(vm.accountOwner.id);
        getTermsAndConditions(vm.accountOwner.id);
        getAccountOwnerTaxes(vm.accountOwner.id);
        setCurrencyCodeAndBillingAddress(vm.billingAccount);
        if (vm.isCreate) {
          getFinanceEmailsByTenancyName(vm.billingAccount.businessIdentifierCode);
        }
      }
    }

    function getManualTransactionTenantTypeByBillingAccount(billingAccountId) {
      vm.loading += 1;
      commonLookupSvc
        .getManualTransactionTenantTypeByBillingAccount({ id: billingAccountId })
        .success((data) => {
          vm.transactionTenantType = App.localize(data.output);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getManualTransactionTypes(type) {
      vm.manualTransactionTypes = vm.enums.manualTransactionType.values.map((x) => x);
      if (type === 1) {
        _.remove(vm.manualTransactionTypes, (x) => x.id === 0);
        _.remove(vm.manualTransactionTypes, (x) => x.id === 2);

      }
      else if (type === 2) {
        _.remove(vm.manualTransactionTypes, (x) => x.id === 1);
        _.remove(vm.manualTransactionTypes, (x) => x.id === 3);
      } 
    }

    function getTransactionCategories(accountOwnerId) {
      vm.loading += 1;
      commonLookupSvc
        .getTransactionCategoriesByAccountOwner({ accountOwnerId })
        .success((data) => {
          vm.transactionCategories = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getCategoryRelatedData(id) {
      vm.loading += 1;
      transactionCategorySvc
        .getTransactionCategory({ id })
        .success((data) => {
          vm.transactionCategory = data;
          vm.categoryDescriptions = data.transactionCategoryDescriptions;
          vm.categoryItems = data.transactionCategoryItems;

          if (!vm.isCreate) {
            _.forEach(vm.manualTransactionRequestItems, (item) => {
              item.accountCode = _.find(
                vm.categoryItems,
                (x) => x.id === item.transactionCategoryItemId
              ).accountCode;
            });
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function setRequestItem(categoryItemId, index) {
      if (!categoryItemId) {
        vm.manualTransactionRequestItems[index].transactionCategoryItemId = null;
        vm.manualTransactionRequestItems[index].accountCode = null;
        return;
      }

      const categoryItem = _.find(vm.categoryItems, (x) => x.id === categoryItemId);

      vm.manualTransactionRequestItems[index].transactionCategoryItemId = categoryItem.id;
      vm.manualTransactionRequestItems[index].accountCode = categoryItem.accountCode;
    }

    function getPaymentTerms(accountOwnerId) {
      vm.loading += 1;
      paymentTermsSvc
        .getPaymentTerms({ accountOwnerId })
        .success((data) => {
          vm.paymentTerms = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getBankingDetails(ownerId) {
      vm.loading += 1;
      manualBankAccountSvc
        .getBankAccounts({ ownerId })
        .success((data) => {
          vm.bankAccounts = data.bankAccounts;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTermsAndConditions(id) {
      vm.loading += 1;
      termsAndConditionsSvc
        .getTermsAndConditions({ id })
        .success((data) => {
          vm.termsAndConditions = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getAccountOwnerTaxes(accountOwnerId) {
      vm.loading += 1;
      taxSvc
        .getAccountOwnerTaxes({ accountOwnerId })
        .success((data) => {
          vm.accountOwnerTaxes = data;

          if (!vm.isCreate) {
            _.forEach(vm.manualTransactionRequestItems, (item) => {
              item.taxPercentage = _.find(
                vm.accountOwnerTaxes,
                (x) => x.taxCode === item.taxCode
              ).percentage;
            });
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTransactionTenantTypeDisplayName(tenantType) {
      switch (tenantType) {
        case 0:
          return App.localize(vm.enums.transactionTenantType.Organization);
        case 1:
          return App.localize(vm.enums.transactionTenantType.Panel);
        case 2:
          return App.localize(vm.enums.transactionTenantType.Member);
        case 3:
          return App.localize(vm.enums.transactionTenantType.Agent);
        case 4:
          return App.localize(vm.enums.transactionTenantType.Insurer);
        default:
          return 'Unknown tenant type';
      }
    }

    function setCurrencyCodeAndBillingAddress(billingAccount) {
      vm.currencyCode = billingAccount.currencyCode;
      vm.billingAddress = billingAccount.billingAddress;
    }

    function setRequestItemTax(accountOwnerTaxCode, index) {
      if (!accountOwnerTaxCode) {
        vm.manualTransactionRequestItems[index].taxCode = null;
        vm.manualTransactionRequestItems[index].taxAmount = null;
        vm.manualTransactionRequestItems[index].taxPercentage = null;
        vm.manualTransactionRequestItems[index].taxDescription = null;
        calculateRequestItemTotal(index);
        calculateTotal();
        return;
      }

      const accountOwnerTax = _.find(
        vm.accountOwnerTaxes,
        (x) => x.taxCode === accountOwnerTaxCode
      );

      vm.manualTransactionRequestItems[index].taxCode = accountOwnerTax.taxCode;
      vm.manualTransactionRequestItems[index].taxPercentage = accountOwnerTax.percentage;
      vm.manualTransactionRequestItems[index].taxDescription = accountOwnerTax.description;
    }

    function setManualTransactionType(id) {
      vm.manualTransactionType = _.find(vm.enums.manualTransactionType, (x) => x.id === id);
      vm.invoices = [];
      if (vm.manualTransactionType.id === 2 || vm.manualTransactionType.id === 3) {
        vm.finalTab = 2;
        vm.bankAccounts = null;
      }
      else {
        vm.finalTab = 1;
        getBankingDetails(vm.accountOwner.id);
      }
    }

    function calculateRequestItemSubTotal(index) {
      const { quantity } = vm.manualTransactionRequestItems[index];
      const { pricePerUnit } = vm.manualTransactionRequestItems[index];

      const preDiscountAmount = Math.round(quantity * pricePerUnit * 100) / 100;

      if (vm.manualTransactionRequestItems[index].discount > 0) {
        const { discount } = vm.manualTransactionRequestItems[index]; // Request item discount.
        const { discountType } = vm.manualTransactionRequestItems[index]; // Request item discount type.

        switch (discountType) {
          case 1: // Per-item percentage discount.
            vm.manualTransactionRequestItems[index].subTotal =
              Math.round(preDiscountAmount * (1 - discount / 100) * 100) / 100;
            break;
          case 2: // Per-item amount discount.
            vm.manualTransactionRequestItems[index].subTotal =
              Math.round((preDiscountAmount - discount) * 100) / 100;
            break;
          default:
            break;
        }
      } else {
        vm.manualTransactionRequestItems[index].subTotal = preDiscountAmount;
      }

      calculateTransactionDiscount(vm.discount, vm.discountType, index);
      return vm.manualTransactionRequestItems[index].subTotal;
    }

    function calculateTransactionDiscount(discount, discountType, index) {
      if (discount === 0 || discountType == null) return;

      const requestItemCount = vm.manualTransactionRequestItems.length;
      const currentSubTotal = vm.manualTransactionRequestItems[index].subTotal;

      switch (discountType) {
        case 1: // Per-transaction percentage discount.
          // Apply the discount % to each item.

          vm.manualTransactionRequestItems[index].subTotal =
            Math.round((currentSubTotal * (1 - discount / 100)) * 100) / 100;

          break;
        case 2: // Per-transaction amount discount.
          // Divide the total discount amount by the number of items,
          // and subtract the amount from each item's previous subtotal.

          vm.manualTransactionRequestItems[index].subTotal =
            Math.round((currentSubTotal - (discount / requestItemCount)) * 100) / 100;

          break;
        default:
          break;
      }
    }

    function calculateTotalDiscount() {
      return Math.round((calculatePreDiscountTotal() - calculateSubTotal()) * 100) / 100;
    }

    function calculateRequestItemTotal(index) {
      const { taxPercentage } = vm.manualTransactionRequestItems[index];
      const { subTotal } = vm.manualTransactionRequestItems[index];

      vm.manualTransactionRequestItems[index].taxAmount =
        Math.round(subTotal * (taxPercentage / 100) * 100) / 100;

      vm.manualTransactionRequestItems[index].total =
        Math.round(subTotal * (1 + taxPercentage / 100) * 100) / 100;

      return vm.manualTransactionRequestItems[index].total;
    }

    function calculatePreDiscountTotal() {
      const itemSubTotalPreDiscount = [];

      _.forEach(vm.manualTransactionRequestItems, (item) =>
        itemSubTotalPreDiscount.push(item.quantity * item.pricePerUnit)
      );

      return _.sum(itemSubTotalPreDiscount);
    }

    function resetDiscount() {
      vm.discount = 0;
      vm.discountType = null;
    }

    function resetPostingDate() {
      vm.postingDate = '';
    }

    function calculateSubTotal() {
      return _.sumBy(vm.manualTransactionRequestItems, (x) => x.subTotal);
    }

    function calculateTax() {
      return _.sumBy(vm.manualTransactionRequestItems, (x) => x.taxAmount);
    }

    function calculateTotal() {
      return calculateSubTotal() + calculateTax();
    }

    function next() {
      vm.activeTab += 1;
      if (vm.activeTab===2)
        getInvoices();
    }

    function previous() {
      vm.activeTab -= 1;
    }

    function addItems() {
      vm.manualTransactionRequestItems.push({});
      vm.canSubmit = false;
    }

    function clearItems() {
      vm.manualTransactionRequestItems = [];
      vm.manualTransactionRequestItems.push({});
      vm.canSubmit = false;
    }

    function deleteRequestItem(index) {
      if (vm.manualTransactionRequestItems.length > 1) {
        vm.manualTransactionRequestItems.splice(index, 1);
      } else {
        vm.manualTransactionRequestItems = [];
        addItems();
      }
    }

    function schedulePost() {
      const isValid = App.isFormValid($scope.createManualTransactionRequestForm);

      if (!isValid) return;

      const titleText = !vm.isInstantPost ? App.localize('SchedulePost') : App.localize('PostNow');
      const bodyText = !vm.isInstantPost
        ? App.localize('ScheduleTransactionNarrative', moment(vm.postingDate).format('DD-MM-YYYY'))
        : App.localize('AreYouSure');

      swal(
        {
          title: titleText,
          text: bodyText,
          type: 'warning',
          confirmButtonText: App.localize('Confirm'),
          cancelButtonText: App.localize('Cancel'),
          showCancelButton: true,
          closeOnConfirm: true,
          closeOnCancel: true,
        },
        (isConfirm) => {
          vm.isSchedulePost = true;
          if (isConfirm && vm.isCreate) {
            createManualTransactionRequest();
          } else if (isConfirm && !vm.isCreate) {
            updateManualTransactionRequest();
          } else {
            swal.close();
          }
        }
      );
    }

    function saveDraft() {
      const isValid = App.isFormValid($scope.createManualTransactionRequestForm);

      if (!isValid) return;

      if (!vm.manualTransactionRequest) createManualTransactionRequest();
      else updateManualTransactionRequest();
    }

    function getInput() {
      _.forEach(vm.manualTransactionRequestItems, (item) => {
        delete item.categoryItem;
        delete item.tax;
      });

      const input = {
        billingAccountId: vm.billingAccount.id,
        tenantType: vm.transactionTenantType.id,
        transactionType: vm.manualTransactionType.id,
        amount: calculateTotal(),
        isCreate: vm.isCreate,
        isSchedulePost: vm.isSchedulePost,
        isInstantPost: vm.isInstantPost,
        postingDate: vm.postingDate,
        transactionCategoryId: vm.transactionCategoryId,
        paymentTermId: vm.paymentTerm,
        isTaxable: vm.isTaxable,
        transactionDate: vm.transactionDate,
        bankAccountId: vm.bankAccountId,
        termsAndConditionsId: vm.chosenTermsAndConditionsId,
        discount: vm.discount,
        discountType: vm.discountType,
        emailNotification: vm.emailNotification,
        items: vm.manualTransactionRequestItems,
        description: vm.manualRequestCategoryDescription,
        invoices: vm.invoices
      };

      if (!vm.isCreate) input.id = vm.manualTransactionRequest.id;

      return input;
    }

    function createManualTransactionRequest() {
      const input = getInput();

      vm.saving += 1;
      manualTransactionRequestsSvc
        .createManualTransactionRequest(input)
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
        })
        .finally(() => {
          vm.saving -= 1;
          $state.go('finance.manualTransactionRequests');
        });
    }

    function updateManualTransactionRequest() {
      const isValid = App.isFormValid($scope.createManualTransactionRequestForm);

      if (!isValid) return;

      const input = getInput();

      vm.saving += 1;
      manualTransactionRequestsSvc
        .updateManualTransactionRequest(input)
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
        })
        .finally(() => {
          vm.saving -= 1;
          $state.go('finance.manualTransactionRequests');
        });
    }

    function deleteManualTransactionRequest() {
      vm.saving += 1;

      const message = 'This manual transaction request will be deleted';

      abp.message.confirm(message, App.localize('AreYouSure'), (d) => {
        if (d) {
          manualTransactionRequestsSvc
            .deleteManualTransactionRequest({ id: vm.manualTransactionRequest.id })
            .success(() => {
              abp.notify.info(App.localize('SuccessfullyDeleted'));
            })
            .finally(() => {
              vm.saving -= 1;
              $state.go('finance.manualTransactionRequests');
            });
        } else {
          vm.saving -= 1;
        }
      });
    }

    function localize(name) {
      return App.localize(name);
    }

    function getRelatedBillingAccount() {
      vm.loading += 1;
      commonLookupSvc
        .getTenantBillingAccounts({
          tenancyName: $stateParams.tenancyName,
          tenantType: $stateParams.transactionTenantType,
          nationalId: $stateParams.nationalId,
          subsidiaryId: $stateParams.subsidiaryId,
          isBilledPerSubsidiary: $stateParams.isBilledPerSubsidiary,
        })
        .success((billingAccounts) => {
          if (billingAccounts.length < 1) {
            abp.message.warn('No related billing account found. Please select one from the list.');
            lookupBillingAccount();
          } else {
            vm.billingAccounts = billingAccounts;
            [vm.billingAccount] = billingAccounts;
            setAccountOwner(vm.billingAccount.accountOwnerId, false);
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getFinanceEmailsByTenancyName(tenancyName) {
      vm.loading += 1;
      portalCommonLookupSvc
        .getFinanceEmailsByTenancyName({
          tenancyName,
        })
        .success((data) => {
          vm.emailNotification = data.financeEmailAddresses;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function checkReferences() {
      vm.referencesInput = [];

      _.forEach(vm.manualTransactionRequestItems, (manualTransactionRequestItem) => {
        if (typeof manualTransactionRequestItem.references !== 'undefined') {
          vm.referencesInput.push(manualTransactionRequestItem.references);
        } else {
          vm.referencesInput.push('');
        }
      });

      vm.loading += 1;
      manualTransactionRequestsSvc
        .checkReferences({
          references: vm.referencesInput,
        })
        .success((data) => {
          vm.canSubmit = data.allReferencesExists;

          let invalidReferenceCount = 0;
          let loopCount = 0;
          _.forEach(vm.manualTransactionRequestItems, (item) => {
            const element = document.getElementById(`reference${loopCount}`);
            const reference = _.find(data.references, ['reference', item.references]);

            if (reference != null) {
              if (!reference.isExists) {
                invalidReferenceCount += 1;
                element.classList.add('errorBorder');
              } else {
                element.classList.remove('errorBorder');
              }
            } else {
              element.classList.remove('errorBorder');
            }
            loopCount += 1;
          });

          if (data.allReferencesExists) {
            abp.notify.info(App.localize('AllReferencesExists'));
          } else {
            abp.notify.error(
              App.localize('FailedToValidateThereAreATotalOfXErrors', invalidReferenceCount)
            );
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getInvoices() {
      vm.loading += 1;
      paymentsSvc
        .getInvoices({
          BillingAccountId: vm.billingAccount.id,
          isPayable: vm.billingAccount.type === 2,
          IsManualTransactionRequest: true
        })
        .success((data) => {
          vm.invoices = data.invoices;

          if (!vm.isCreate && vm.creditedInvoices.length > 0) {
            _.forEach(vm.creditedInvoices, (creditedInvoice) => {
              vm.invoices = vm.invoices.filter((i) => i.id !== creditedInvoice.id);
            })
            vm.creditedInvoices.forEach(creditedInvoice => vm.invoices.push(creditedInvoice));
          }
        })
        .finally(() => {
          vm.loading -= 1;
        })
    }

    function getAppliedAmount() {
      return Math.round((_.sumBy(vm.invoices, (x) => x.appliedAmount)) * 100) / 100;
    } 

    function payFull(transaction) {
      const total = calculateTotal();
      if (total === 0) return;

      const t = transaction;
      let allInvoices = vm.invoices.slice();
      let otherInvoicesAppliedAmount = 0;

      // Sum up the applied amount on all other invoices.

      allInvoices = _.remove(allInvoices, (invoice) => invoice.id !== t.id);
      _.forEach(allInvoices, (invoice) => {
        otherInvoicesAppliedAmount += invoice.appliedAmount;
      });

      t.appliedAmount =
        total - otherInvoicesAppliedAmount >= t.openBalance
          ? t.openBalance
          : total - otherInvoicesAppliedAmount;

      // Round the applied amount to 2 decimal places.

      t.appliedAmount = Number(t.appliedAmount.toFixed(2));
    }

    function getDateString(date) {
      return App.getDateString(moment.utc(date));
    }
  }
})();
