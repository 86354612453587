(function () {
  'use strict';

  const app = angular.module('app');
    app.controller('host.views.claims.inpatientClaims.fglProcessing', ['$scope', '$state', '$uibModal', '$stateParams',
      'abp.services.app.hostServiceRequest', 'abp.services.app.commonLookup', 'moment', controller
    ]);

  function controller($scope, $state, $modal, $stateParams, hostServiceRequestSvc, commonLookupSvc, moment) {
    function init() {
      vm.loading++;
      hostServiceRequestSvc.getFglRequestDetails({
        id: vm.letterNumber
      }).success(function (data) {
        vm.isLoading = true;
        vm.requestInfo = data;
        vm.printUrl = data.printUrl;
        vm.isOnHold = data.request.isOnHold;
        vm.hasUnresolvedQuery = data.request.hasUnresolvedQuery;
        vm.isMaternity = data.request.serviceType === 8;
        vm.claimNumber = $stateParams.claimNumber;
        vm.isInsuranceBenefitGroup = data.patient.isInsuranceBenefitGroup;
        vm.currencyCode = data.request.currencyCode;
        vm.countryCode = data.request.countryCode;
      }).finally(function () {
        vm.loading--;
      });
    }

    var vm = this;
    vm.loading = 0;
    vm.isHost = App.isHost();
    vm.letterNumber = $stateParams.letterNumber;
    vm.timeZoneId = $stateParams.timeZoneId;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.requestInfo = {};
    vm.hospitalizationBenefits = [];
    vm.isOnHold = false;
    vm.isFormFilled = false;
    vm.isInsuranceBenefitGroup = false;

    vm.coPay = {
      isCoInsurance: false,
      isPercentage: false,
      value: 0
    };

    vm.requestDetailsTrigger = 0;
    vm.checkSaveTrigger = checkSaveTrigger;
    vm.checkEditMode = checkEditMode;
    vm.openApprovalModal = openApprovalModal;
    vm.openRejectionModal = openRejectionModal;
    vm.openDelegationModal = openDelegationModal;
    vm.openRecommendationModal = openRecommendationModal;
    vm.openAssigneeModal = openAssigneeModal;
    vm.openEscalationModal = openEscalationModal;
    vm.saveDraft = saveDraft;
    vm.preview = preview;
    vm.query = query;
    vm.checkFormFilled = checkFormFilled;
    vm.requestAmount = null;
    vm.totalCoveredAmount = null;

    var delegateUsers = [];
    var recommendUsers = [];

    vm.requestType = 'Final';
    vm.requestStatus = 'Pending approval'

    // Check if inpatient benefit type is maternity

    vm.isMaternity = false;
    vm.isTallied = true;
    vm.isFinalBillTallied = true;

    vm.coPay = {
      isRoomAndBoardExceedEntitlement: false,
      isCoInsurance: false,
      isPercentage: false,
      coPayExclusions: [],
      value: 0
    };

    vm.roomAndBoardCharges = 0;
    vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );

    function checkEditMode(isEdit) {
      vm.isEdit = isEdit;
    }

    function checkSaveTrigger() {
      vm.requestDetailsTrigger++;
    }

    function getRequestInput(isApproving, approverRemarks) {
      var roomItems = [],
        procedureItems = [],
        otherItems = [],
        doctorProcedureItems = [],
        hospitalChargesItems = [],
        emergencyOutpatientTreatments = [],
        miscellaneousTreatments = [];
      var ticketItems = [];

      _.each(vm.requestInfo.request.rooms, function (x) {
        _.each(x.ticketItems, function (y) {
          y.startDate = y.roomChargeDateRangeModel.startDate ? moment(y.roomChargeDateRangeModel.startDate).utc() : null;
          y.endDate = y.roomChargeDateRangeModel.endDate ? moment(y.roomChargeDateRangeModel.endDate).utc() : null;

          // Skip add records if category is null and approving the request.

          if (!isApproving) {
            y.category = x.category;
          }

          if (typeof y.category !== 'undefined' && y.category !== null) {
            roomItems.push(y);
          }
        });
      });

      if (vm.hasInsuranceEnhancementModule && !vm.isMaternity) {
        roomItems = formatTicketItems(
          vm.requestInfo.request.rooms,
          isApproving,
          (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: x.section,
            categoryName: x.category,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
            startDate: x.roomChargeDateRangeModel.startDate
              ? moment(x.roomChargeDateRangeModel.startDate).utc()
              : null,
            endDate: x.roomChargeDateRangeModel.endDate
              ? moment(x.roomChargeDateRangeModel.endDate).utc()
              : null,
            dailyCharges: x.dailyCharges,
            lengthOfStay: x.lengthOfStay
          }));

        procedureItems = formatTicketItems(
          vm.requestInfo.request.procedures,
          isApproving,
          (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: x.section,
            categoryName: x.doctorType,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
            startDate: x.visitDate ? moment(x.visitDate).utc() : null,
            endDate: x.visitDate ? moment(x.visitDate).utc() : null,
            doctorId: x.doctorId,
            totalVisit: 1,
            visit: x.visit
          }));

        doctorProcedureItems = formatTicketItems(
          vm.requestInfo.request.doctorProcedures,
          isApproving,
          (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: x.section,
            categoryName: x.category,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
            startDate: x.visitDate ? moment(x.visitDate).utc() : null,
            endDate: x.visitDate ? moment(x.visitDate).utc() : null,
            doctorId: x.doctorId
          }));

        hospitalChargesItems = formatTicketItems(
          vm.requestInfo.request.hospitalCharges,
          isApproving,
          (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: x.section,
            categoryName: x.category,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
          }));

        emergencyOutpatientTreatments = formatTicketItems(
          vm.requestInfo.request.emergencyOutpatientTreatments,
          isApproving,
          (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: x.section,
            categoryName: x.mainTreatment,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
          }));

        miscellaneousTreatments = formatTicketItems(
          vm.requestInfo.request.miscellaneousTreatments,
          isApproving,
          (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: x.section,
            categoryName: x.mainTreatment,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
          }));

          ticketItems = _.unionWith(
            roomItems,
            procedureItems,
            doctorProcedureItems,
            hospitalChargesItems,
            emergencyOutpatientTreatments,
            miscellaneousTreatments);
      } else if (vm.hasSobEnhancement && !vm.isMaternity) {
        _.each(vm.requestInfo.request.procedures, function (x) {
          _.each(x.ticketItems, function (y) {
            y.startDate = y.visitDate ? moment(y.visitDate).utc() : null;
            y.endDate = y.visitDate ? moment(y.visitDate).utc() : null;
            y.doctorType = x.doctorType;
            y.doctorId = x.doctorId;

            // Total visit is always 1.

            if (!y.totalVisit || y.totalVisit !== 1)
              y.totalVisit = 1;

            if ((typeof y.doctorId !== 'undefined' && y.doctorId !== null && y.doctorType === 2 && vm.requestInfo.request.serviceType === 7) ||
              (y.doctorType === 1 && vm.requestInfo.request.serviceType === 7) ||
              (typeof y.category !== 'undefined' && y.category !== null && vm.requestInfo.request.serviceType === 8) ||
              !isApproving) {
              procedureItems.push(y);
            }
          });
        });

        _.each(vm.requestInfo.request.doctorProcedures, function (x) {
          _.each(x.ticketItems, function (y) {

            y.startDate = y.visitDate ? moment(y.visitDate).utc() : null;
            y.endDate = y.visitDate ? moment(y.visitDate).utc() : null;

            if ((typeof y.category !== 'undefined' && y.category !== null) || !isApproving) {
              doctorProcedureItems.push(y);
            }
          });
        });

        _.each(vm.requestInfo.request.hospitalCharges, function (x) {
          _.each(x.ticketItems, function (y) {
            if ((typeof y.category !== 'undefined' && y.category !== null) || !isApproving) {
              hospitalChargesItems.push(y);
            }
          });
        });

        ticketItems = _.unionWith(
          roomItems,
          procedureItems,
          doctorProcedureItems,
          hospitalChargesItems);
      } else {
        _.each(vm.requestInfo.request.procedures, function (x) {
          _.each(x.ticketItems, function (y) {
            y.startDate = y.procedureChargeDateRangeModel ? moment(y.procedureChargeDateRangeModel.startDate).utc() : null;
            y.endDate = y.procedureChargeDateRangeModel ? moment(y.procedureChargeDateRangeModel.endDate).utc() : null;

            // Skip add records if category is null and approving the request.

            if (!isApproving) {
              y.category = x.category;
            }

            if ((typeof y.category !== 'undefined' && y.category !== null) || !isApproving) {
              procedureItems.push(y);
            }
          });
        });

        _.each(vm.requestInfo.request.others, function (x) {
          if ((typeof x.category !== 'undefined' && x.category !== null) || !isApproving) {
            otherItems.push(x);
          }
        });

        ticketItems = _.unionWith(roomItems, procedureItems, otherItems);
      }

      const cleanedDiagnoses = _.map(vm.requestInfo.request.diagnoses, (d) =>
        // Free-text entry has null diagnosis code.

         ({
          code: _.isNumber(d.code) ? null : d.code,
          description: d.description,
          diagnosisDate: d.diagnosisDate
        })
      );

      return {
        id: vm.requestInfo.request.id,
        remarks: approverRemarks,
        isApprove: isApproving,
        currentAllowance: vm.employeeAllowance,
        request: {
          finalBillAmount: vm.requestInfo.request.finalBillAmount,
          admissionDate: vm.requestInfo.request.admissionDate,
          dischargeDate: vm.requestInfo.request.dischargeDate,
          doctorIds: vm.requestInfo.request.doctorIds,
          diagnoses: cleanedDiagnoses,
          documents: vm.requestInfo.request.documents,
          medicalCertificate: vm.requestInfo.request.medicalCertificate,
          ticketItems: ticketItems,
          hospitalizationDays: vm.requestInfo.request.hospitalizationDays,
          hospitalizationLeaveDates: vm.requestInfo.request.hospitalizationLeaveDates
        }
      };
    }

    function formatTicketItems(treatments, isApproving, format) {
      const items = []

      _.each(treatments, (x) => {
        _.each(x.ticketItems, (y) => {
          if (isApproving && !y.mainTreatment && !y.category && !y.doctorType) return;

          const ticketItem = angular.copy(y);
          items.push(format(ticketItem));
        });
      });

      return items;
    }

    function processRequest(isApproving, approverRemarks) {
      if (!isApproving) {
        $scope.$broadcast('validateProcessingInput');
        if (!vm.isFormFilled) {
          abp.message.error(App.localize('OneOrMoreInputValueIsInvalid'));
          return;
        }
      }

      var input = getRequestInput(isApproving, approverRemarks);
      vm.loading++;
      hostServiceRequestSvc.processFglRequest(input)
        .success(function () {
          if (isApproving) {
            abp.notify.success(App.localize('Approved'));
            navigateToListing();
          } else {
            abp.notify.info(App.localize("SuccessfullySaved"));
          }
        }).error(function (data) {
          /* Error code 1 == Insufficient allowance */
          if (data.code == 1) {
            /* Insufficient employee balance for request amount */
            swal({
              title: App.localize('InvalidAmount'),
              text: App.localize('InsufficientEmployeeBalanceNarrative') + '\n' +
                App.localize('RefreshThePageAndEditTheAmount'),
              confirmButtonColor: '#1AB394',
              confirmButtonText: App.localize('RefreshNow')
            }, function (isConfirm) {
              if (isConfirm) {
                location.reload();
              }
            })
          }
        }).finally(function () {
          vm.loading--;
        });
    }

    function approve(remarks) {
      processRequest(true, remarks);
    }

    function reject(remarks) {
      vm.loading++;
      hostServiceRequestSvc.rejectGuaranteeLetterServiceRequest({
        id: vm.requestInfo.request.id,
        letterNumber: vm.letterNumber,
        rejectionReason: remarks
      }).success(function () {
        abp.notify.success(App.localize('Rejected'));
        navigateToListing();
      }).finally(function () {
        vm.loading--;
      });
    }

    function delegate(recipient, remarks) {
      vm.loading++;
      hostServiceRequestSvc.delegateRequest({
        processorUserId: recipient.operatorUserId,
        serviceRequestId: vm.requestInfo.request.id,
        letterNumber: vm.letterNumber,
        emailAddress: recipient.operatorEmailAddress,
        remarks: remarks
      }).success(function () {
        abp.notify.success(App.localize('ServiceRequestDelegated'));
        navigateToListing();
      }).finally(function () {
        vm.loading--;
      });
    }

    function recommend(recipient, remarks) {
      vm.loading++;
      hostServiceRequestSvc.recommendRequest({
        processorUserId: recipient.operatorUserId,
        serviceRequestId: vm.requestInfo.request.id,
        letterNumber: vm.letterNumber,
        emailAddress: recipient.operatorEmailAddress,
        remarks: remarks
      }).success(function () {
        abp.notify.success(App.localize('ServiceRequestRecommended'));
        navigateToListing();
      }).finally(function () {
        vm.loading--;
      });
    }

    function saveDraft() {
      processRequest(false, '')
    }

    function checkFormFilled(data) {
      vm.isFormFilled = data;
    }

    function openApprovalModal() {
      // Validation on input.

      $scope.$broadcast('validateProcessingInput');

      if (vm.isFormFilled) {
        if (!vm.isTallied) {
          vm.isFinalBillTallied = false;
          abp.message.error(App.localize("FinalBillNotTallied"));
          return;
        } else {
          vm.isFinalBillTallied = true;
        }

        if (!vm.isEdit) {
          $modal.open({
            templateUrl: require('/App/common/views/modals/remarks/remarks.modal.html'),
            controller: 'common.views.modals.remarksModal as vm',
            backdrop: 'static',
            resolve: {
              title: function () {
                return App.localize('ApprovalConfirmation');
              },
              existingRemarks: function () {
                  return vm.requestInfo.request.escalationRemarks;
              },
              remarksMaxLength: function () {
                  return app.consts.serviceRequestMaxLength;
              }
            }
          }).result.then(function (remarks) {
            approve(remarks);
          });
        } else {
          promptEditModeWarning();
        }
      } else {
        abp.message.error(App.localize('OneOrMoreInputValueIsInvalid'));
        return;
      }
    }

    function openRejectionModal() {
      if (!vm.isEdit) {
        $modal.open({
          templateUrl: require('/App/common/views/modals/remarks/remarks.modal.html'),
          controller: 'common.views.modals.remarksModal as vm',
          backdrop: 'static',
          resolve: {
            title: function () {
              return App.localize('RejectionConfirmation');
            },
            existingRemarks: function () {
                return vm.requestInfo.request.escalationRemarks;
            },
            remarksMaxLength: function () {
                return app.consts.serviceRequestMaxLength;
            }
          }
        }).result.then(function (remarks) {
          reject(remarks);
        });
      } else {
        promptEditModeWarning();
      }
    }

    function openDelegationModal() {
      getServiceRequestClaimProcessors(function () {
        if (!vm.isEdit) {
          $modal.open({
            templateUrl: require('/App/common/views/modals/notifyRecipient/notifyRecipient.modal.html'),
            controller: 'common.views.modals.notifyRecipientModal as vm',
            backdrop: 'static',
            resolve: {
              users: function () {
                return delegateUsers;
              },
              title: function () {
                return App.localize('DelegationConfirmation');
              },
              message: function () {
                return App.localize('DelegationWarningMessage')
              }
            }
          }).result.then(function (result) {
            delegate(result.recipient, result.remarks);
          });
        } else {
          promptEditModeWarning();
        }
      })
    }

    function openRecommendationModal() {
      getServiceRequestOverridingApprovers(function () {
        if (!vm.isEdit) {
          $modal.open({
            templateUrl: require('/App/common/views/modals/notifyRecipient/notifyRecipient.modal.html'),
            controller: 'common.views.modals.notifyRecipientModal as vm',
            backdrop: 'static',
            resolve: {
              users: function () {
                return recommendUsers;
              },
              title: function () {
                return App.localize('RecommendationConfirmation');
              },
              message: function () {
                return App.localize('RecommendationWarningMessage')
              }
            }
          }).result.then(function (result) {
            recommend(result.recipient, result.remarks);
          });
        } else {
          promptEditModeWarning();
        }
      })
    }

    function openAssigneeModal() {
      if (!vm.isEdit) {
        $modal.open({
          templateUrl: require('/App/common/views/modals/assigneeConfirmation/assigneeConfirmation.modal.html'),
          controller: 'common.views.modals.assigneeConfirmationModal as vm',
          backdrop: 'static',
          resolve: {
            users: function () {
              return users;
            },
            title: function () {
              return App.localize('AssigneeConfirmation');
            },
            assigneeName: function () {
              return "Doctor A";
            }
          }
        }).result.then(function (recipients, remarks) {
          delegate(recipients, remarks);
        });
      } else {
        promptEditModeWarning();
      }
    }

    function promptEditModeWarning() {
      abp.message.error(App.localize('SaveOrCancelChangesNarrative'));
    }

    function navigateToListing() {
      $state.go('host.inpatientClaims');
    }

    function getServiceRequestClaimProcessors(_callback) {
      vm.loading++

      commonLookupSvc.getServiceRequestClaimProcessor()
        .success(function (data) {
          // Filter away current user
          delegateUsers = _.remove(data.items, function (n) {
            return n.value.operatorUserId !== abp.session.userId;
          });
        }).finally(function () {
          vm.loading--;
          _callback();
        })
    }

    function getServiceRequestOverridingApprovers(_callback) {
      vm.loading++

      commonLookupSvc.getServiceRequestOverridingApprover()
        .success(function (data) {
          // Filter away current user
          recommendUsers = _.remove(data.items, function (n) {
            return n.value.operatorUserId !== abp.session.userId;
          });
        }).finally(function () {
          vm.loading--;
          _callback();
        })
    }

    function preview() {
      window.open(vm.printUrl);
    }

    function query() {
      if (vm.hasUnresolvedQuery) {
        $state.go('inpatientGuaranteeLettersDetails', {
          letterNumber: vm.requestInfo.request.letterNumber,
          timeZoneId: vm.timeZoneId,
          tab: 3
        });
      } else {
        $state.go('createQuery', {
          letterNumber: vm.requestInfo.request.letterNumber,
          timeZoneId: vm.timeZoneId
        });
      }
    }

    function openEscalationModal() {
        if (!vm.isEdit) {
            $modal.open({
                templateUrl: require('/App/common/views/modals/remarks/remarks.modal.html'),
                controller: 'common.views.modals.remarksModal as vm',
                backdrop: 'static',
                resolve: {
                    title: function () {
                        return App.localize('EscalationConfirmation');
                    },
                    existingRemarks: function () {
                        return vm.requestInfo.request.escalationRemarks;
                    },
                    remarksMaxLength: function () {
                        return app.consts.serviceRequestMaxLength;
                    }
                }
            }).result.then(function (remarks) {
                escalate(remarks);
            });
        } else {
            promptEditModeWarning();
        }
    }

    function escalate(remarks) {
          vm.loading++;
          hostServiceRequestSvc.escalateGuaranteeLetterRequest({
              id: vm.requestInfo.request.id,
              isResolve: true,
              escalationRemark: remarks
          }).success(function () {
              abp.notify.success(App.localize('GlEscalated'));
              navigateToListing();
          }).finally(function () {
              vm.loading--;
          });
    }

    init();
  }
})();
