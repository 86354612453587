(() => {
  angular.module('app').component('commonComponentsPatientAdmissionTimelineComponent', {
    templateUrl: require('./patientAdmissionTimeline.component.html'),
    controller: PatientAdmissionTimelineController,
    controllerAs: 'vm',
    bindings: {
      guaranteeLetterNumber: '<',
      timeZoneId: '=?',
      serviceType: '<'
    },
  });

  PatientAdmissionTimelineController.$inject = [
    'abp.services.app.inpatientGuaranteeLetter',
    '$uibModal',
    'Hms.ServiceRequests.ServiceRequestStatus',
    'Hms.ServiceRequests.ServiceRequestType',
    '$stateParams',
    'Hms.Services.ServiceType'
  ];

  function PatientAdmissionTimelineController(
    inpatientGuaranteeLetterSvc,
    $modal,
    enumServiceRequestStatus,
    enumServiceRequestType,
    $stateParams,
    enumServiceType
  ) {
    const vm = this;

    vm.isHospital = App.isHospital();
    vm.isHost = App.isHost();
    vm.checkInPatient = () => {
      abp.message.confirm(App.localize('ConfirmPatientAdmission'), App.localize('AreYouSure'));
    };
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.loading = 0;

    vm.enums = {
      serviceRequestStatus: enumServiceRequestStatus,
      serviceRequestType: enumServiceRequestType,
      serviceType: enumServiceType,
    };

    vm.getServiceRequestName = getServiceRequestName;
    vm.showRequestDetailsModal = showRequestDetailsModal;

    init();

    function init() {
      getTimeline();
    }

    function getServiceRequestName(value) {
      return App.localize(value);
    }

    function getTimeline() {
      vm.loading += 1;
      inpatientGuaranteeLetterSvc
        .getPatientTimeline({
          letterNumber: vm.guaranteeLetterNumber,
          serviceType: vm.serviceType
        })
        .success((data) => {
          vm.timeline = data;
          vm.currencyCode = data.currencyCode;
          vm.timeZoneId = $stateParams.timeZoneId ?? data.ianaTimeZoneId;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function showRequestDetailsModal(requestId) {
      $modal.open({
        templateUrl: require('./requestDetails/requestDetailsModal.html'),
        controller: 'common.views.patientAdmissionTimeline.requestDetailsModal as vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          serviceRequestId() {
            return requestId;
          },
          currencyCode() {
            return vm.currencyCode;
          },
          ianaTimeZoneId() {
            return vm.timeZoneId;
          }
        },
      });
    }
  }
})();
