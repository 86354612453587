(() => {
  angular.module('app').component('hostClinicsPanelFeaturesEditorComponent', {
    require: {
      container: '^hostClinicsPanelEditorContainerComponent',
    },
    templateUrl: require('./panelFeaturesEditor.component.html'),
    controller: PanelFeaturesController,
    controllerAs: 'vm',
    bindings: {
      data: '<',
      panelType: '<',
      pharmacies: '<',
    },
  });

  PanelFeaturesController.$inject = [
    '$scope',
    'Hms.MultiTenancy.TenantClinicType',
  ];

  function PanelFeaturesController($scope, enumTenantClinicType) {
    const vm = this;
    vm.loading = 0;
    vm.saving = 0;
    vm.isEditing = false;

    vm.enums = {};
    vm.enums.tenantClinicType = enumTenantClinicType;
    vm.panelTypesWithAddModules = [
      vm.enums.tenantClinicType.GP.id,
      vm.enums.tenantClinicType.Specialist.id,
      vm.enums.tenantClinicType.Hospital.id,
    ];
    vm.hasAdditionalModules = false;

    vm.$onInit = init;

    vm.isDirty = () =>
      $scope.panelCreateEditFeaturesForm && $scope.panelCreateEditFeaturesForm.$dirty;

    vm.setPristine = () => {
      $scope.panelCreateEditFeaturesForm.$setPristine();
    };

    // Force validation on this form.
    // Return true if valid, otherwise, false.

    vm.validateForm = () => {
      const form = $scope.panelCreateEditFeaturesForm;
      App.touchFormErrors(form);
      return form.$valid;
    };

    // Generate the payload from this form to send to ClinicAppService API.

    vm.getPayload = () => ({
      features: vm.data,
    });

    // Ask container to save this widget.

    vm.save = () => {
      vm.container.saveWidget(vm);
    };

    function init() {
      vm.container.addWidget(this);
    }
  }
})();
