(function () {
  var componentId = 'clinicSpecialistDashboardPatientListingContainerComponent';
  var app = angular.module('app');

  app.component(componentId, {
    templateUrl: require('/App/clinic/views/specialistDashboard/widgets/patientListingContainer/patientListingContainer.component.html'),
    controller: [controller],
    controllerAs: 'vm',
    bindings: {
      items: '<',
      headerText: '@',
      isRedContainer: '<',
      isPendingSubmissionComponent: '<',
      isOutstandingComponent: '<',
      isLateSubmissionComponent: '<',
      isGlPendingApprovalComponent: '<',
      isSubmittedComponent: '<',
      isRejectedComponent: '<',
      viewType: '@', // Grid or tab
      claimStatus: '<', // If < 0, redirect to Specialist claim page without any filter.
      redirectToSpecialistGl: '<',
      viewMoreFunction: '&',
      itemsTotalCount: '<',
      hasMore: '<',
      timeZoneId: '<',
    },
  });

  function controller() {
    var vm = this;
    vm.loading = 0;

    vm.sortPatientListing = sortPatientListing;

    // Sort function

    function sortPatientListing(isAscending) {
      if (isAscending)
        vm.items = _.orderBy(vm.items, ['lastModificationTime', 'expiryTime'], ['asc']);
      else vm.items = _.orderBy(vm.items, ['lastModificationTime', 'expiryTime'], ['desc']);
    }
  }
})();
