(function () {
    var componentId = 'clinicSpecialistDashboardOutstandingClaimsWidget';
    var app = angular.module('app');

    function controller(specialistDashboardSvc, $state, $sce, $interval, $scope, enumSpecialistClaimStatus, moment) {
        function init() {
            refresh();

            var tickerInterval = $interval(tickerJob, 1000);
            $scope.$on('$destroy', function () {
                if (angular.isDefined(tickerInterval)) {
                    $interval.cancel(tickerInterval);
                    tickerInterval = undefined;
                }
            });
        }

        var tickerCount = 0;
        function tickerJob() {
            if (!((++tickerCount) % 10) && !vm.loading && !isSilentLoading) {
                refresh(true);
            }
        }

        var vm = this;
        vm.loading = 0;
        var isSilentLoading = 0;

        vm.enums = {};
        vm.enums.specialistClaimStatus = enumSpecialistClaimStatus;

        vm.permissions = {
            canViewClaim: abp.auth.isGranted('SpecialistClaims.Clinic'),
            canEditClaim: abp.auth.isGranted('SpecialistClaims.Clinic.Edit')
        };

        function refresh(silentLoad) {
            if (vm.loading || isSilentLoading) return;

            if (silentLoad) isSilentLoading++;
            else vm.loading++;

            specialistDashboardSvc.getOutstandingClaims(
                { abpHandleError: false }
            ).success(function (data) {
                vm.items = data.items;
            }).finally(function () {
                if (silentLoad) isSilentLoading--;
                else vm.loading--;
            });
        }

        vm.getSubmitMessage = function (claimNumber, creationTime) {
            var claimUrl = $state.href(
                'specialistClaimDetail', 
                { claimNumber: claimNumber, timeZoneId: vm.timeZoneId }, 
                { absolute: true });
            var claimNumberText = vm.permissions.canViewClaim
                ? '<a href="' + claimUrl + '">' + App.htmlEncode(claimNumber) + '</a>'
                : App.htmlEncode(claimNumber);
            var creationTimeText = moment(creationTime).format('L LT');

            return $sce.trustAsHtml(App.localize('XSubmittedOnYMessage', claimNumberText, creationTimeText));
        };

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/clinic/views/specialistDashboard/widgets/outstandingClaims/index.html'),
        controller: ['abp.services.app.specialistDashboard', '$state', '$sce', '$interval', '$scope', 'Hms.SpecialistClaims.SpecialistClaimStatus', 'moment', controller],
        controllerAs: 'vm',
        bindings: {
            timeZoneId: '<'
        }
    });
})();
