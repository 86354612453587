(function () {
    'use strict';

    // $attrs is used to check if binding function is empty.
    // Empty binding function indicates that the button is not required thus won't show on the cshtml side.

    angular.module('app').component('hostInpatientGuaranteeLettersCommonActionButtonGroupComponent', {
        templateUrl: require('/App/host/views/inpatientGuaranteeLetters/common/actionButtonGroup/actionButtonGroup.component.html'),
        controller: ['$attrs', controller],
        controllerAs: 'vm',
        bindings: {
            onApprove: '&',
            onReject: '&',
            onQuery: '&',
            onDelegate: '&',
            onRecommend: '&',
            onPreview: '&',
            onSaveDraft: '&',
            isOnHold: '<',
            onRejectGl: '&',
            hasUnresolvedQuery: '<',
            onEscalate: '&'
        }
    });

    function controller($attrs) {
        var vm = this;
        vm.attrs = $attrs;
        vm.hasInpatientClaimProcessingEnhancement = abp.setting.getBoolean('Hms.Feature.InpatientClaimProcessingEnhancement');
    }
})();
