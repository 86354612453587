(() => {
  angular.module('app').component('commonInpatientTicketsDetailCalculationSectionsComponent', {
    templateUrl: require('./calculationSections.component.html'),
    controller: CalculationSectionsController,
    controllerAs: 'vm',
    bindings: {
      currencyCode: '<',
      totalAmount: '<',
      totalCoveredAmount: '<',
      totalUncoveredAmount: '<',
      copayRate: '<',
      copayAmount: '<',
      eligibleClaimAmount: '<',
      entitledAmount: '<',
      exceededAmount: '<',
      totalPayableAmount: '<',
      totalCollectCashAmount: '<',
    },
  });

  CalculationSectionsController.$inject = [];

  function CalculationSectionsController() {
    const vm = this;

    vm.getCoPayValueDisplay = getCoPayValueDisplay;
    init();

    function init() {}

    function getCoPayValueDisplay() {
      if (vm.copayRate !== null) {
        return vm.copayRate.isPercentage
          ? `${vm.copayRate.value}%`
          : `${vm.currencyCode} ${vm.copayRate.value}`;
      }
      return ''
    }
  }
})();
